import React from "react";
import { withTranslation } from "react-i18next";
import { DeleteForever } from "@styled-icons/material/DeleteForever";
import { IComponentProps } from "./types";
import { Container, Button } from "./style";

const Dropdown = ({ table, t }: IComponentProps) => {
  const onClickDelete = React.useCallback(() => {
    table.delete();
  }, [table]);

  return (
    <Container>
      <Button className="delete-table-button" onClick={onClickDelete}>
        <DeleteForever size={15} />
        {t("Delete")}
      </Button>
    </Container>
  );
};

export default withTranslation()(Dropdown);
