import styled from "styled-components";
import { up } from "styled-breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 382px;
  width: 100%;
`;

export const FormWrapper = styled.div`
  padding: 0px 15px;
  width: 100%;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    ${up("md")} {
      /* padding: 11px 3px; */
    }
  }
`;

export const Label = styled.p`
  margin-top: 15px;
`;

export const Input = styled.input`
  background-color: ${(props) => props.theme.colors.gray};
  border: 1px solid transparent;
  /* height: 40px; */
  /* border-color: #e7e7e9; */
  /* max-width: 382px; */
  width: 100%;
  appearance: none;
  color: #0d0c22;
  border-radius: 8px;
  padding: 10px 16px;
  outline: none;
  font-size: 14px;

  transition: all 200ms ease;

  &:focus {
    background-color: #fff;
    border-color: rgba(234, 76, 137, 0.4);
    box-shadow: 0 0 0 4px rgba(234, 76, 137, 0.1);
  }
`;

export const Button = styled.button`
  display: block;
  background-color: ${(props) => props.theme.colors.blue};
  color: #fff;
  width: 100%;
  outline: none;
  border-radius: 8px;
  text-align: center;
  height: 40px;
  border: none;
  padding: 10px 16px;
  margin: auto;
  margin-top: 15px;
  cursor: pointer;

  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.blueDarker};
  }
`;

export const Error = styled.p`
  color: red;
`;
