import React from "react";
import { FormikProps } from "formik";
import { FormValues, IComponentProps } from "./type";
import {
  Container,
  FormWrapper,
  Label,
  Button,
  Error,
  RadioContainer,
} from "./style";
import LoadingSpinner from "../../controls/loading_spinner";
import Input from "../../controls/input";

const SignInForm = (props: FormikProps<FormValues> & IComponentProps) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    status,
    t,
  } = props;

  return (
    <Container>
      <FormWrapper>
        <p>{t(status)}</p>
        <form onSubmit={handleSubmit} id="sign-in-form">
          <RadioContainer>
            <input
              type="radio"
              id="sign-in-company"
              defaultChecked={values.is_employee === "false"}
              name="is_employee"
              value="false"
              onChange={handleChange}
            />
            <label htmlFor="sign-in-company">
              {t("sign-in-form.sign-in-as-company")}
            </label>
          </RadioContainer>
          <RadioContainer>
            <input
              type="radio"
              id="sign-in-employee"
              defaultChecked={values.is_employee === "true"}
              name="is_employee"
              value="true"
              onChange={handleChange}
            />
            <label htmlFor="sign-in-employee">
              {t("sign-in-form.sign-in-as-employee")}
            </label>
          </RadioContainer>

          <Label>{t("email")}</Label>
          <Input
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            placeholder="Email"
          />
          {errors.email && touched.email && <Error>{t(errors.email)}</Error>}
          <Label>{t("password")}</Label>
          <Input
            type="password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            placeholder={t("password")}
          />
          {errors.password && touched.password && (
            <Error>{t(errors.password)}</Error>
          )}

          <Button
            type="submit"
            disabled={
              isSubmitting ||
              !!(errors.email && touched.email) ||
              !!(errors.password && touched.password)
            }
          >
            {isSubmitting ? <LoadingSpinner /> : t("sign-in-form.submit")}
          </Button>
        </form>
      </FormWrapper>
    </Container>
  );
};

export default SignInForm;
