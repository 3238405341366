import styled from "styled-components";
import { down } from "styled-breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  ${down("md")} {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  margin-left: 15px;

  ${down("md")} {
    width: 100%;
  }
`;

export const Header = styled.div`
  margin-left: 15px;
`;

export const Title = styled.h1`
  margin-bottom: 0;
`;

export const Description = styled.p``;

export const Tour = styled.a`
  color: ${(props) => props.theme.colors.blue};
  cursor: pointer;
`;

export const Button = styled.button<{ active?: number }>`
  background-color: ${(props) =>
    props.active ? props.theme.colors.grayDarker : props.theme.colors.gray};
  outline: none;
  border-radius: 8px;
  color: black;
  text-align: center;
  height: 40px;
  border: none;
  padding: 10px 16px;
  margin-top: 15px;
  margin-right: 5px;
  margin-bottom: 15px;
  cursor: pointer;

  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.grayDarker};
  }
`;

export const MoveButton = styled(Button)`
  color: #fff;
  background-color: ${(props) =>
    props.active ? props.theme.colors.redDarker : props.theme.colors.red};

  &:hover {
    background-color: ${(props) => props.theme.colors.redDarker};
  }
`;

export const Horizontal = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
