import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { withTranslation } from "react-i18next";
import { signUpSchema } from "./validation";
import { IComponentProps, FormValues } from "./type";
import Component from "./component";
import { authClient } from "../../api";
import event, { ACCOUNT_TYPE } from "../../event";

const initialValues: FormValues = {
  name: "",
  email: "",
  password: "",
  passwordConfirmation: "",
};

const SignUpForm = withFormik<IComponentProps, FormValues>({
  mapPropsToValues: () => initialValues,
  validationSchema: signUpSchema,
  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    const { name, email, password, passwordConfirmation } = values;
    const { t, history } = props;

    event.track("sign-up-form.submit");

    try {
      await authClient.sign_up(name, email, password, passwordConfirmation);
      event.identify(email);
      event.people.set({ role: ACCOUNT_TYPE.admin, name, email });
      setStatus(t("Signed up successfully"));
      enqueueSnackbar(t("Signed up successfully"), { variant: "success" });
      await authClient.sign_in(email, password);
      history.push("/products");
      event.track("sign-up-form.success");
    } catch (error) {
      event.track("sign-up-form.failure");
      setStatus(t("Failed to sign up"));
      enqueueSnackbar(t("Failed to sign up"), { variant: "error" });

      if (error.response?.data.errors.full_messages.length > 0) {
        const message = error.response?.data.errors.full_messages.join(",");
        setStatus(message);
      }
    }

    setSubmitting(false);
  },
})(Component);

export default withRouter(withTranslation()(SignUpForm));
