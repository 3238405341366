import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Container, LeftContainer, Header, Description } from "./style";
import ResetPasswordForm from "../../components/reset_password_form";
import { IComponentProps } from "./types";
import Logo from "../../controls/logo";

const ResetPasswordPage: React.FC<IComponentProps> = ({ t }) => {
  return (
    <Container>
      <LeftContainer>
        <Logo />

        <Header>{t("reset-password-page.title")}</Header>
        <Description>
          {t("dont-have-account")}{" "}
          <ReactRouterLink to="/sign_up">{t("sign-up")}</ReactRouterLink>!
        </Description>
        <Description>
          {t("Go back to")}{" "}
          <ReactRouterLink to="/">{t("home")}</ReactRouterLink>
        </Description>
      </LeftContainer>

      <ResetPasswordForm />
    </Container>
  );
};

export default ResetPasswordPage;
