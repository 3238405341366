import React from "react";
import { Rnd } from "react-rnd";
import { Container } from "./style";
import { IComponentProps } from "./types";
import LoadingSpinner from "../../../controls/loading_spinner";

const Table: React.FC<IComponentProps> = ({
  table,
  onClick,
  onDragStop,
  onResizeStop,
  disableDragging,
}: IComponentProps) => {
  const [showTotal, setShowTotal] = React.useState(false);

  return (
    <Rnd
      default={{
        x: table.left,
        y: table.top,
        width: table.width,
        height: table.height,
      }}
      disableDragging={disableDragging}
      enableResizing={!disableDragging}
      minWidth="40"
      minHeight="40"
      onDragStop={onDragStop}
      onResizeStop={onResizeStop}
      onClick={onClick}
      dragGrid={[1, 1]}
      className="table-rnd"
    >
      <Container
        width={table.width}
        height={table.height}
        top={table.top}
        left={table.left}
        whileHover={{ scale: 1.06 }}
        hasOrder={table.hasOrder}
        onMouseEnter={() => setShowTotal(true)}
        onMouseLeave={() => setShowTotal(false)}
      >
        {showTotal ? (
          <p>$ {table.order ? table.order.total : 0}</p>
        ) : (
          <p>{table.name}</p>
        )}
        {table.isLoading && <LoadingSpinner />}
      </Container>
    </Rnd>
  );
};

export default Table;
