import React, { useCallback } from "react";
import {
  Day,
  CloseTime,
  DaySettings,
  DurationTime,
  Info,
  Select,
  HiddenCheckbox,
  ActiveLabel,
} from "./style";
import { IComponentProps } from "./types";
import { enqueueSnackbar } from "notistack";
import { addTime } from "../../utils/addTime";
import Button from "../../controls/button";

const DailyReportsSetting = ({
  dailyReportSetting,
  t,
}: IComponentProps): JSX.Element => {
  // get values from dailyReportSetting
  const { day } = dailyReportSetting;

  //get UTC time zone offset and specify if it is negative or positive
  const offset: string =
    new Date().toString().match(/([-\+][0-9]+)\s/)?.[1] || "";
  //transform "-0300" to "-03:00" or "+0300" to "+03:00"
  const offset_formatted = offset.replace(/(\d{2})(\d{2})/, "$1:$2");
  //get closes_at in local time zone
  const close_at_local = addTime(
    dailyReportSetting.closes_at,
    offset_formatted
  ) as string;

  const [closes_at, setClosesAt] = React.useState(close_at_local);
  const [duration, setDuration] = React.useState(dailyReportSetting.duration);
  const [active, setActive] = React.useState(dailyReportSetting.active);

  // values for info text
  const sent_time = addTime(closes_at, "00:05") as string;

  const start_time = addTime(closes_at, "-" + duration) as string;

  const info_values = {
    sent_time,
    start_time,
    end_time: closes_at,
  };

  // update functions for each setting
  async function updateCloseTime(event: React.ChangeEvent<HTMLSelectElement>) {
    try {
      setClosesAt(event.target.value);

      //close_at sent to backend must be in UTC time zone
      const closes_at_utc =
        offset_formatted[0] === "+"
          ? //if offset is positive, subtract offset from closes_at
            addTime(event.target.value, offset_formatted.replace("+", "-"))
          : //if offset is negative, add offset to closes_at
            addTime(event.target.value, offset_formatted.slice(1));

      await dailyReportSetting.update({ closes_at: closes_at_utc });

      enqueueSnackbar(t("profile-page.daily-report.close-time-updated"), {
        variant: "success",
      });
    } catch (e) {
      setClosesAt(close_at_local);
      enqueueSnackbar(t("profile-page.daily-report.close-time-error"), {
        variant: "error",
      });
    }
  }

  async function updateDurationTime(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    try {
      setDuration(event.target.value);
      await dailyReportSetting.update({ duration: event.target.value });
      enqueueSnackbar(t("profile-page.daily-report.duration-updated"), {
        variant: "success",
      });
    } catch (e) {
      console.error(e);
      setDuration(dailyReportSetting.duration);
      enqueueSnackbar(t("profile-page.daily-report.duration-error"), {
        variant: "error",
      });
    }
  }

  async function updateActive(event: React.ChangeEvent<HTMLInputElement>) {
    try {
      setActive(event.target.checked);
      await dailyReportSetting.update({ active: event.target.checked });
      enqueueSnackbar(t("profile-page.daily-report.active-updated"), {
        variant: "success",
      });
    } catch (e) {
      console.error(e);
      setActive(dailyReportSetting.active);
      enqueueSnackbar(t("profile-page.daily-report.active-error"), {
        variant: "error",
      });
    }
  }

  // component for active checkbox
  const ActiveCheckbox = ({
    active,
    onChange,
  }: {
    active: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }): JSX.Element => {
    return <HiddenCheckbox checked={active} onChange={onChange} />;
  };

  // component for hours select
  const HoursSelect = ({
    value,
    onChange,
    disabled,
  }: {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    disabled?: boolean;
  }): JSX.Element => {
    return (
      <Select value={value} onChange={onChange} disabled={disabled}>
        {Array.from(Array(24).keys()).map((hour) => {
          //create array of 24 hours
          const formattedHour = hour.toString().padStart(2, "0") + ":00"; //format hour to hh:mm
          return <option key={hour}>{formattedHour}</option>;
        })}
      </Select>
    );
  };

  const handleTriggerClick = useCallback(async () => {
    try {
      await dailyReportSetting.trigger();
      enqueueSnackbar(t("profile-page.daily-report.trigger.success"), {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(t("profile-page.daily-report.trigger.failure"), {
        variant: "error",
      });
    }
  }, []);

  return (
    <DaySettings>
      <Day>{t(`day.${day}`)}</Day>
      <CloseTime>
        <p>{t("profile-page.daily-report.close-time-label")}</p>
        <HoursSelect
          value={closes_at}
          onChange={updateCloseTime}
          disabled={!active}
        />
      </CloseTime>
      <DurationTime>
        {t("profile-page.daily-report.duration-label-1")}
        <HoursSelect
          value={duration}
          onChange={updateDurationTime}
          disabled={!active}
        />
        {t("profile-page.daily-report.duration-label-2")}
      </DurationTime>
      <ActiveLabel>
        <ActiveCheckbox active={active} onChange={updateActive} />
        <span>{t("active")}</span>
      </ActiveLabel>
      {active && (
        <Info>{t("profile-page.daily-report.info", { ...info_values })}</Info>
      )}

      <Button variant="gray" onClick={handleTriggerClick}>
        {t("profile-page.daily-report.trigger.title")}
      </Button>
    </DaySettings>
  );
};

export default DailyReportsSetting;
