import React from "react";
import Joyride, { CallBackProps } from "react-joyride";
import { useStores } from "../../contexts/store_context";
import {
  Container,
  ProductListContainer,
  Title,
  Header,
  Description,
  Horizontal,
  Tour,
} from "./style";
import { IComponentProps } from "./types";
import event from "../../event";
import ProductList from "../../components/product_list";
import ProductTable from "../../components/product_table";
import { useFlags } from "flagsmith/react";
import Input from "../../controls/input";
import Button from "../../controls/button";
import TrialModal from "../../components/modal/trial_modal";

const steps = (t: any) => [
  {
    target: "[data-testid=create-product-button]",
    content: t("products-page.steps.1"),
  },
  {
    target: ".product-name-input",
    content: t("products-page.steps.2"),
  },
  {
    target: ".product-description-input",
    content: t("products-page.steps.4"),
  },
  {
    target: ".product-price-input",
    content: t("products-page.steps.5"),
  },
  {
    target: ".product-published-button",
    content: t("products-page.steps.6"),
  },
  {
    target: ".product-tags-input",
    content: t("products-page.steps.7"),
  },
  {
    target: ".product-option-menu",
    content: t("products-page.steps.8"),
  },
  {
    target: ".product-image-menu",
    content: t("products-page.steps.9"),
  },
  {
    target: ".product-delete-button",
    content: t("products-page.steps.10"),
  },
];

const ProductsPage: React.FC<IComponentProps> = ({ t }) => {
  // only causes re-render if specified flag values / traits change
  const flags = useFlags(["product_table"]);

  const stores = useStores();
  const [runTour, setRunTour] = React.useState<boolean>(
    localStorage.getItem("tour#product_list_page") !== "true"
  );
  const [searchInputValue, setSearchInputValue] = React.useState<string>();

  React.useEffect(() => {
    stores?.productListPageStore.load();
  }, []);

  const products = stores?.productListPageStore.products || [];
  const product_tags = stores?.productListPageStore.getProductTags || [];

  const onCreateProductClick = (count = 1) => {
    for (let i = 0; i < count; i++) {
      stores?.productListPageStore.productsStore.create({
        name: "New Product",
      });
      event.track("product-list-page.product.create");
    }
    changeSearch("");
  };

  const onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeSearch(event.target.value);
  };

  const changeSearch = (value: string) => {
    setSearchInputValue(value);
    stores?.productListPageStore.setSearchQuery(value);
  };

  return (
    <Container>
      <Header>
        <Title>{t("products-page.title")}</Title>
        <Description>
          {t("products-page.description")}{" "}
          <Tour
            onClick={() => {
              setRunTour((prev) => !prev);
            }}
          >
            {t("start-tour")}
          </Tour>
        </Description>

        <Horizontal>
          <Input
            value={searchInputValue}
            type="text"
            placeholder={t("Search by name")}
            onChange={onSearchInputChange}
            id="search-product-input"
          />

          <Button
            variant="blue"
            onClick={() => {
              onCreateProductClick(1);
            }}
            test_id="create-product-button"
          >
            {t("products-page.new-product-button")}
          </Button>

          <Button
            variant="boderless"
            onClick={() => {
              onCreateProductClick(5);
            }}
            test_id="create-multiple-products-button"
          >
            {t("products-page.5-new-product-button")}
          </Button>
        </Horizontal>
      </Header>

      <ProductListContainer>
        <br />
        {flags.product_table.enabled ? (
          <ProductTable
            products={products}
            product_tags={product_tags}
            reloadProductTags={() => {
              stores?.productListPageStore.loadProductTags();
            }}
          />
        ) : (
          <ProductList products={products} />
        )}
      </ProductListContainer>

      <Joyride
        continuous={true}
        steps={steps(t)}
        showProgress={true}
        showSkipButton={true}
        styles={{
          options: {
            primaryColor: "#fd3a5c",
            zIndex: 1,
          },
        }}
        run={runTour}
        callback={(data: CallBackProps) => {
          if (["finished", "skipped"].includes(data.status)) {
            localStorage.setItem("tour#product_list_page", "true");
          }
        }}
      />

      <TrialModal />
    </Container>
  );
};

export default ProductsPage;
