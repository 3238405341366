import { useCallback, useEffect, useRef, useState } from "react";
import Tag, { BLUE_COLOR, RED_COLOR } from "../../../controls/tag";
import { useTranslation } from "react-i18next";
import useModal from "../../../hooks/useModal";
import {
  Modal,
  Input,
  Label,
  TagList,
  TagContainer,
  Container,
  InlineText,
} from "./style";
import { IComponentProps } from "./types";

const TagCell = ({ selected, options, updateCallback }: IComponentProps) => {
  const { t } = useTranslation();

  const search_input_ref = useRef<HTMLInputElement>(null);

  const [opened, setOpened, containerRef] = useModal();

  const [search_text, setSearchText] = useState("");
  const search_text_exist = search_text.length > 1;

  useEffect(() => {
    if (search_input_ref.current && opened) {
      search_input_ref.current.focus();
    }
  }, [opened]);

  const handleSearchTextChange = useCallback(
    (e: { target: { value: string } }) => {
      setSearchText(e.target.value);
    },
    []
  );

  const openTagSelector = useCallback(() => {
    setOpened(true);
  }, []);

  const onKeyDown = useCallback((event: { key: string }) => {
    if (event.key === "Enter") {
      // Open the tag selector
      setOpened(true);
    }
  }, []);

  const filtered_options = search_text_exist
    ? options.filter((option) => {
        // Skip already selected options
        if (selected.includes(option.name)) {
          return false;
        }

        return option.name.toLowerCase().includes(search_text.toLowerCase());
      })
    : options.filter((option) => {
        // Skip already selected options
        if (selected.includes(option.name)) {
          return false;
        }

        return true;
      });

  const onOptionClick = useCallback(
    (value: string) => () => {
      updateCallback(value);
      setOpened(false);
    },
    []
  );

  const onRemoveOptionClick = useCallback(() => {
    updateCallback("");
    setOpened(false);
  }, []);

  const onInputKeyDown = useCallback(
    (event: { key: string }) => {
      if (event.key === "Enter") {
        if (search_text_exist) {
          if (filtered_options.length == 0) {
            // Create new tag
            updateCallback(search_text);
          } else {
            // Set first filtered option as the value
            updateCallback(filtered_options[0].name);
          }

          setOpened(false);
        }
      } else if (event.key === "Tab") {
        setOpened(false);
      }
    },
    [filtered_options]
  );

  return (
    <Container ref={containerRef}>
      {selected.length == 0 && (
        <Label onClick={openTagSelector} onKeyDown={onKeyDown} tabIndex={0}>
          {t("editable-cells.tag.empty")}
        </Label>
      )}

      {selected.map((tag, index) => (
        <Tag
          color={BLUE_COLOR}
          onClick={openTagSelector}
          onKeyDown={onKeyDown}
          tabIndex={0}
          key={`${tag}-${index}`}
        >
          {tag}
        </Tag>
      ))}

      {opened && (
        <Modal data-testid="tag-selector-modal">
          <Input
            ref={search_input_ref}
            type="text"
            value={search_text}
            onChange={handleSearchTextChange}
            onKeyDown={onInputKeyDown}
            placeholder={t("editable-cells.tag.input")}
          />
          <Label>{t("editable-cells.tag.selected")}</Label>
          {selected.map((tag, index) => (
            <Tag
              key={`selected-${tag}-${index}`}
              color={RED_COLOR}
              onClick={onRemoveOptionClick}
              data-testid="tag-selector-remove-btn"
            >
              {tag} x
            </Tag>
          ))}

          <Label>{t("editable-cells.tag.available")}</Label>
          <TagList>
            {filtered_options.map(({ name }, index) => (
              // Set focused the first option filtered by search text
              <TagContainer
                key={`available-${name}-${index}`}
                data-testid="tag-selector-option-btn"
                focused={search_text_exist && index == 0}
              >
                <Tag
                  color={BLUE_COLOR}
                  disable_hover
                  onClick={onOptionClick(name)}
                >
                  {name}
                </Tag>
              </TagContainer>
            ))}

            {/* Show create new tag option when no option was found from search */}
            {search_text_exist && (
              <TagContainer
                data-testid="tag-selector-create-btn"
                onClick={onOptionClick(search_text)}
              >
                <InlineText>{t("editable-cells.tag.new")}</InlineText>
                <Tag color={BLUE_COLOR} disable_hover>
                  {search_text}
                </Tag>
              </TagContainer>
            )}
          </TagList>
        </Modal>
      )}
    </Container>
  );
};

export default TagCell;
