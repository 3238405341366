import styled from "styled-components";
import { down } from "styled-breakpoints";

export const Canvas = styled.div`
  height: 100%;
  min-height: 600px;
  position: relative;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.gray};
  border: none;
  border-radius: 8px;
  padding: 10px 30px;

  ${down("md")} {
    width: 100%;
    max-width: initial;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
