import React from "react";
import { accessesClient } from "../api";
import { IAccess } from "../api/accesses_client";
import LoadingPage from "../pages/loading_page";
import UnauthorizedPage from "../pages/unauthorized_page";

export const ACCESS_TABLE = {
  product: {
    create: 0,
    update: 1,
    delete: 2,
  },
  order: {
    view: 10,
    create: 11,
    update: 12,
  },
  floor: {
    create: 20,
    update: 21,
    delete: 22,
  },
  table: {
    create: 30,
    update: 31,
    delete: 32,
  },
  ledger: {
    view: 40,
    create: 41,
    update: 42,
    delete: 43,
  },
  dashboard: {
    view: 50,
  },
  profile: {
    view: 60,
    update: 61,
  },
  billing: {
    view: 70,
  },
  employee: {
    view: 80,
    create: 81,
    update: 82,
    delete: 83,
  },
  review: {
    view: 90,
    update: 91,
  },
};

const withAuthorization =
  <T extends Record<string, unknown>>(
    access: number,
    WrappedComponent: React.ComponentType<T>
  ) =>
  (props: any) => {
    const [current_accesses, setCurrentAccesses] = React.useState<IAccess[]>(
      []
    );
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
      const fetchAccesses = async () => {
        setLoading(true);
        const response = await accessesClient.index();
        setCurrentAccesses(response.accesses);
        setLoading(false);
      };

      fetchAccesses();
    }, []);

    const has_access = current_accesses.some((current_access) => {
      return current_access.value == access;
    });

    if (loading == true) {
      return <LoadingPage />;
    } else if (has_access == false) {
      return <UnauthorizedPage />;
    } else {
      return <WrappedComponent {...props} />;
    }
  };

export default withAuthorization;
