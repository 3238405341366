import { makeAutoObservable } from "mobx";
import { reviewsClient } from "../api";
import type {
  ReviewStatus,
  IReview,
  IUpdateParams,
  IUser,
} from "../api/reviews_client";
import type ReviewsStore from "./reviews_store";

class Review {
  id!: number;
  company_id!: number;
  user!: IUser;
  body!: string;
  featured!: boolean;
  status!: ReviewStatus;
  created_at!: string;
  updated_at!: string;

  store!: ReviewsStore;

  constructor(store: ReviewsStore, params: IReview) {
    makeAutoObservable(this, {
      id: false,
      store: false,
    });

    this.store = store;

    this.refresh(params);
  }

  refresh(json: IReview): void {
    this.id = json.id;
    this.company_id = json.company_id;
    this.user = json.user;
    this.body = json.body;
    this.status = json.status;
    this.featured = json.featured;
    this.created_at = json.created_at;
    this.updated_at = json.updated_at;
  }

  *update(params: IUpdateParams): Generator<Promise<IReview>, void, IReview> {
    try {
      const json = yield reviewsClient.update(this.id, params);
      this.refresh(json);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  get key(): string {
    return `${this.company_id}-${this.id}-${this.created_at}`.toLowerCase();
  }
}

export default Review;
