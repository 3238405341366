import { makeAutoObservable } from "mobx";
import { floorsClient } from "../api";
import { IFloor, ICreateParams } from "../api/floors_client";
import Floor from "./floor";

class FloorsStore {
  floors: Map<number, Floor> = new Map();

  isLoading = false;

  initialized = false;

  constructor() {
    makeAutoObservable(this, {});
  }

  *load(): Generator<Promise<IFloor[]>, void, IFloor[]> {
    try {
      this.isLoading = true;
      const fetchedFloors = yield floorsClient.all();
      fetchedFloors.forEach((json: IFloor) =>
        this.refreshFloorFromServer(json)
      );
      this.initialized = true;
    } finally {
      this.isLoading = false;
    }
  }

  refreshFloorFromServer(json: IFloor): void {
    const foundFloor = this.floors.get(json.id);

    if (!foundFloor) {
      const floor = new Floor(this, json);
      this.floors.set(json.id, floor);
    } else {
      foundFloor.refresh(json);
    }
  }

  *create(params: ICreateParams): Generator<Promise<IFloor>, void, IFloor> {
    try {
      this.isLoading = true;
      const json = yield floorsClient.create(params);
      const floor = new Floor(this, json);
      this.floors.set(floor.id, floor);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  *remove(floor_id: number): Generator<Promise<void>, void, void> {
    try {
      this.isLoading = true;
      yield floorsClient.delete(floor_id);
      this.floors.delete(floor_id);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default FloorsStore;
