import styled from "styled-components";
import { down } from "styled-breakpoints";

export const Container = styled.div`
  margin-left: 10px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  min-width: 300px;
`;

export const Header = styled.div`
  max-width: 1280px;
  margin: auto;
`;

export const Title = styled.h1`
  margin-bottom: 0;
  max-width: 768px;
`;

export const Description = styled.p``;

export const OrderListContainer = styled.div`
  max-width: 1280px;
  margin: auto;

  ${down("md")} {
    padding: 0px;
  }
`;
