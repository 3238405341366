import { ThemeProvider } from "styled-components";
import { SnackbarProvider } from "notistack";
import createStore from "./stores";
import { StoresContext } from "./contexts/store_context";
import Router from "./router";
import { theme } from "./theme";
import Footer from "./components/footer";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ""}
        >
          <FlagsmithProvider
            options={{
              environmentID:
                process.env.REACT_APP_FLAGSMITH_CLIENT_ENV_KEY || "",
            }}
            flagsmith={flagsmith}
          >
            <StoresContext.Provider value={createStore()}>
              <Router />
              <Footer />
            </StoresContext.Provider>
          </FlagsmithProvider>
        </GoogleOAuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
