import { makeAutoObservable } from "mobx";
import { reviewsClient } from "../api";
import { IReview, IFiltersParams, IIndexResponse } from "../api/reviews_client";
import Review from "./review";

class ReviewsStore {
  reviews: Map<string, Review> = new Map();

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  *load(
    page: number,
    per_page: number,
    filters: IFiltersParams
  ): Generator<Promise<IIndexResponse>, IIndexResponse, IIndexResponse> {
    try {
      this.isLoading = true;

      const response = yield reviewsClient.index(page, per_page, filters);

      // eslint-disable-next-line
      const ids = response.ids;

      ids.forEach((id: string) => {
        this.refreshOrderFromServer(id, response.data[id]);
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  refreshOrderFromServer(key: string, json: IReview): void {
    const foundReview = this.reviews.get(key);

    if (!foundReview) {
      const review = new Review(this, json);
      this.reviews.set(key, review);
    } else {
      foundReview.refresh(json);
    }
  }
}

export default ReviewsStore;
