import styled from "styled-components";
import { up } from "styled-breakpoints";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export const Container = styled(motion.div)`
  max-width: 95vw;
  max-height: 80vh;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  overflow: scroll;
  transition: all 0.5s ease-out;

  ${up("md")} {
    overflow: hidden;
    display: flex;
    align-items: stretch;
  }
`;

export const Dropzone = styled.div`
  background-color: ${(props) => props.theme.colors.gray};
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${up("md")} {
    width: 40%;
    max-width: 300px;
    height: auto;
  }
`;

export const Gallery = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 0 20px;
  gap: 10px;

  ${up("md")} {
    overflow-y: auto;
  }
`;

export const ProductName = styled.h2`
  margin: 0;
  padding-bottom: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray};
  text-transform: capitalize;
`;

export const Subtitle = styled.h2`
  margin: 0;
`;

export const Description = styled.p``;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 0;

  ${up("md")} {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 15px;
`;

export const Image = styled.img``;

export const Videos = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 0;

  ${up("md")} {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 15px;
`;

export const Video = styled.video``;

export const Subscribe = styled(Link)`
  background-color: ${(props) => props.theme.colors.red};
  color: #fff;
  text-decoration: none;
  border-radius: 8px;
  text-align: center;
  border: none;
  padding: 10px 16px;
  cursor: pointer;
  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.redDarker};
  }
`;

export const RemoveButton = styled.button`
  color: black;
  outline: none;
  border-radius: 8px;
  text-align: center;
  height: 40px;
  border: none;
  padding: 10px 16px;
  margin-top: 15px;
  cursor: pointer;

  transition: all 200ms ease;

  &:hover {
    color: ${(props) => props.theme.colors.red};
  }
`;
