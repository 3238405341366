import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-width: 768px;
  padding: 0 10px;
`;

export const EmptyOrders = styled.h3`
  text-align: center;
  margin-top: 80px;
`;

export const Header = styled.div`
  margin: 10px 0;
  border-radius: 8px;
  padding: 15px 25px;
  max-height: 60px;

  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.gray};

  span {
    width: 200px;
  }
`;

export const Column = styled.span``;

export const List = styled.div`
  height: 600px;
`;

export const Button = styled.button`
  background-color: ${(props) => props.theme.colors.gray};
  outline: none;
  border-radius: 8px;
  color: black;
  text-align: center;
  height: 40px;
  border: none;
  padding: 10px 16px;
  margin-top: 15px;
  cursor: pointer;

  transition: all 200ms ease;

  &:hover {
    background-color: #2ec4b6;
  }
`;
