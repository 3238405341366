import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled.div`
  width: 100%;
  padding-top: 15px;
  font-size: 14px;
`;

export const Product = styled(motion.div)`
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const Name = styled.p``;

export const Price = styled.span``;

export const RightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Count = styled.div`
  background-color: ${(props) => props.theme.colors.gray};
  width: 50px;
  padding: 10px 0;
  border-radius: 8px;
  margin-left: 15px;
`;

export const CountButtonContainer = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
`;

export const CountButton = styled(motion.button)`
  background-color: ${(props) =>
    props.active ? props.theme.colors.grayDarker : props.theme.colors.gray};
  outline: none;
  border-radius: 8px;
  color: black;
  text-align: center;
  height: 40px;
  border: none;
  padding: 10px 16px;
  margin-top: 15px;
  margin-right: 5px;
  cursor: pointer;

  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.grayDarker};
  }
`;

export const InputContainer = styled.div`
  width: 100%;

  input {
    width: 100%;
    text-align: center;
    background-color: transparent;
    font-size: inherit;
    font-weight: inherit;
    padding: 0;
    border: none;

    :focus {
      outline: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
