import styled from "styled-components";

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
`;

export const Title = styled.h2`
  margin-top: 0;
`;

export const Text = styled.p`
  margin: 0;
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${(props) => props.theme.colors.blue};
`;

export const Dismiss = styled.p`
  font-size: 0.8em;
  color: ${(props) => props.theme.colors.black};
  align-self: flex-end;

  cursor: pointer;
`;
