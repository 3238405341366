import styled, { css } from "styled-components";

const Container = styled.button<{ variant: string; font_size: string }>`
  --opacity: 0.65;

  width: fit-content;
  padding: 10px 20px;
  font-family: inherit;
  font-weight: 600;
  border-radius: 12px;
  cursor: pointer;
  border: none;
  font-size: ${(props) => props.font_size};

  ${(props) =>
    props.variant == "gray" &&
    css`
      background-color: ${(props) => props.theme.colors.gray};
      color: ${(props) => props.theme.colors.blueDarker};

      :hover {
        background-color: #dcdadb;
      }

      :disabled {
        background-color: #dcdadb;
        opacity: var(--opacity);
        cursor: initial;
      }
    `}

  ${(props) =>
    props.variant == "blue" &&
    css`
      background-color: ${(props) => props.theme.colors.blue};
      color: ${(props) => props.theme.colors.white};

      :hover {
        background-color: ${(props) => props.theme.colors.blueDarker};
        text-decoration: underline;
      }

      :disabled {
        background-color: ${(props) => props.theme.colors.blueDarker};
        opacity: var(--opacity);
        cursor: initial;
      }
    `}

    ${(props) =>
    props.variant == "borderless" &&
    css`
      background-color: transparent;
      color: ${(props) => props.theme.colors.blueDarker};

      :hover {
        text-decoration: underline;
      }

      :disabled {
        opacity: var(--opacity);
        cursor: initial;
      }
    `}

    ${(props) =>
    props.variant == "black" &&
    css`
      background-color: ${(props) => props.theme.colors.black};
      color: ${(props) => props.theme.colors.white};

      :hover {
        text-decoration: underline;
      }

      :disabled {
        opacity: var(--opacity);
        cursor: initial;
        text-decoration: initial;
      }
    `}

    ${(props) =>
    props.variant == "red" &&
    css`
      background-color: ${(props) => props.theme.colors.red};
      color: ${(props) => props.theme.colors.white};

      :hover {
        text-decoration: underline;
      }

      :disabled {
        opacity: var(--opacity);
        cursor: initial;
        text-decoration: initial;
      }
    `}
`;

interface IComponentProps {
  variant?: string;
  onClick: React.MouseEventHandler;
  children: any;
  test_id?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  font_size?: string;
}

const Button: React.FC<IComponentProps> = ({
  variant = "gray",
  onClick,
  test_id,
  children,
  disabled,
  style,
  font_size = "0.9em",
}) => {
  return (
    <Container
      variant={variant}
      onClick={onClick}
      data-testid={test_id}
      disabled={disabled}
      style={style}
      font_size={font_size}
    >
      {children}
    </Container>
  );
};

export default Button;
