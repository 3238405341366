import { makeAutoObservable } from "mobx";
import { IOrder, IUpdateParams } from "../api/orders_client";
import type OrdersStore from "./orders_store";
import Item from "./item";
import ItemsClient, { IItem, ICreateResponse } from "../api/items_client";
import { itemsClient, ordersClient } from "../api";
import { IPaymentMethod } from "../api/payment_methods_client";

class Order {
  id!: number;

  aasm_state!: string;

  discount_percentage!: number;

  note?: string;

  member?: number;

  name!: string;

  items!: Item[]; // Reference to an Item object

  total_f!: number;

  subtotal_f!: number;

  orderable_type!: string;

  orderable_id!: number;

  created_at!: string;

  updated_at!: string;

  payment_method!: IPaymentMethod | null;

  // Extra Properties

  store!: OrdersStore;

  itemsClient!: ItemsClient;

  isLoading = false;

  constructor(store: OrdersStore, params: IOrder) {
    makeAutoObservable(this, {
      id: false,
    });

    this.store = store;

    this.refresh(params);
  }

  refresh(json: IOrder): void {
    this.id = json.id;
    this.aasm_state = json.aasm_state;
    this.discount_percentage = json.discount_percentage;
    this.note = json.note;
    this.member = json.member;
    this.name = json.name;
    this.subtotal_f = json.subtotal_f;
    this.total_f = json.total_f;
    this.created_at = json.created_at;
    this.updated_at = json.updated_at;
    this.payment_method = json.payment_method;
    this.orderable_type = json.orderable_type;
    this.orderable_id = json.orderable_id;
    this.items = json.items.map((item: IItem) => new Item(this, item));
  }

  *createItem(
    product_id: number,
    quantity: number
  ): Generator<Promise<ICreateResponse>, void, ICreateResponse> {
    try {
      this.isLoading = true;
      const response = yield itemsClient.create(this.id, {
        product_id,
        quantity,
      });

      // this.items.push(new Item(this, json));
      this.refresh(response.order);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  *update(params: IUpdateParams): Generator<Promise<IOrder>, void, IOrder> {
    try {
      const json = yield ordersClient.update(this.id, params);
      this.refresh(json);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  *complete() {
    try {
      this.isLoading = true;
      yield ordersClient.complete(this.id);
      this.store.orders.delete(String(this.id));
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  *cancel() {
    try {
      this.isLoading = true;
      yield ordersClient.cancel(this.id);
      this.store.orders.delete(String(this.id));
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  get discounted_total(): number {
    const t = (this.subtotal * this.discount_percentage) / 100;
    return Math.round(t * 100) / 100;
  }

  get subtotal(): number {
    return this.subtotal_f;
  }

  get total(): number {
    return this.total_f;
  }

  get key(): string {
    return `${this.name}-${this.id}-${this.created_at}`.toLowerCase();
  }
}

export default Order;
