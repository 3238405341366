import * as Yup from "yup";

export const updatePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("password is required")
    .min(5, "password is too short")
    .max(100, "password is too long"),
  passwordConfirmation: Yup.string()
    .required("password confirmation is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});
