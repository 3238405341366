import React from "react";
import { FormikProps } from "formik";
import { FormValues, IComponentProps } from "./type";
import { Container, FormWrapper, Label, Button, Error } from "./style";
import LoadingSpinner from "../../controls/loading_spinner";
import Input from "../../controls/input";

const Form: React.FunctionComponent<
  FormikProps<FormValues> & IComponentProps
> = (props: FormikProps<FormValues> & IComponentProps) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    status,
    t,
  } = props;

  return (
    <Container>
      <FormWrapper>
        <p>{status}</p>
        <form onSubmit={handleSubmit} id="sign-up-form">
          <Label>{t("sign-up-form.shop-name")}</Label>
          <Input
            type="text"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            placeholder={t("sign-up-form.your-shop-name")}
          />
          {errors.name && touched.name && <Error>{t(errors.name)}</Error>}
          <Label>{t("email")}</Label>
          <Input
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            placeholder="Email"
          />
          {errors.email && touched.email && <Error>{t(errors.email)}</Error>}
          <Label>{t("password")}</Label>
          <Input
            type="password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            placeholder={t("password")}
          />
          {errors.password && touched.password && (
            <Error>{t(errors.password)}</Error>
          )}
          <Label>{t("password-confirmation")}</Label>
          <Input
            type="password"
            name="passwordConfirmation"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.passwordConfirmation}
            placeholder={t("password-confirmation")}
          />
          {errors.passwordConfirmation && touched.passwordConfirmation && (
            <Error>{t(errors.passwordConfirmation)}</Error>
          )}
          <Button
            type="submit"
            disabled={
              isSubmitting ||
              !!(errors.email && touched.email) ||
              !!(errors.password && touched.password) ||
              !!(errors.passwordConfirmation && touched.passwordConfirmation)
            }
          >
            {isSubmitting ? <LoadingSpinner /> : t("sign-up-form.submit")}
          </Button>
        </form>
      </FormWrapper>
    </Container>
  );
};

export default Form;
