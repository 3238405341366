import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import { Container, Title, Description, ChartContainer } from "./style";
import { IComponentProps } from "./types";
import { dashboardClient } from "../../../api";

function getDateFormat(groupBy: string): string {
  const formatMap: { [key: string]: string } = {
    hour: "LT",
    day: "L",
  };

  return formatMap[groupBy] || "LT";
}

const Sales: React.FC<IComponentProps> = ({ range, t }) => {
  const [data, setData] = React.useState<any>([]);

  React.useEffect(() => {
    async function loadData() {
      const response = await dashboardClient.sales(
        range.from.toISOString(),
        range.to.toISOString(),
        range.groupBy
      );

      setData(response.data);
    }

    loadData();
  }, [range]);

  const dateFormatter = React.useCallback(
    (date: string) => {
      return moment(date).format(getDateFormat(range.groupBy));
    },
    [range.groupBy]
  );

  return (
    <Container>
      <Title>{t("sales-title")}</Title>
      <Description>{t("sales-description")}</Description>

      <ChartContainer>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="created_at" tickFormatter={dateFormatter} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="total"
              strokeWidth={3}
              stroke="#fd3a5c"
            />
          </LineChart>
        </ResponsiveContainer>
      </ChartContainer>
    </Container>
  );
};

export default Sales;
