import styled from "styled-components";

export const Container = styled.div`
  max-width: 1280px;
  margin: auto;
  padding: 10px;
`;

export const Title = styled.h1`
  margin-bottom: 0;
`;

export const Description = styled.p`
  max-width: 590px;
  margin-bottom: 50px;
`;

export const Horizontal = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const CardContainer = styled.div`
  max-width: 360px;
`;
