import { makeAutoObservable } from "mobx";
import {
  IDailyReportSetting,
  IUpdateDailyReportSettingParams,
} from "../api/daily_report_settings_client";
import type DailyReportSettingsStore from "./daily_report_settings_store";
import { dailyReportSettingsClient } from "../api";

class DailyReportSetting {
  id!: IDailyReportSetting["id"];
  day!: IDailyReportSetting["day"];
  duration!: IDailyReportSetting["duration"];
  closes_at!: IDailyReportSetting["closes_at"];
  active!: IDailyReportSetting["active"];
  created_at!: IDailyReportSetting["created_at"];
  updated_at!: IDailyReportSetting["updated_at"];

  // Extra Properties
  store!: DailyReportSettingsStore;

  isLoading = false;

  constructor(store: DailyReportSettingsStore, params: IDailyReportSetting) {
    makeAutoObservable(this, {
      id: false,
    });

    this.store = store;
    this.refresh(params);
  }

  refresh(json: IDailyReportSetting): void {
    this.id = json.id;
    this.day = json.day;
    this.duration = json.duration;
    this.closes_at = json.closes_at;
    this.active = json.active;
    this.created_at = json.created_at;
    this.updated_at = json.updated_at;
  }

  *update(
    params: IUpdateDailyReportSettingParams
  ): Generator<Promise<IDailyReportSetting>, void, IDailyReportSetting> {
    try {
      this.isLoading = true;
      const json = yield dailyReportSettingsClient.update(this.id, params);
      this.refresh(json);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  *trigger() {
    yield dailyReportSettingsClient.trigger(this.id);
  }

  get key(): string {
    return `${this.day}-${this.id}-${this.created_at}`.toLowerCase();
  }
}

export default DailyReportSetting;
