import React from "react";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import Component from "./component";
import { useStores } from "../../contexts/store_context";
import Order from "../../stores/order";
import { printCodes, print } from "../../lib/print";

const Injected = withTranslation()(Component);

const OrderFormIndex = ({ order }: { order: Order }) => {
  const stores = useStores();

  const productOptions = stores?.tablePageStore.getProductsOptions;

  const paymentMethods = stores?.tablePageStore.getPaymentMethods;

  const getProductById = (key: string) =>
    stores?.tablePageStore.getProductById(key);

  const printCallback = () => print(stores?.tablePageStore.company, order);

  const printCodeCallback = () => printCodes(order);

  const itemInputReferenceCallback = React.useCallback((reference: any) => {
    if (stores?.tablePageStore) {
      stores.tablePageStore.itemInputs.current[order.orderable_id] = reference;
    }
  }, []);

  return (
    <Injected
      order={order}
      printCallback={printCallback}
      printCodeCallback={printCodeCallback}
      getProductById={getProductById}
      productOptions={productOptions}
      paymentMethods={paymentMethods}
      itemInputReferenceCallback={itemInputReferenceCallback}
    />
  );
};

export default observer(OrderFormIndex);
