import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100vh;
  justify-content: center;
  align-items: center;

  color: ${(props) => props.theme.colors.black};
`;

const Title = styled.h1``;

const Description = styled.p``;

const UnauthorizedPage: React.FC<WithTranslation> = ({
  t,
}: WithTranslation) => {
  return (
    <Container>
      <Title>{t("unauthorized-page.title")} 😅</Title>
      <Description>{t("unauthorized-page.description")}</Description>
    </Container>
  );
};

export default withTranslation()(UnauthorizedPage);
