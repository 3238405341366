import React from "react";
import { Moment } from "moment";
import { useStores } from "../../contexts/store_context";
import {
  Container,
  Title,
  Header,
  PaginationContainer,
  Description,
  ListContainer,
  Horizontal,
} from "./style";
import LedgerList from "../../components/ledger_list";
import Pagination from "../../components/pagination";
import DateRanges from "../../components/date_ranges";
import LedgerForm from "../../components/ledger_form";
import SubscriptionModal from "../../components/subscription_modal";
import useSubscribed from "../../hooks/useSubscribed";
import { today } from "../../components/date_ranges/ranges";
import { IComponentProps } from "./types";

const LedgersListPage: React.FC<IComponentProps> = ({ t }) => {
  const stores = useStores();
  const [subscribed] = useSubscribed();

  const ledgers = stores?.ledgerListPageStore.current_page_ledgers || [];

  const handleDateRangeChange = React.useCallback(
    (date: { from: Moment; to: Moment }) => {
      const prev = stores?.ledgerListPageStore.filters;
      stores?.ledgerListPageStore.setFilters({
        ...prev,
        from: date.from.toISOString(),
        to: date.to.toISOString(),
      });
    },
    [stores?.ledgerListPageStore]
  );

  React.useEffect(() => {
    handleDateRangeChange(today());
  }, [stores?.ledgerListPageStore]);

  const onPageClick = React.useCallback(
    (page: number) => {
      stores?.ledgerListPageStore.setCurrentPage(page);
    },
    [stores?.ledgerListPageStore]
  );

  return (
    <>
      {!subscribed && <SubscriptionModal />}

      <Container>
        <Header>
          <Title id="ledger-list-page-title">
            {t("ledger-list-page.title")}
          </Title>
          <Description>{t("ledger-list-page.description")}</Description>
          .<LedgerForm />
        </Header>

        <ListContainer>
          <Horizontal>
            <DateRanges onDateChange={handleDateRangeChange} />

            <PaginationContainer>
              <Pagination
                current_page={stores?.ledgerListPageStore.current_page || 1}
                last_page={stores?.ledgerListPageStore.last_page || 1}
                onPageClick={onPageClick}
              />
            </PaginationContainer>
          </Horizontal>
          <LedgerList ledgers={ledgers} />
        </ListContainer>
      </Container>
    </>
  );
};

export default LedgersListPage;
