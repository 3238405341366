import { makeAutoObservable } from "mobx";
import { productOptionsClient } from "../api";
import type {
  IProductOption,
  IUpdateParams,
} from "../api/product_options_client";
import ProductOptionGroup from "./product_option_group";

class ProductOption {
  id!: number;
  name!: string;
  price_f!: number;
  product_option_group_id!: number;
  updated_at!: string;
  created_at!: string;

  product_option_group!: ProductOptionGroup;

  isLoading = false;

  constructor(
    product_option_group: ProductOptionGroup,
    params: IProductOption
  ) {
    makeAutoObservable(this, {
      id: false,
    });

    this.product_option_group = product_option_group;
    this.refresh(params);
  }

  refresh(json: IProductOption): void {
    this.id = json.id;
    this.name = json.name;
    this.price_f = json.price_f;
    this.product_option_group_id = json.product_option_group_id;
    this.updated_at = json.updated_at;
    this.created_at = json.created_at;
  }

  *update(
    params: IUpdateParams
  ): Generator<Promise<IProductOption>, void, IProductOption> {
    try {
      this.isLoading = true;
      const json = yield productOptionsClient.update(this.id, params);
      this.refresh(json);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  delete(): void {
    this.product_option_group.deleteProductOption(this.id);
  }

  get key(): string {
    return `${this.id}-${this.name}-${this.product_option_group_id}-${this.created_at}`.toLowerCase();
  }
}

export default ProductOption;
