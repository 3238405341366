import { motion } from "framer-motion";
import styled, { css } from "styled-components";

interface IContainerProps {
  width: number;
  height: number;
  top: number;
  left: number;
  hasOrder?: boolean;
}

export const Container = styled(motion.div)<IContainerProps>`
  position: absolute;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ hasOrder, theme }) =>
    hasOrder &&
    css`
      background-color: ${theme.colors.red};
    `}
`;
