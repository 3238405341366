import React from "react";
import { PayPalButton } from "react-paypal-button-v2";
import {
  Container,
  Vertical,
  Title,
  PlanContainer,
  Price,
  Checked,
  SubscribeContainer,
  Description,
  Item,
} from "./style";
import { planPurchasesClient } from "../../api";
import LoadingPage from "../loading_page";
import { useStores } from "../../contexts/store_context";
import { IComponentProps } from "./types";

export const PRICE = 12.99;

const BillingPage: React.FC<IComponentProps> = ({ t }: IComponentProps) => {
  const stores = useStores();

  React.useEffect(() => {
    stores?.billingPageStore.load();
  }, [stores]);

  const company = stores?.billingPageStore.company;

  if (!company) {
    return <LoadingPage />;
  }

  return (
    <Container>
      <Vertical>
        <Title>{t("billing-page.title")}</Title>
        <Description>{t("billing-page.description")}</Description>
      </Vertical>
      <PlanContainer>
        <Title>{t("profile-page.free.title")}</Title>
        <Description>{t("profile-page.free.description")}</Description>

        <Item>{t("plan.digital-menu")}</Item>
        <Item>{t("plan.products")}</Item>

        <Price></Price>

        {company.hasActivePro == false && (
          <SubscribeContainer>
            <Checked />
            {t("active")}
          </SubscribeContainer>
        )}
      </PlanContainer>
      <PlanContainer>
        <Title highlight>{t("profile-page.pro.title")}</Title>
        <Description>{t("profile-page.pro.description")}</Description>

        <ul>
          <Item>{t("plan.video")}</Item>
          <Item>{t("plan.dashboard")}</Item>
          <Item>{t("plan.orders")}</Item>
          <Item>{t("plan.tables")}</Item>
          <Item>{t("plan.ledgers")}</Item>
        </ul>

        <Price>$ {PRICE}</Price>

        {company.hasActivePro === false && (
          <>
            <PayPalButton
              amount={PRICE}
              shippingPreference="NO_SHIPPING"
              onSuccess={async (details: any, data: any) => {
                await planPurchasesClient.create(data.orderID);

                window.location.reload();
              }}
              options={{
                clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
              }}
            />
          </>
        )}
        {company.hasActivePro == true && (
          <SubscribeContainer>
            <>
              <Checked />
              {t("active")}
            </>
          </SubscribeContainer>
        )}
      </PlanContainer>
    </Container>
  );
};

export default BillingPage;
