import { makeAutoObservable } from "mobx";
import { ledgersClient } from "../api";
import type { ILedger, IUpdateParams } from "../api/ledgers_client";
import type LedgersStore from "./ledgers_store";
class Ledger {
  id!: number;
  description!: string;
  category!: string;
  total_f!: number;
  billed_at!: string;
  tag_list!: string;
  created_at!: string;
  updated_at!: string;

  store!: LedgersStore;

  constructor(store: LedgersStore, params: ILedger) {
    makeAutoObservable(this, {
      id: false,
      store: false,
    });

    this.store = store;

    this.refresh(params);
  }

  refresh(json: ILedger): void {
    this.id = json.id;
    this.description = json.description;
    this.category = json.category;
    this.total_f = json.total_f;
    this.billed_at = json.billed_at;
    this.tag_list = json.tag_list;
    this.created_at = json.created_at;
    this.updated_at = json.updated_at;
  }

  *update(params: IUpdateParams): Generator<Promise<ILedger>, void, ILedger> {
    try {
      const json = yield ledgersClient.update(this.id, params);
      this.refresh(json);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  delete(): void {
    this.store.delete(this);
  }

  get key(): string {
    return `${this.category}-${this.id}-${this.created_at}`.toLowerCase();
  }

  get tag_list_set(): Set<string> {
    return new Set(this.tag_list);
  }
}

export default Ledger;
