import { makeAutoObservable } from "mobx";
import { productsClient, profileClient } from "../api";
import { ICompany } from "../api/profile_client";
import { ITag } from "../api/products_client";
import Company from "./company";
import ProductsStore from "./products_store";
import Product from "./product";

class DigitalMenuPageStore {
  company!: Company;

  tags_by_priority: string[] = [];

  productsStore!: ProductsStore;

  is_loading = false;

  constructor() {
    this.productsStore = new ProductsStore();

    makeAutoObservable(this);
  }

  *load() {
    try {
      this.is_loading = true;
      const company: ICompany = yield profileClient.show();
      this.company = new Company(company);

      yield this.productsStore.loadAll();
      yield this.loadTags();
    } finally {
      this.is_loading = false;
    }
  }

  *loadTags() {
    const tags_sorted_by_priority: { data: ITag[] } =
      yield productsClient.all_tags(true);

    this.tags_by_priority = tags_sorted_by_priority.data.map((tag) => tag.name);
  }

  get products(): Product[] {
    const products = Array.from(this.productsStore.products.values());

    const sorted = products.sort((a, b) => {
      return a.priority - b.priority;
    });

    return sorted;
  }
}

export default DigitalMenuPageStore;
