import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 1em;
`;

export const ProductListContainer = styled.div`
  width: 100%;
  max-width: 1280px;
`;

export const Header = styled.div`
  width: 100%;
  max-width: 1280px;
`;

export const Title = styled.h1`
  margin-bottom: 0;
  max-width: 768px;
`;

export const Description = styled.p``;

export const Tour = styled.a`
  color: ${(props) => props.theme.colors.blue};
  cursor: pointer;
`;

export const Horizontal = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;
