import React from "react";
import { Moment } from "moment";
import { useStores } from "../../contexts/store_context";
import {
  Container,
  Title,
  Header,
  PaginationContainer,
  Description,
  OrderListContainer,
} from "./style";
import OrderList from "../../components/order_list";
import Pagination from "../../components/pagination";
import DateRanges from "../../components/date_ranges";
import SubscriptionModal from "../../components/subscription_modal";
import useSubscribed from "../../hooks/useSubscribed";
import { IComponentProps } from "./types";
import { today } from "../../components/date_ranges/ranges";

const OrdersPage: React.FC<IComponentProps> = ({ t }) => {
  const stores = useStores();
  const [subscribed] = useSubscribed();

  const orders = stores?.orderListPageStore.current_page_orders || [];

  const handleDateRangeChange = React.useCallback(
    (date: { from: Moment; to: Moment }) => {
      const prev = stores?.orderListPageStore.filters;
      stores?.orderListPageStore.setFilters({
        ...prev,
        from: date.from.toISOString(),
        to: date.to.toISOString(),
      });
    },
    [stores]
  );

  const onPageClick = React.useCallback(
    (page: number) => {
      stores?.orderListPageStore.setCurrentPage(page);
    },
    [stores]
  );

  React.useLayoutEffect(() => {
    handleDateRangeChange(today());
  }, [stores]);

  return (
    <>
      {!subscribed && <SubscriptionModal />}

      <Container>
        <Header>
          <Title>{t("order-list-page.title")}</Title>

          <Description>{t("order-list-page.description")}</Description>

          <DateRanges onDateChange={handleDateRangeChange} />
        </Header>

        <PaginationContainer>
          <Pagination
            current_page={stores?.orderListPageStore.current_page || 1}
            last_page={stores?.orderListPageStore.last_page || 1}
            onPageClick={onPageClick}
          />
        </PaginationContainer>

        <OrderListContainer>
          <OrderList orders={orders} />
        </OrderListContainer>
      </Container>
    </>
  );
};

export default OrdersPage;
