import styled from "styled-components";

export const Input = styled.input<{ font_zie?: string }>`
  width: 100%;
  height: 100%;
  background-color: transparent;

  border: 0;
  border-radius: 0;
  padding: 0.2em;

  font-weight: bold;
  font-family: inherit;
  font-size: ${(props) => props.font_zie || "0.9em"};

  :focus {
    background-color: ${(props) => props.theme.colors.gray};
    scale: 1.02;
  }
`;
