import { useState, useEffect, useCallback } from "react";
import { Input } from "./style";

interface IComponentProps {
  value: string | number;
  updateCallback: (value: any) => void;
  type: string;
  palceholder?: string;
  test_id?: string;
  max_length?: number;
  transformTextCallback?: (value: string) => string;
}

const InputCell = ({
  value: initialValue,
  updateCallback,
  type = "text",
  palceholder,
  test_id,
  max_length,
  transformTextCallback,
}: IComponentProps) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const onChange = (e: { target: { value: string } }) => {
    if (transformTextCallback) {
      setValue(transformTextCallback(e.target.value));
    } else {
      setValue(e.target.value);
    }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = useCallback(async () => {
    if (initialValue === value) {
      return; // Skip if value is unchanged
    }

    try {
      await updateCallback(value);
    } catch (e) {
      // When update fails, revert to the original values
      setValue(initialValue);
    }
  }, [value]);

  const onKeyDown = useCallback(
    (event: { key: string }) => {
      if (event.key === "Enter") {
        try {
          updateCallback(value);
        } catch (e) {
          // When update fails, revert to the original values
          setValue(initialValue);
        }
      }
    },
    [value]
  );

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Input
      type={type}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      data-testid={test_id}
      placeholder={palceholder}
      maxLength={max_length}
    />
  );
};

export default InputCell;
