import React from "react";
import { useThrottledCallback } from "use-debounce";
import { times } from "lodash";
import {
  Container,
  Name,
  Price,
  Count,
  InputContainer,
  Product,
  RightContainer,
  CountButton,
  CountButtonContainer,
} from "./style";
import { IComponentProps } from "./types";
import useDropdown from "../../../hooks/useDropdown";
import ProductOptionGroupForm from "./product_option_group_form";
import { enqueueSnackbar } from "notistack";

const WAIT_INTERVAL = 800; // Input value change wait interval

/**
 * FC Component
 * renders a item quantity input.
 *
 * @param  {item} item
 * @param  {product} product
 * @param  {inputRef} References for the input, parent component manages child input ref in order to focus programatically
 *
 * @test
 * it should pass floors, selectFloor, createFloor and deleteFloor
 */
const Item: React.FC<IComponentProps> = ({
  item,
  product,
  inputRef,
  t,
}: IComponentProps) => {
  const [openButtons, setOpenButtons, containerRef] = useDropdown();

  const [value, setValue] = React.useState(item.quantity);

  const onChangeCallback = async (quantity: number) => {
    try {
      await item.setQuantity(quantity);

      enqueueSnackbar(t("order-form.item.update.success"), {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(t("order-form.item.update.failure"), {
        variant: "error",
      });
    }
  };

  const debounced = useThrottledCallback((new_value) => {
    onChangeCallback(Number(new_value));
  }, WAIT_INTERVAL);

  return (
    <Container ref={containerRef}>
      <Product
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => {
          setOpenButtons((prev: boolean) => {
            return !prev;
          });
        }}
        className="item-product-container"
      >
        <Name>{product.name}</Name>
        <RightContainer>
          <Price>$ {product.price}</Price>
          <Count>
            <InputContainer>
              <input
                ref={inputRef}
                type="number"
                value={value !== item.quantity ? value : item.quantity}
                min={0}
                max={100}
                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                  const newValue = Number(event.currentTarget.value);
                  setValue(newValue);
                  debounced(newValue);
                }}
              />
            </InputContainer>
          </Count>
        </RightContainer>
      </Product>

      {openButtons && (
        <>
          <CountButtonContainer>
            {times(7, (i) => (
              <CountButton
                className="item-count-button"
                key={`${name}-${i}`}
                whileTap={{ scale: 0.9 }}
                onClick={() => {
                  setValue(i);
                  debounced(i);
                }}
              >
                {i}
              </CountButton>
            ))}

            <CountButton
              className="item-count-plus-button"
              whileTap={{ scale: 0.9 }}
              onClick={() => {
                setValue((prev) => {
                  debounced(prev + 1);
                  return prev + 1;
                });
              }}
            >
              +
            </CountButton>
          </CountButtonContainer>
          {product.product_option_groups.map((product_option_group) => (
            <div key={product_option_group.key}>
              <ProductOptionGroupForm
                item={item}
                product_option_group={product_option_group}
              />
              {!product_option_group.product_options.length &&
                t("product-option-group-form.empty-options")}
            </div>
          ))}
        </>
      )}
    </Container>
  );
};

Item.defaultProps = {
  inputRef: null,
};

export default Item;
