import React from "react";
import { IComponentProps } from "./types";
import { Container, TabContainer } from "./style";
import Button from "../../controls/button";

const Tabs: React.FC<IComponentProps> = ({
  tabs,
  onClickCallback,
  children,
}) => {
  const [active_tab, setActiveTab] = React.useState<string>(tabs[0].value);

  return (
    <Container>
      <TabContainer>
        {tabs.map((tab, index) => (
          <Button
            key={`${tab.value}-${index}`}
            onClick={() => {
              if (onClickCallback) {
                onClickCallback(tab.value);
              }

              setActiveTab(tab.value);
            }}
            disabled={active_tab === tab.value}
          >
            {tab.displayed_name}
          </Button>
        ))}
      </TabContainer>
      {children(active_tab)}
    </Container>
  );
};

export default Tabs;
