import React from "react";
import Product from "./product";
import { Container, Header, Column, List, EmptyProducts } from "./style";
import { IComponentProps } from "./types";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";

const ProductListComponent: React.FunctionComponent<IComponentProps> = ({
  products,
  t,
}: IComponentProps) => {
  const isMobile = useBreakpoint(down("md"));

  return (
    <Container>
      <Header>
        <Column>{t("product-list.column.name")}</Column>
        {!isMobile && <Column>{t("product-list.column.description")}</Column>}
        <Column>{t("product-list.column.price")}</Column>
        {!isMobile && (
          <>
            <Column>{t("product-list.column.published")}</Column>
            <Column>{t("product-list.column.tag")}</Column>
            <Column>{t("product-list.column.code")}</Column>
            <Column>{t("product-list.column.created")}</Column>
            <Column>{t("product-list.column.options")}</Column>
            <Column>{t("product-list.column.images")}</Column>
            <Column>{t("product-list.column.delete")}</Column>
          </>
        )}
        {isMobile && <Column>{t("product-list.column.edit")}</Column>}
      </Header>

      {products.length < 1 && (
        <EmptyProducts>{t("product-list.no-products-found")}</EmptyProducts>
      )}

      <List>
        {products.map((product) => (
          <Product key={product.key} product={product} />
        ))}
      </List>
    </Container>
  );
};

export default ProductListComponent;
