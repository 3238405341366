import styled from "styled-components";
import { down } from "styled-breakpoints";

export const EmptyTables = styled.h3``;

export const List = styled.div`
  width: 100%;
  max-width: 360px;
  padding: 10px 20px;

  ${down("md")} {
    width: 100%;
    max-width: initial;
  }
`;

export const Input = styled.input`
  background-color: ${(props) => props.theme.colors.gray};
  border: 1px solid transparent;
  height: 40px;
  border-color: #e7e7e9;
  max-width: 200px;
  width: 100%;
  appearance: none;
  color: #0d0c22;
  border-radius: 8px;
  padding: 10px 16px;
  outline: none;

  transition: all 200ms ease;

  &:focus {
    background-color: #fff;
    border-color: rgba(234, 76, 137, 0.4);
    box-shadow: 0 0 0 4px rgba(234, 76, 137, 0.1);
  }
`;
