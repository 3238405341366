import { AxiosInstance } from "axios";

export type TCurrentPlan = "free" | "pro";
export type TCurrentPlanStatus = "active" | "inactive";

export interface ICompany {
  id: number;
  name: string;
  slug: string;
  description: string;
  short_description: string;
  tag_priority: string;
  instagram: string;
  subscription_id: string;
  subscription_plan: string;
  subscription_plan_id: string;
  subscription_status: string;
  current_plan: TCurrentPlan;
  current_plan_status: TCurrentPlanStatus;
  time_zone: string;
  locale: string;
}

export type IShowResposne = ICompany;

export type IUpdateResponse = ICompany;

export type IUpdateParams = Partial<ICompany>;

class ProfileClient {
  restClient!: AxiosInstance;

  constructor(restClient: AxiosInstance) {
    this.restClient = restClient;
  }

  async show(): Promise<IShowResposne> {
    const response = await this.restClient.get("/api/v1/companies/profile");

    return response.data;
  }

  async update(params: IUpdateParams): Promise<IUpdateResponse> {
    const response = await this.restClient.put("/api/v1/companies/profile", {
      company: params,
    });

    return response.data;
  }
}

export default ProfileClient;
