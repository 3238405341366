import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 5px;
  border-radius: 8px;
  padding: 15px 25px;
  transition: all 0.2s ease;

  :hover {
    box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 10%);
  }

  * {
    max-width: 200px;
  }
`;

export const Column = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Button = styled.button<{ hoverColor?: string }>`
  background-color: ${(props) => props.theme.colors.gray};
  outline: none;
  border-radius: 8px;
  color: black;
  text-align: center;
  height: 40px;
  border: none;
  padding: 10px 16px;
  cursor: pointer;

  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) =>
      props.hoverColor ? props.hoverColor : props.theme.colors.grayDarker};
  }
`;
