import { makeAutoObservable } from "mobx";
import { productsClient } from "../api";
import {
  IProduct,
  IIndexResponse,
  ICreateParams,
} from "../api/products_client";
import Product from "./product";
import { IPages } from "./types";

function createPageKey(page: number, per_page: number) {
  return `${page}-${per_page}`;
}

class ProductsStore {
  products: Map<string, Product> = new Map();

  isLoading = false;

  pages: IPages = {};

  constructor() {
    makeAutoObservable(this);
  }

  *load(
    page: number,
    per_page: number
  ): Generator<Promise<IIndexResponse>, IIndexResponse, IIndexResponse> {
    try {
      this.isLoading = true;

      const response = yield productsClient.index(page, per_page);

      // eslint-disable-next-line
      const ids = response.ids;

      ids.forEach((id: string) => {
        this.refreshProductFromServer(id, response.data[id]);
      });

      this.pages[createPageKey(page, per_page)] = ids;

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  *loadAll() {
    this.isLoading = true;
    let page = 1;
    let nextPage: null | number = null;

    do {
      try {
        const response = yield this.load(page, 15);
        nextPage = response.meta.next;
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        page += 1;
      }
    } while (nextPage);

    this.isLoading = false;
  }

  refreshProductFromServer(id: string, json: IProduct): void {
    const foundProduct = this.products.get(id);

    if (!foundProduct) {
      const product = new Product(this, json);
      this.products.set(id, product);
    } else {
      // Delete and recreate object to rerender TanStack table
      this.products.delete(id);
      const product = new Product(this, json);
      this.products.set(id, product);
    }
  }

  *create(params: ICreateParams): Generator<Promise<IProduct>, void, IProduct> {
    try {
      const json = yield productsClient.create(params);
      const product = new Product(this, json);
      this.products.set(String(product.id), product);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  *remove(product: Product): Generator<Promise<void>, void, void> {
    try {
      yield productsClient.delete(product.id);
      this.products.delete(String(product.id));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default ProductsStore;
