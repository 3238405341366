import React from "react";
import { observer } from "mobx-react";
import Component from "./component";
import { IComponentProps, IPosition } from "./types";
import { useStores } from "../../../contexts/store_context";
import { print } from "../../../lib/print";

function inject(InnerComponent: React.FC<IComponentProps>) {
  const OuterComponent = (
    props: Omit<
      IComponentProps,
      "onClick" | "onDragStop" | "onResizeStop" | "disableDragging"
    >
  ) => {
    const { table } = props;
    const stores = useStores();

    const onClick = React.useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        stores?.tablePageStore.selectTable(table.id);
        if (stores?.tablePageStore.scrollToTable !== undefined) {
          stores?.tablePageStore.scrollToTable(table.id);
        }

        if (event.altKey) {
          if (table.order) {
            print(stores?.tablePageStore.company, table.order);
          }
        }
      },
      [stores, table]
    );

    const onDragStop = React.useCallback(
      (e: any, data: IPosition) => {
        table.update({ left: data.x, top: data.y });
      },
      [table]
    );

    const onResizeStop = React.useCallback(
      (e: any, direction: any, ref: any, delta: any, position: IPosition) => {
        table.update({
          width: parseInt(ref.style.width, 10),
          height: parseInt(ref.style.height, 10),
          left: position.x,
          top: position.y,
        });
      },
      [table]
    );

    const isEditingTables = stores?.tablePageStore.isEditingTables;

    return (
      <InnerComponent
        table={table}
        onClick={onClick}
        onDragStop={onDragStop}
        onResizeStop={onResizeStop}
        disableDragging={!isEditingTables}
      />
    );
  };

  return OuterComponent;
}

export default observer(inject(observer(Component)));
