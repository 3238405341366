import styled from "styled-components";

export const Container = styled.div`
  padding: 0.3em;
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;

  border-radius: 4px;
  background: white;

  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  overflow: visible;
  min-height: 35px;
  max-height: 40em;
  height: fit-content;

  padding: 0 0.2em 0.2em 0.2em;
  z-index: 1;

  position: absolute;
  top: 0px;
  left: 0px;

  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  color: ${(props) => props.theme.colors.black};
  font-size: 0.8em;
  padding-left: 0.3em;
  margin-top: 0.6em;
`;

export const InlineText = styled.span`
  font-size: 0.8em;
  margin-right: 0.3em;
`;

export const Input = styled.input`
  font-family: inherit;
  background-color: ${(props) => props.theme.colors.gray};
  border: 1px solid transparent;
  width: 100%;
  color: ${(props) => props.theme.colors.black};
  padding: 0.2em 0.1em;
`;

export const TagList = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const TagContainer = styled.div<{ focused?: boolean }>`
  display: flex;
  align-items: center;

  background-color: ${(props) =>
    props.focused ? props.theme.colors.gray : props.theme.colors.white};

  padding: 0.2em 0.2em;

  :hover {
    background-color: ${(props) => props.theme.colors.gray};
  }
`;
