import styled, { css } from "styled-components";
import { Variant } from "./types";

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const Button = styled.button<{
  active?: boolean;
  variant: Variant;
}>`
  margin-right: 10px;
  outline: none;
  border-radius: 8px;
  text-align: center;
  height: 40px;
  border: none;
  padding: 10px 16px;
  cursor: pointer;
  font-weight: 600;
  font-family: "Nunito Sans";

  transition: all 200ms ease;

  ${(props) =>
    props.variant == "gray" &&
    css`
      background-color: ${props.active
        ? props.theme.colors.grayDarker
        : props.theme.colors.gray};

      :hover {
        background-color: ${props.theme.colors.grayDarker};
      }
    `}

  ${(props) =>
    props.variant == "borderless" &&
    css`
      background-color: transparent;
      color: ${props.active
        ? props.theme.colors.blue
        : props.theme.colors.grayDarker};

      :hover {
        color: ${props.theme.colors.blue};
      }
    `}
`;
