import React from "react";
import { FormikProps } from "formik";
import { FormValues, IComponentProps } from "./types";
import { Container, FormWrapper, Label, Button, Error } from "./style";
import LoadingSpinner from "../../controls/loading_spinner";
import Input from "../../controls/input";

const Form: React.FunctionComponent<
  FormikProps<FormValues> & IComponentProps
> = (props: FormikProps<FormValues> & IComponentProps) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    status,
    t,
  } = props;

  return (
    <Container>
      <FormWrapper>
        <p>{status}</p>
        <form onSubmit={handleSubmit} id="sign-in-form">
          <Label>{t("email")}</Label>
          <Input
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            placeholder="Email"
          />
          {errors.email && touched.email && <Error>{t(errors.email)}</Error>}

          <Button
            type="submit"
            disabled={isSubmitting || !!(errors.email && touched.email)}
          >
            {isSubmitting ? (
              <LoadingSpinner />
            ) : (
              t("reset-password-form.submit")
            )}
          </Button>
        </form>
      </FormWrapper>
    </Container>
  );
};

export default Form;
