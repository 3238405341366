import { makeAutoObservable } from "mobx";
import {
  IPaymentMethod,
  IUpdatePaymentMethodParams,
} from "../api/payment_methods_client";
import type PaymentMethodsStore from "./payment_methods_store";
import { paymentMethodsClient } from "../api";

class PaymentMethod {
  id!: number;
  name!: string;
  default!: boolean; // When new order is created, default payment method is assigned to the order
  discarded_at!: string | null;
  created_at!: string;
  updated_at!: string;

  // Extra Properties
  store!: PaymentMethodsStore;

  isLoading = false;

  constructor(store: PaymentMethodsStore, params: IPaymentMethod) {
    makeAutoObservable(this, {
      id: false,
    });

    this.store = store;
    this.refresh(params);
  }

  refresh(json: IPaymentMethod): void {
    this.id = json.id;
    this.name = json.name;
    this.default = json.default;
    this.discarded_at = json.discarded_at;
    this.created_at = json.created_at;
    this.updated_at = json.updated_at;
  }

  *update(
    params: IUpdatePaymentMethodParams,
  ): Generator<Promise<IPaymentMethod>, void, IPaymentMethod> {
    // Backend will set default:false for all payment_methods when default param ture is provided.
    if (params.default == true) {
      this.store.payment_methods.forEach((payment_method) => {
        payment_method.default = false;
      });
    }

    const json = yield paymentMethodsClient.update(this.id, params);
    this.store.refreshFromServer(String(json.id), json);
  }

  get key(): string {
    return `${this.name}-${this.id}-${this.created_at}`.toLowerCase();
  }
}

export default PaymentMethod;
