import React from "react";
import Joyride, { CallBackProps } from "react-joyride";
import TablesCanvas from "../../components/tables_canvas";
import TablesList from "../../components/tables_list";
import { useStores } from "../../contexts/store_context";
import {
  Container,
  Left,
  Header,
  Title,
  Description,
  Button,
  Horizontal,
  MoveButton,
  Tour,
} from "./style";
import FloorsTabs from "../../components/floors_tabs";
import { IComponentProps } from "./types";
import Hotkeys, { OnKeyFun } from "react-hot-keys";

const steps = (t: any) => [
  {
    target: "#add-table-button",
    content: t("tables-page.steps.1"),
  },
  {
    target: ".table-name-input",
    content: t("tables-page.steps.2"),
  },
  {
    target: ".table-menu",
    content: t("tables-page.steps.3"),
  },
  {
    target: ".new-order-button",
    content: t("tables-page.steps.4"),
  },
  {
    target: "#edit-tables-button",
    content: t("tables-page.steps.5"),
  },
  {
    target: "#create-floor-button",
    content: t("tables-page.steps.6"),
  },
  {
    target: "#floor-name-input",
    content: t("tables-page.steps.7"),
  },
  {
    target: "#delete-floor-menu",
    content: t("tables-page.steps.8"),
  },
];

const TablesMonitor: React.FC<IComponentProps> = ({ t }) => {
  const stores = useStores();
  const [runTour, setRunTour] = React.useState<boolean>(
    localStorage.getItem("tour#tables_page") !== "true"
  );

  React.useEffect(() => {
    stores?.tablePageStore.load();
  }, [stores]);

  React.useEffect(() => {
    if (stores?.tablePageStore.floorsStore.initialized) {
      if (stores?.tablePageStore.floors.length > 0) {
        stores?.tablePageStore.selectFloor(stores?.tablePageStore.floors[0].id);
      }
    }
  }, [stores?.tablePageStore, stores?.tablePageStore.floorsStore.initialized]);

  const selectedFloorId = stores?.tablePageStore.selectedFloorId;

  const onAddTableClick = React.useCallback(() => {
    if (selectedFloorId === undefined) return;

    stores?.tablePageStore.tablesStore.create({
      name: t("Example Table"),
      left: 10,
      top: 10,
      width: 100,
      height: 100,
      floor_id: selectedFloorId,
    });
  }, [stores, selectedFloorId]);

  const onEditTablesClick = React.useCallback(() => {
    stores?.tablePageStore.setIsEditingTables(
      !stores.tablePageStore.isEditingTables
    );
  }, [stores?.tablePageStore.isEditingTables]);

  const onKeyDown: OnKeyFun = React.useCallback(
    (keyName) => {
      switch (keyName) {
        case "enter":
          // Find if table was selected
          const table = stores?.tablePageStore.getTableById(
            stores.tablePageStore.selectedTableId
          );

          // Do nothing when there is no selected table
          if (table == undefined) {
            return;
          }

          // If table has no order, create new order
          // If table already has order, focus product select element
          if (table.hasOrder == false) {
            table.createOrder();
          } else {
            stores?.tablePageStore.focusItemInputByTableId(
              stores.tablePageStore.selectedTableId
            );
          }
      }
    },
    [stores?.tablePageStore.selectedTableId]
  );

  return (
    <Hotkeys keyName="enter" onKeyDown={onKeyDown}>
      <Container>
        <Left>
          <Header>
            <Title>{t("tables-page.title")}</Title>
            <Description>
              {t("tables-page.description")}
              <Tour
                onClick={() => {
                  setRunTour((prev) => !prev);
                }}
              >
                {t("start-tour")}
              </Tour>
            </Description>
          </Header>

          <Horizontal>
            <FloorsTabs />

            <MoveButton
              id="edit-tables-button"
              onClick={onEditTablesClick}
              active={stores?.tablePageStore.isEditingTables ? 1 : 0}
            >
              {stores?.tablePageStore.isEditingTables
                ? t("tables-page.stop-moving-tables")
                : t("tables-page.start-moving-tables")}
            </MoveButton>

            <Button id="add-table-button" onClick={onAddTableClick}>
              {t("tables-page.add-table")}
            </Button>
          </Horizontal>

          <TablesCanvas />
        </Left>
        <TablesList />
        <Joyride
          continuous={true}
          steps={steps(t)}
          showProgress={true}
          showSkipButton={true}
          styles={{
            options: {
              primaryColor: "#fd3a5c",
              zIndex: 1,
            },
          }}
          run={runTour}
          callback={(data: CallBackProps) => {
            if (["finished", "skipped"].includes(data.status)) {
              localStorage.setItem("tour#tables_page", "true");
            }
          }}
        />
      </Container>
    </Hotkeys>
  );
};

export default TablesMonitor;
