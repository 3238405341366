import styled from "styled-components";
import Moment from "react-moment";

const Container = styled.div`
  max-width: 600px;
  padding: 20px;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.gray};
  gap: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.h5`
  margin: 0.8em 0;
  font-weight: bold;
  font-size: 1em;
`;

const Body = styled.p``;

const Datetime = styled.p`
  color: ${(props) => props.theme.colors.grayDarker};
  align-self: flex-end;
  font-size: 0.7rem;
  margin: 0;
`;

interface IComponentProps {
  title: string;
  body: string;
  datetime: string;
  test_id?: string;
}

const ReviewCard: React.FC<IComponentProps> = ({
  title,
  body,
  datetime,
  test_id,
}) => {
  return (
    <Container data-testid={test_id}>
      <div>
        <Title>{title}</Title>
        <Body>{body}</Body>
      </div>
      <Datetime>
        <Moment fromNow>{datetime}</Moment>
      </Datetime>
    </Container>
  );
};

export default ReviewCard;
