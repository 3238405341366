import styled, { keyframes, css } from "styled-components";
import { ThreeDots as DotsHorizontal } from "@styled-icons/bootstrap/ThreeDots";

const gradient = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

export const Container = styled.div<{ isLoading?: boolean; selected: boolean }>`
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  padding: 15px 25px;
  box-shadow: 0px 0px 15px 1px rgb(0 0 0 / 10%);
  width: 95%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  transition: all 200ms ease;

  ${(props) =>
    props.isLoading &&
    css`
      background: linear-gradient(-45deg, #f8f6f7, #fff, #f6f4f5, #fff);
      background-size: 400% 400%;
      animation: ${gradient} 2s ease infinite;
    `}

  ${(props) =>
    props.selected &&
    css`
      border: 1px solid #4285f4;
    `}
`;

export const OrderContainer = styled.div`
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 25px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 17px;

  div:first-child {
    width: 60%;
  }
`;

export const OrderButton = styled.button`
  display: block;
  background-color: ${(props) => props.theme.colors.gray};
  outline: none;
  border-radius: 8px;
  text-align: center;
  height: 40px;
  border: none;
  padding: 10px 16px;
  margin-top: 15px;
  cursor: pointer;

  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.grayDarker};
  }
`;

export const Dots = styled(DotsHorizontal)`
  transition: all 200ms ease;
  color: ${(props) => props.theme.colors.grayDarker};

  :hover {
    color: ${(props) => props.theme.colors.gray};
    cursor: pointer;
  }
`;

export const EmptyTitle = styled.h3`
  margin: 0;
`;

export const EmptyDescription = styled.p`
  text-align: center;
`;
