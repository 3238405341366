import { IComponentProps } from "./types";
import ProductOptionGroupForm from "../../../product_list/product/product_option_groups_form";

const ProductOptions = ({ product, t }: IComponentProps): JSX.Element => {
  const onProductOptionGroupCreate = () => {
    product.createProductOptionGroup({
      name: t("product-options.new-group"),
      is_mandatory: false,
      selection_limit: 1,
    });
  };

  return (
    <ProductOptionGroupForm
      product_name={product.name}
      productOptionGroups={product.product_option_groups}
      onProductOptionGroupCreate={onProductOptionGroupCreate}
    />
  );
};

export default ProductOptions;
