import { AxiosInstance } from "axios";

export interface ITable {
  id: number;
  name: string;
  left: number;
  top: number;
  width: number;
  height: number;
  floor_id: number;
  order?: number;
  created_at: string;
  updated_at: string;
}

export type ICreateParams = Omit<
  ITable,
  "id" | "created_at" | "order" | "updated_at"
>;

export type IUpdateParams = Partial<ICreateParams>;

class TablesClient {
  restClient!: AxiosInstance;

  constructor(restClient: AxiosInstance) {
    this.restClient = restClient;
  }

  async all(): Promise<ITable[]> {
    const response = await this.restClient.get("/api/v1/companies/tables");
    return response.data;
  }

  async create(body: ICreateParams): Promise<ITable> {
    const response = await this.restClient.post("/api/v1/companies/tables", {
      table: body,
    });
    return response.data;
  }

  async update(id: number, body: IUpdateParams): Promise<ITable> {
    const response = await this.restClient.put(
      `/api/v1/companies/tables/${id}`,
      { table: body }
    );
    return response.data;
  }

  async delete(id: number): Promise<void> {
    const response = await this.restClient.delete(
      `/api/v1/companies/tables/${id}`
    );
    return response.data;
  }
}

export default TablesClient;
