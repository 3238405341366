import styled from "styled-components";
import { up } from "styled-breakpoints";
import { Link as ReactRouterLink } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  ${up("md")} {
    align-items: center;
    height: 100%;
  }
`;

export const LeftContainer = styled.div`
  padding: 15px;
`;

export const Header = styled.h1`
  font-size: 49px;
`;

export const Description = styled.p``;

export const Link = styled(ReactRouterLink)`
  display: block;
  background-color: ${(props) => props.theme.colors.blue};
  color: #fff;
  outline: none;
  border-radius: 8px;
  padding: 15px 30px;
  text-decoration: none;
  cursor: pointer;

  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.blueDarker};
  }
`;
