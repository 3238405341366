export default {
  translation: {
    active: "활성화",
    "already-subscribed": "구독 완료",
    "Are you sure?": "확실합니까?",
    "billing-page": {
      description:
        "가치 있는 콘텐츠를 제공하면 브랜드의 인지도가 높아집니다. 환불 및 구독 취소가 가능합니다.",
      title: "고객분들이 좋아하는 메뉴를 만드세요",
    },
    Cancel: "취소",
    category: "카테고리",
    category: "카테고리",
    "check-email-page": {
      description:
        "확인 이메일을 보내 드렸습니다. 사용하기 전에 계정을 활성화해야 합니다.",
      "sign-in": "계정을 활성화했나요? 여기를 클릭하세요.",
      title: "감사합니다!",
    },
    "click here": "이곳을 클릭하세요",
    code: "코드",
    Complete: "완료",
    Cost: "비용",
    cost: "비용",
    "costTag-description": "매장에 가장 큰 비용을 알아보세요.",
    "costTag-title": "태그별 비용",
    "count-description": "가장 많이 판매된 상품을 찾을 수 있습니다.",
    "count-title": "인기 상품",
    Created: "생성됨",
    created: "생성됨",
    Dashboard: "대시보드",
    "dashboard-page": {
      description:
        "모션 메뉴는 대시보드 페이지를 지속적으로 개선하고 있습니다.",
      title: "대시보드",
    },
    day: {
      0: "월요일",
      1: "화요일",
      2: "수요일",
      3: "목요일",
      4: "금요일",
      5: "토요일",
      6: "일요일",
    },
    delete: "삭제",
    Delete: "삭제",
    description: "설명",
    "digital-menu": "디지털 메뉴",
    "digital-menu-page": {
      description:
        "글자만으로는 충분하지 않습니다. QR 코드 디지털 메뉴를 시도하세요. 더 많은 콘텐츠를 제공할수록 고객들은 더 많은 가치를 느낄 수 있습니다",
      "menu-page-link": "링크를 통해 메뉴 페이지를 확인하세요",
      "product-priority-description":
        "드래그하여 우선순위를 변경할 수 있습니다. 컨텐츠가 있는 상품을 높은 우선순위에 두세요.",
      "qr-link": "QR 코드를 클릭하여 이미지를 다운로드하여 인쇄하세요.",
      "sort-by-products": "상품별로 정렬",
      "sort-by-tags": "태그별로 정렬",
      "tag-priority-description":
        "태그를 드래그하여 우선순위를 변경할 수 있습니다. 태그가 높은 우선순위에 있을수록 먼저 표시됩니다.",
      "tag-priority-title": "먼저 표시할 상품 변경하세요",
      title: "매력적인 컨텐츠를 사용하여 더 많은 관심을 끌어보세요",
    },
    Discount: "할인",
    "dont-have-account": "계정이 없으신가요?",
    "Drop it here!": "여기에 파일을 드롭하세요!",
    "dropzone content": "여기에 파일을 드롭하거나 파일을 선택하려면 클릭하세요",
    "editable-cells": {
      boolean: {
        false: "거짓",
        true: "참",
      },
      tag: {
        available: "옵션",
        empty: "태그 없음",
        input: "태그를 입력하세요",
        new: "새로 만들기",
        selected: "선택됨",
      },
    },
    email: "이메일",
    "empty-tables": "테이블이 없습니다. 시작하려면 새 테이블을 추가하세요.",
    "Failed to reset password": "비밀번호 재설정에 실패했습니다",
    "Failed to sign in": "로그인에 실패했습니다",
    "Failed to sign up": "가입에 실패했습니다",
    "Failed to update password": "비밀번호 업데이트에 실패했습니다",
    "File type not accepted, sorry!": "파일 유형이 허용되지 않습니다",
    "First click new order to fill with items":
      "새 주문을 클릭하여 항목을 채우세요",
    "floors-tabs": {
      delete: "층을 삭제하시겠습니까",
    },
    "Forgot your password?": "비밀번호를 잊으셨나요?",
    "forgot-password": "비밀번호를 잊으셨나요?",
    friday: "금요일",
    "Go back to": "돌아가기",
    "Go To Store": "매장으로 이동",
    "google-sign-in-button": {
      failure: "오류가 발생했습니다.",
      success: "구글 계정으로 로그인되었습니다.",
    },
    "got-suspended": "중지되었습니다",
    here: "이곳을 클릭하세요",
    home: "홈",
    inactive: "비활성",
    income: "수입",
    index: "인덱스",
    "item-quantity-modal": {
      description: "얼마나 추가하시겠습니까?",
      submit: "확인",
    },
    "ledger-form": {
      "billed-at": "청구일",
      bread: "빵",
      coca: "코카콜라",
      created: "성공적으로 생성되었습니다",
      electricity: "전기세",
      failed: "거래 생성에 실패했습니다",
      ham: "햄",
      material: "재료",
      milk: "우유",
      salary: "월급",
      soda: "소다",
      submit: "제출",
      suger: "설탕",
    },
    "ledger-list-page": {
      description:
        "비용 파악과 수입 분석은 중요합니다. 수입 또는 비용을 선택하십시오. 구별을 위해 태그 추가를 권장합니다.",
      title: "최근 수입 및 비용 확인할 수 있습니다!",
    },
    Ledgers: "거래",
    Menu: "메뉴",
    monday: "월요일",
    month: "월",
    "Motion Menu is a place that connects you with your clients!":
      "모션 메뉴는 고객과 연결되는 곳입니다!",
    Name: "이름",
    navbar: {
      billing: "구독",
      dashboard: "대시보드",
      "digital-menu": "디지털 메뉴",
      ledgers: "장부",
      orders: "주문",
      products: "상품",
      profile: "프로필",
      "sign-out": "로그아웃",
      tables: "테이블",
    },
    "No actions": "작업 없음",
    "No products found": "상품을 찾을 수 없습니다",
    "no-confirmation-email": "확인 이메일이 도착하지 않았나요?",
    "no-result": "결과가 없습니다",
    options: "옵션",
    "order-form": {
      cancel: "취소",
      complete: "완료",
      "complete-failed": "완료 할 수 없습니다.",
      completed: "주문이 완료되었습니다.",
      discount: "할인",
      item: {
        update: {
          failure: "수량 업데이트에 실패했습니다.",
          success: "수량이 업데이트되었습니다.",
        },
      },
      "no-items": "주문 내역이 없습니다",
      print: "인쇄",
      "print-code": "코드 인쇄",
      subtotal: "소계",
      total: "총계",
    },
    "order-list": {
      created: "생성일",
      dicsount: "할인",
      index: "인덱스",
      items: "내역",
      name: "이름",
      "no-result": "결과가 없습니다",
      status: "상태",
      subtotal: "소계",
      total: "총계",
    },
    "order-list-page": {
      description: "각 버튼은 선택한 날짜의 모든 주문내역을 가져옵니다.",
      title:
        "완료된 주문 및 취소된 주문을 찾을 수 있습니다. 날짜를 변경하여 더 이전의 주문을 가져올 수 있습니다.",
    },
    Orders: "주문",
    password: "비밀번호",
    "password-confirmation": "비밀번호 확인",
    "payment-method-table": {
      create: "생성",
      default: "기본 결제 수단",
      "default-input": {
        failed: "기본 결제 수단을 변경할 수 없습니다.",
        success: "기본 결제 수단이 변경되었습니다.",
      },
      delete: "삭제",
      "delete-confirm": "확인하시겠습니까?",
      name: "이름",
      "name-input": {
        failed: "이름이 유효하지 않습니다.",
        success: "이름이 성공적으로 변경되었습니다.",
      },
    },
    plan: {
      dashboard: "대시보드로 더 나은 인사이트를 얻으세요",
      "digital-menu": "디지털 메뉴용 QR 코드 받기",
      free: "모든 무료 플랜 기능이 포함됩니다",
      ledgers: "지출 및 수입 관리",
      orders: "주문 생성 및 인쇄",
      products: "상품 생성 및 이미지 업로드",
      support: "초기 설정 및 추가 채팅 지원",
      tables: "테이블 관리",
      upgrade: "플랜 업그레이드",
      video: "상품용 비디오 및 이미지 업로드",
    },
    "Please wait for redirection": "리디렉션을 기다려주세요",
    "product-list": {
      column: {
        code: "코드",
        created: "생성일",
        delete: "삭제하기",
        description: "상품 설명",
        edit: "수정하기",
        images: "이미지",
        name: "이름",
        options: "상품 옵션",
        price: "가격",
        published: "공개됨",
        tag: "태그",
      },
      "image-form": {
        "maximum-image":
          "파일 크기는 최대 5MB입니다. 비디오가 없으면 이미지가 디지털 메뉴에 표시됩니다. 여러 이미지를 업로드한 경우 제품을 클릭하여 이미지를 볼 수 있습니다.",
        "maximum-videos":
          "파일 크기는 최대 2MB입니다. 비디오가 디지털 메뉴에 표시됩니다. MP4 형식이 권장됩니다.",
        "no-images-found": "업로드된 이미지가 없습니다",
        "no-videos-found": "업로드된 비디오가 없습니다",
        remove: "삭제하기",
        upgrade: {
          description: "구독 후 모든 기능을 활용 하세요",
          title: "구독하기",
        },
        "uploaded-images": "업로드된 이미지",
        "uploaded-videos": "업로드된 비디오",
      },
      "no-products-found": "상품이 없습니다",
      product: {
        created: "생성일",
        delete: "삭제하기",
        images: "이미지",
        options: "상품 옵션",
        upload: {
          failed: "이미지 업로드에 실패했습니다.",
          loading: "업로드 중...",
          success: "이미지가 업로드되었습니다.",
        },
      },
    },
    "product-option-group-form": {
      "empty-options": "옵션이 없습니다.",
      "update-failure": "상품 옵션 추가에 실패했습니다.",
      "update-success": "옵션이 추가되었습니다.",
    },
    "product-option-groups-form": {
      "create-group": "그룹 생성",
      description:
        "상품의 옵션을 추가할 수 있습니다. 예를 들어 햄버거는 코카 콜라와 감자 튀김을 옵션으로 가질 수 있습니다. 원하신다면 옵션을 필수적으로 선택하게 할 수 있으며 모든 옵션에는 추가 비용을 가질 수 있습니다.",
      "is-mandatory": "필수 입력 항목인가요?",
      "new-option": "새로운 옵션",
      "selection-limit": "사용자는 다음을 선택할 수 있습니다.",
      title: "상품 옵션",
    },
    "product-table": {
      code: "코드",
      delete: "삭제",
      delete: {
        button: "삭제",
        confirm: "상품을 삭제하시겠습니까?",
        failure: "삭제를 실패했습니다F",
        success: "상품이 삭제되었습니다",
        title: "{{count}}개 삭제",
      },
      description: {
        placeholder: "상품을 소개하세요",
        title: "설명",
      },
      images: "이미지",
      name: "이름",
      options: "옵션",
      price: "가격",
      published: "공개됨",
      tags: "태그",
      update: {
        failure: "상품 업데이트를 실패했습니다",
        success: "상품이 업데이트되었습니다",
      },
    },
    products: "상품",
    "products-page": {
      "5-new-product-button": "+5개 상품 추가하기",
      "5-new-product-button": "+5개 상품 추가하기",
      description:
        "새로운 상품을 만들고, 이름과 가격을 변경할 수 있습니다. 또한 상품을 숨길 수도 있습니다.",
      "new-product-button": "상품 추가하기",
      steps: {
        1: "새 상품을 만들려면 이 버튼을 누르세요. 상품 이름이나 가격을 입력할 필요가 없습니다.",
        2: "상품 이름을 직접 편집할 수 있습니다. 텍스트를 클릭하면 이름을 수정할 수 있습니다.",
        3: "(선택 사항) 상품 코드는 상품의 고유 식별자입니다. 고객에게는 표시되지 않습니다. (예시) PD-12",
        4: "상품 설명은 디지털 메뉴를 통해 고객에게 표시됩니다. 상품을 설명하고 고객에게 더 많은 콘텐츠와 가치를 제공하세요.",
        5: "상품 가격은 소수점 이하 두 자리까지 가질 수 있습니다.",
        6: "디지털 메뉴에서 상품을 공개 또는 숨길 수 있습니다. 텍스트를 클릭하여 발행 또는 숨기기를 선택하세요. 이렇게 하면 소비자 측 애플리케이션에서만 가시성이 변경됩니다. 필요하다면 숨겨진 상품을 주문에 추가할 수도 있습니다.",
        7: "상품에 태그를 추가하세요. (예시) 저녁 식사, 음료, 커피 등. 저희 디지털 메뉴는 태그에 따라 구성됩니다. 이 데이터는 분석에도 사용될 수 있습니다.",
        8: "상품 옵션을 만들 수 있습니다. 이 기능의 한 가지 사용 사례는 햄버거입니다. 이곳에서 변형을 만들고 조직할 수 있습니다.",
        9: "상품 이미지와 비디오를 여기에 추가하세요. 최대 크기는 5MB입니다. 이미지와 비디오는 가능한 작게 유지하세요. 이 사이트를 시도해 보세요! https://www.imgonline.com.ua/eng/compress-image-size.php",
        10: "이 버튼을 클릭하여 상품을 제거할 수 있습니다.",
      },
      title: "상품 관리를 할 수 있습니다.",
    },
    "profile-form": {
      description: {
        description: "디지털 메뉴 랜딩 페이지에서 설명이 표시됩니다.",
        long: "설명이 너무 깁니다.",
        title: "매장 설명",
      },
      failure: "정보 업데이트에 실패했습니다.",
      instagram: "인스타그램",
      instagram: {
        title: "인스타그램",
      },
      "instagram-long": "인스타그램 URL이 너무 깁니다.",
      locale: {
        title: "언어",
      },
      name: {
        description:
          "매장 이름을 입력하세요. 이 값에 기반하여 디지털 메뉴 주소가 생성됩니다.",
        short: "이름이 너무 짧습니다.",
        title: "매장 이름",
      },
      Profile: "프로필",
      submit: "저장",
      success: "프로필이 업데이트되었습니다.",
    },
    "profile-page": {
      "daily-report": {
        "active-error": "일일 보고서 활성화를 실패했습니다.",
        "active-updated": "일일 보고서가 성공적으로 횔성화 되었습니다.",
        "close-time-error": "마감 시간 업데이트에 실패했습니다.",
        "close-time-label": "매장 마감 시간",
        "close-time-updated": "마감 시간이 업데이트되었습니다.",
        "duration-error": "기간 업데이트에 실패했습니다.",
        "duration-label-1": "총",
        "duration-label-2": "시간의 데이터를 수집합니다.",
        "duration-updated": "기간이 업데이트되었습니다.",
        info: "보고서는 {{sent_time}}에 전송됩니다. {{start_time}}부터 {{end_time}}까지의 데이터가 포함됩니다.",
        title: "일일 보고서 설정",
        trigger: {
          failure: "테스트 이메일 전송에 실패했습니다.",
          success: "테스트 이메일이 성공적으로 전송되었습니다.",
          title: "테스트",
        },
      },
      description: "비즈니스 프로필 및 응용 프로그램 설정을 업데이트하세요.",
      free: {
        description: "상품에 대한 비디오는 허용되지 않습니다.",
        title: "무료",
      },
      "payment-methods": {
        create: "생성",
        "default-name": "새로 만들기",
        description:
          "결제 수단을 추가하거나 제거할 수 있습니다. 새로 생성된 주문에는 기본 결제 수단이 할당됩니다.",
        title: "결제 수단",
      },
      pro: {
        description:
          "더 나은 사용자 경험을 위해 구독하세요. 매장에 적합한 최고의 디지털 메뉴를 만듭니다.",
        title: "프로",
      },
      title: "설정",
    },
    "re-send-confirmation-email": "다시 보내기",
    reactivate: "재활성화",
    "resend-confirmation-email-form": {
      failure:
        "이메일을 보낼 수 없습니다. 라이브 채팅이나 이메일로 지원팀에 문의해주세요.",
      success: "확인 이메일을 보냈습니다!",
    },
    "resend-confirmation-email-page": {
      title: "확인 이메일 재전송",
    },
    "reset-password": "비밀번호 재설정",
    "reset-password-confirmation-page": {
      title: "재지정을 위해 잠시만 기다려주세요",
    },
    "reset-password-form": {
      submit: "비밀번호 재설정",
    },
    "reset-password-page": {
      title: "비밀번호를 잊으셨나요?",
    },
    "review-list-page": {
      action: {
        featured: "추천 리뷰로 설정",
        hide: "숨기기",
        publish: "공개",
      },
      featured: {
        "cancel-button": "취소",
        count: "{{count}}개의 추천 리뷰",
        description:
          "추천 리뷰는 상단에 노출됩니다. 사용자들에게 내 가게의 리뷰를 알리고 더 많은 주문을 받으세요. (최대 3개까지 가능)",
        title: "추천 리뷰",
      },
      notification: {
        error: "오류가 발생했습니다.",
        featured: "리뷰가 추천 리뷰로 설정되었습니다.",
        hidden: "리뷰가 숨겨졌습니다.",
        published: "리뷰가 공개되었습니다.",
      },
      reviews: {
        description:
          "사용자 리뷰를 게시하거나 숨기세요. 숨겨진 리뷰는 사용자들에게 노출되지 않습니다. 검토되지 않은 리뷰는 첫번째 탭에서 모두 확인할 수 있습니다.",
        "no-hidden-reviews":
          "숨겨진 리뷰가 없습니다. 새 리뷰 탭을 확인해보세요. 😮",
        "no-new-reviews":
          "리뷰가 없습니다. 사용자가 리뷰를 제출하면 표시됩니다. 😀",
        "no-published-reviews":
          "공개된 리뷰가 없습니다. 새 리뷰 탭을 확인해보세요. 😮",
        title: "새로운 리뷰를 만나보세요",
      },
      tab: {
        hidden: "숨겨진 리뷰",
        new: "새 리뷰",
        published: "공개된 리뷰",
      },
    },
    reviews: "리뷰",
    "sales-description":
      "지정된 시간 동안 판매한 금액을 확인할 수 있습니다. 다른 날짜와 비교하여 변화가 있는지 확인해보세요.",
    "sales-title": "판매 기록 확인하기",
    saturday: "토요일",
    "Search by name": "이름으로 검색...",
    "select-dates": "날짜 선택",
    "Set new password": "새 비밀번호 설정",
    "Sign In to Motion Menu": "모션 메뉴에 로그인하기",
    "Sign out": "로그아웃",
    "Sign Up in Motion Menu": "모션 메뉴에 가입하기",
    "sign-in-form": {
      "sign-in-as-company": "매장 관리자",
      "sign-in-as-employee": "직원",
      submit: "로그인",
    },
    "sign-in-page": {
      description: "모션 메뉴는 고객과 연결되는 장소입니다!",
      releases: "다가오는 기능을 확인하세요!",
      title: "더 많은 콘텐츠, 더 많은 가치",
    },
    "sign-up": "가입하기",
    "sign-up-form": {
      "shop-name": "매장 이름",
      submit: "가입하기!",
      "your-shop-name": "매장 이름",
    },
    "sign-up-page": {
      "already-have-account": "이미 계정이 있으신가요?",
      description: "생동감 넘치는 음식 사진으로 사람들을 사로 잡으세요.",
      "sign-in": "로그인",
      title: "모션 메뉴에 가입하세요",
    },
    "Signed in successfully": "로그인 되었습니다",
    "Signed up successfully": "가입 되었습니다",
    "start-tour": " 투어 시작",
    Status: "상태",
    subscribe: "구독하기",
    Subtotal: "소계",
    sunday: "일요일",
    suspend: "중지",
    "Table is empty": "테이블이 비어 있습니다",
    "table-list": {
      "is-empty": {
        description: "새로운 주문을 만들려면 이 버튼을 누르세요.",
        title: "테이블이 비어있습니다.",
      },
      "new-order-button": "새로운 주문",
    },
    Tables: "테이블",
    "tables-page": {
      "add-table": "테이블 추가",
      description:
        "플러스 버튼을 눌러 새로운 층을 만들 수 있습니다. 현재 층에 새로운 테이블을 만들 수도 있습니다.",
      "start-moving-tables": "테이블 이동 시작",
      steps: {
        1: "새로운 테이블을 만들려면 버튼을 누르세요.",
        2: "이름을 직접 편집할 수 있습니다. 텍스트를 클릭하여 편집할 수 있습니다.",
        3: "메뉴를 표시하여 테이블을 삭제할 수 있습니다.",
        4: "고객이 요청한 제품을 추가할 수 있는 새로운 주문을 생성합니다.",
        5: "테이블을 필요한 위치에 놓으려면 여기를 클릭하고 다시 클릭하여 이동을 멈출 수 있습니다.",
        6: "필요한 경우 새로운 층 또는 섹터를 추가할 수 있습니다.",
        7: "층이나 섹터의 이름을 편집할 수 있습니다. 텍스트를 클릭하여 편집할 수 있습니다.",
        8: "필요한 경우 층 메뉴를 표시하여 제거할 수 있습니다.",
      },
      "stop-moving-tables": "테이블 이동 중지",
      title: "테이블과 층을 관리하세요",
    },
    tags: "태그",
    "This Month": "이번 달",
    "This Week": "이번 주",
    thursday: "목요일",
    "to sign in": "로그인하려면",
    Today: "오늘",
    Total: "합계",
    total: "합계",
    "trial-modal": {
      "attract-more-link": "알아보기",
      "attract-more-title":
        "고퀄리티의 사진으로 고객을 더 많이 불러오고 싶나요?",
      description: "시험 기간동안 모든 기능을 사용할 수 있습니다.",
      dismiss: "한동안 보지 않기",
      "get-access-link": "${{ price }}",
      "get-access-title": "구매하기",
      title: "14일 무료 시험기간에 선정 되었습니다!",
    },
    tuesday: "화요일",
    "unauthorized-page": {
      description: "관리자에게 접근 권한을 요청하세요.",
      title: "권한이 없습니다",
    },
    undo: "실행 취소",
    "update-password-form": {
      submit: "비밀번호 업데이트",
    },
    "update-password-page": {
      title: "새로운 비밀번호 설정",
    },
    validations: {
      email: {
        long: "이메일이 너무 깁니다",
        required: "이메일이 필요합니다",
        short: "이메일이 너무 짧습니다",
        valid: "이메일이 유효하지 않습니다",
      },
      name: {
        long: "이름이 너무 깁니다",
        required: "이름이 필요합니다",
        short: "이름이 너무 짧습니다",
      },
      password: {
        long: "비밀번호가 너무 깁니다",
        match: "비밀번호가 일치하지 않습니다",
        required: "비밀번호가 필요합니다",
        short: "비밀번호가 너무 짧습니다",
      },
    },
    "We have sent you a reset password email!":
      "비밀번호 재설정 이메일을 보냈습니다!",
    wednesday: "수요일",
    "Welcome to motion menu. Ultimate tool get more clients connected!":
      "모션 메뉴에 오신 것을 환영합니다. 좋은 퀄리티의 사진으로 이목을 끌어보세요!",
    Yesterday: "어제",
    "You have updated password successfully!":
      "비밀번호를 성공적으로 업데이트했습니다!",
  },
};
