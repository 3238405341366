import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { resetPasswordSchema } from "./validation";
import { IComponentProps, FormValues } from "./types";
import Component from "./component";
import { authClient } from "../../api";

const initialValues: FormValues = {
  email: "",
};

const ResetPasswordForm = withFormik<IComponentProps, FormValues>({
  mapPropsToValues: () => initialValues,
  validationSchema: resetPasswordSchema,
  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    const { email } = values;
    const { t } = props;

    try {
      await authClient.reset_password(email);
      setStatus(t("We have sent you a reset password email!"));
      enqueueSnackbar(t("We have sent you a reset password email!"), {
        variant: "success",
      });
    } catch (error) {
      setStatus(t("Failed to reset password"));
      enqueueSnackbar(t("Failed to reset password"), {
        variant: "error",
      });

      const message = error?.response.data.errors.join(", ");
      if (message.length > 0) {
        setStatus(message);
      }
    }

    setSubmitting(false);
  },
})(Component);

export default withRouter(withTranslation()(ResetPasswordForm));
