import { AxiosInstance } from "axios";

export interface IProductOption {
  id: number;
  name: string;
  price_f: number;
  product_option_group_id: number;
  updated_at: string;
  created_at: string;
}

export interface ICreateParams {
  name: string;
  price: number;
}

export type IUpdateParams = Partial<Partial<ICreateParams>>;

export interface IIndexResponse {
  data: IProductOption[];
}

class ProductOptionsClient {
  restClient!: AxiosInstance;

  constructor(restClient: AxiosInstance) {
    this.restClient = restClient;
  }

  // async index(): Promise<IIndexResponse> {
  //   const response = await this.restClient.get(
  //     `/api/v1/companies/product_options`
  //   );

  //   return response.data;
  // }

  async create(
    product_id: number,
    product_option_group_id: number,
    body: ICreateParams
  ): Promise<IProductOption> {
    const response = await this.restClient.post(
      `/api/v1/companies/products/${product_id}/product_option_groups/${product_option_group_id}/product_options`,
      {
        product_option: body,
      }
    );
    return response.data;
  }

  async update(id: number, body: IUpdateParams): Promise<IProductOption> {
    const response = await this.restClient.put(
      `/api/v1/companies/product_options/${id}`,
      { product_option: body }
    );

    return response.data;
  }

  async delete(id: number): Promise<void> {
    const response = await this.restClient.delete(
      `/api/v1/companies/product_options/${id}`
    );

    return response.data;
  }
}

export default ProductOptionsClient;
