import React, { useCallback } from "react";
import { IComponentProps } from "./types";
import { Title, Text, Link, TextContainer, Dismiss } from "./style";
import Modal from "..";

const TrialModal: React.FC<IComponentProps> = ({ t }) => {
  const [closeModalFromChildren, setCloseModalFromChildren] =
    React.useState(false);
  const PRICE = "12.99";
  const storage_key = "dismissedTrialModal";

  const checkIfTrialModalDismissed = () => {
    const dismissedTrialModal = localStorage.getItem(storage_key);
    if (dismissedTrialModal) {
      const dismissedTrialModalDate = new Date(dismissedTrialModal);
      const now = new Date();
      if (dismissedTrialModalDate > now) {
        return true;
      }
    }
    return false;
  };

  const setDismissedTrialModal = () => {
    const DAYS_TO_DISMISS = 7;
    const now = new Date();
    now.setDate(now.getDate() + DAYS_TO_DISMISS);
    localStorage.setItem(storage_key, now.toString());
  };

  const onDismissClick = useCallback(() => {
    setCloseModalFromChildren(true);
    setDismissedTrialModal(); //set local storage to not show modal again for seven days
  }, [setCloseModalFromChildren]);

  //if the user has dismissed the modal and it is still within the seven day period, don't show the modal
  if (checkIfTrialModalDismissed()) {
    return null;
  }

  return (
    <Modal closeModalFromChildren={closeModalFromChildren} clickOutsideDisabled>
      <TextContainer data-testid="trial-modal">
        <span style={{ fontSize: "3em" }}>🥳</span>
        <Title>{t("trial-modal.title")}</Title>
        <Text>{t("trial-modal.description")}</Text>
        <Text>
          {t("trial-modal.get-access-title")}
          <Link
            href="https://admin.motion.menu/billing"
            data-testid="price-link"
          >
            {t("trial-modal.get-access-link", { price: PRICE })}
          </Link>
        </Text>
        <Text>
          {t("trial-modal.attract-more-title")}
          <Link
            data-testid="contact-link"
            href="https://motion.menu/landing/restaurant-marketing"
          >
            {" "}
            {t("trial-modal.attract-more-link")}
          </Link>
        </Text>
        <Dismiss onClick={onDismissClick} data-testid="dismiss">
          {t("trial-modal.dismiss")}
        </Dismiss>
      </TextContainer>
    </Modal>
  );
};

export default TrialModal;
