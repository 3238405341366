import React from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  ColumnDef,
} from "@tanstack/react-table";
import { Table, Header, Resizer, Row, Styles, Cell } from "./style";
import { IComponentProps } from "./types";
import { enqueueSnackbar } from "notistack";
import Product from "../../stores/product";
import InputCell from "../editable-cells/input";
import BooleanCell from "../editable-cells/boolean";
import Button from "../../controls/button";
import useModal from "../../hooks/useModal";
import TextareaCell from "../editable-cells/textarea";
import TagCell from "../editable-cells/tag";
import ProductModal from "./modal";
import ImageOptions from "./modal/image_options";
import ProductOptions from "./modal/product_options";
import event from "../../event";
import TableCheckbox from "../table_checkbox";

const PRODUCT_UPDATE_EVENT_NAME = "admin.product-list-page.product.update";

const ProductTable = ({
  products,
  product_tags,
  reloadProductTags,
  t,
}: IComponentProps): JSX.Element => {
  const columns = React.useMemo<ColumnDef<Product, any>[]>(
    () => [
      {
        id: "select",
        size: 50,
        header: ({ table }) => (
          <TableCheckbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <div>
            <TableCheckbox
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          </div>
        ),
      },
      {
        accessorKey: "name",
        header: () => t("product-table.name"),
        cell: ({ row, getValue }) => (
          <InputCell
            type="text"
            value={getValue()}
            updateCallback={async (value) => {
              try {
                row.original.update({ name: value });
                event.track(PRODUCT_UPDATE_EVENT_NAME, { attribute: "name" });

                enqueueSnackbar(t("product-table.update.success"), {
                  variant: "success",
                });
              } catch (error) {
                enqueueSnackbar(t("product-table.update.failure"), {
                  variant: "error",
                });
              }
            }}
            test_id="product-name-input"
            max_length={100}
          />
        ),
      },
      {
        accessorKey: "price",
        header: () => t("product-table.price"),
        cell: ({ row, getValue }) => {
          return (
            <InputCell
              type="number"
              value={getValue()}
              updateCallback={async (value) => {
                try {
                  row.original.update({ price: value });
                  event.track(PRODUCT_UPDATE_EVENT_NAME, {
                    attribute: "price",
                  });

                  enqueueSnackbar(t("product-table.update.success"), {
                    variant: "success",
                  });
                } catch (error) {
                  enqueueSnackbar(t("product-table.update.failure"), {
                    variant: "error",
                  });
                }
              }}
            />
          );
        },
      },
      {
        accessorKey: "description",
        header: () => t("product-table.description.title"),
        cell: ({ row, getValue }) => {
          return (
            <TextareaCell
              value={getValue()}
              palceholder={t("product-table.description.placeholder")}
              max_length={500}
              updateCallback={async (value) => {
                try {
                  row.original.update({ description: value });
                  event.track(PRODUCT_UPDATE_EVENT_NAME, {
                    attribute: "description",
                  });

                  enqueueSnackbar(t("product-table.update.success"), {
                    variant: "success",
                  });
                } catch (error) {
                  enqueueSnackbar(t("product-table.update.failure"), {
                    variant: "error",
                  });
                }
              }}
            />
          );
        },
      },
      {
        accessorKey: "published",
        header: t("product-table.published"),
        cell: ({ row, getValue }) => {
          return (
            <BooleanCell
              value={getValue()}
              updateCallback={async (value) => {
                try {
                  row.original.update({ published: value });
                  event.track(PRODUCT_UPDATE_EVENT_NAME, {
                    attribute: "published",
                  });

                  enqueueSnackbar(t("product-table.update.success"), {
                    variant: "success",
                  });
                } catch (error) {
                  enqueueSnackbar(t("product-table.update.failure"), {
                    variant: "error",
                  });
                }
              }}
              test_id="product-published-button"
            />
          );
        },
      },
      {
        accessorKey: "tag_list",
        header: t("product-table.tags"),
        cell: ({ row, getValue }) => {
          return (
            <TagCell
              selected={getValue()}
              options={product_tags}
              updateCallback={async (value) => {
                try {
                  await row.original.update({ tag_list: value });
                  await reloadProductTags();
                  event.track(PRODUCT_UPDATE_EVENT_NAME, { attribute: "tags" });

                  enqueueSnackbar(t("product-table.update.success"), {
                    variant: "success",
                  });
                } catch (error) {
                  enqueueSnackbar(t("product-table.update.failure"), {
                    variant: "error",
                  });
                }
              }}
            />
          );
        },
      },
      {
        accessorKey: "code",
        header: t("product-table.code"),
        cell: ({ row, getValue }) => {
          return (
            <InputCell
              type="text"
              value={getValue() || ""} // Fix `value` prop on `input` should not be null.
              palceholder="ABC-123"
              max_length={20}
              updateCallback={async (value) => {
                try {
                  row.original.update({ code: value });
                  event.track(PRODUCT_UPDATE_EVENT_NAME, { attribute: "code" });

                  enqueueSnackbar(t("product-table.update.success"), {
                    variant: "success",
                  });
                } catch (error) {
                  enqueueSnackbar(t("product-table.update.failure"), {
                    variant: "error",
                  });
                }
              }}
              transformTextCallback={(value) => {
                return value.toUpperCase();
              }}
            />
          );
        },
      },
      {
        accessorKey: "images",
        header: t("product-table.images"),
        // button to open a modal to upload images
        cell: ({ row }) => {
          const [imageModalOpen, setImageModalOpen, containerRef] = useModal();

          return (
            <>
              <Button
                variant="borderless"
                test_id="images-modal-button"
                onClick={() => {
                  setImageModalOpen(true);
                }}
              >
                {t("product-table.images")}
              </Button>

              {imageModalOpen && (
                <ProductModal closeCallback={() => setImageModalOpen(false)}>
                  <div ref={containerRef}>
                    <ImageOptions product={row.original} />
                  </div>
                </ProductModal>
              )}
            </>
          );
        },
      },
      {
        accessorKey: "product_option_groups",
        header: t("product-table.options"),
        cell: ({ row }) => {
          const [productModalOpen, setProductModalOpen, containerRef] =
            useModal();

          return (
            <>
              <Button
                variant="borderless"
                test_id="product-modal-button"
                onClick={() => {
                  setProductModalOpen(true);
                }}
              >
                {t("product-table.options")}
              </Button>

              {productModalOpen && (
                <ProductModal closeCallback={() => setProductModalOpen(false)}>
                  <div ref={containerRef}>
                    <ProductOptions product={row.original} />
                  </div>
                </ProductModal>
              )}
            </>
          );
        },
      },
    ],
    [product_tags],
  );

  // Product rows can be selected for bulk deletion
  const [rowSelection, setRowSelection] = React.useState({});

  // Use the state and functions returned from useTable to build your UI
  const table = useReactTable({
    columns,
    data: products,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    state: {
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
  });

  const { getCenterTotalSize, getHeaderGroups, getRowModel } = table;

  const deleteSelectedRows = React.useCallback(() => {
    const list = Object.keys(rowSelection).map((index) => {
      const row = table.getRowModel().rows.at(Number(index));
      return row?.original;
    });

    // TODO list all items
    if (confirm(t("product-table.delete.confirm")) == true) {
      try {
        list.forEach((product) => {
          product?.delete();
        });

        // Reset selected rows
        setRowSelection({});

        enqueueSnackbar(t("product-table.delete.success"), {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(t("product-table.delete.failure"), {
          variant: "error",
        });
      }
    }
  }, [rowSelection]);

  // Render the UI for your table
  return (
    <Styles>
      <Table
        style={{
          width: getCenterTotalSize(),
        }}
      >
        <thead>
          {getHeaderGroups().map((headerGroup) => (
            <Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Header
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: header.getSize(),
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}

                  <Resizer
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    isResizing={header.column.getIsResizing()}
                  />
                </Header>
              ))}
            </Row>
          ))}
        </thead>

        <tbody>
          {getRowModel().rows.map((row) => {
            return (
              <Row key={row.id} data-testid="product-row">
                {row.getVisibleCells().map((cell) => (
                  <Cell
                    key={cell.id}
                    style={{
                      width: cell.column.getSize(),
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Cell>
                ))}
              </Row>
            );
          })}
        </tbody>
      </Table>

      <br />
      <Button
        variant="red"
        test_id="product-delete-button"
        onClick={deleteSelectedRows}
        disabled={Object.keys(rowSelection).length == 0}
      >
        {t("product-table.delete.title", {
          count: Object.keys(rowSelection).length,
        })}
      </Button>
    </Styles>
  );
};

export default ProductTable;
