import { useEffect } from "react";
import { useStores } from "../contexts/store_context";
import LoadingPage from "../pages/loading_page";
import { TStore } from "../stores";

const withStoresLoading =
  <P extends Record<string, unknown>>(
    Component: React.ComponentType<P>,
    store_names: string[]
  ) =>
  (props: P) => {
    const stores = useStores();

    useEffect(() => {
      store_names.forEach((store_name) => {
        stores?.[store_name as keyof TStore].load();
      });
    }, [stores]);

    const is_loading: boolean = store_names.some(
      (store_name) => stores?.[store_name as keyof TStore].is_loading
    );

    if (is_loading) {
      return <LoadingPage />;
    }

    return <Component {...props} />;
  };

export default withStoresLoading;
