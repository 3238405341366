import React from "react";
import TableObject from "./table";
import { EmptyTables, List, Input } from "./style";
import { IComponentProps } from "./types";

const TablesListComponent: React.FunctionComponent<IComponentProps> = ({
  tables,
  scrollAreaRef,
  createTableRefCallback,
  onSarchInputChangeCallback,
  selectedTableId,
  t,
}: IComponentProps) => {
  const onSarchInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSarchInputChangeCallback(event.target.value);
    },
    []
  );

  return (
    <List>
      <Input
        type="search"
        placeholder={t("Search by name")}
        onChange={onSarchInputChange}
      />

      {tables.length < 1 && <EmptyTables>{t("empty-tables")}</EmptyTables>}

      <div
        ref={scrollAreaRef}
        style={{
          overflowY: "scroll",
          height: "100%",
          maxHeight: "800px",
          marginTop: "5px",
        }}
      >
        {tables.map((table) => (
          <TableObject
            key={table.key}
            table={table}
            referenceCallback={createTableRefCallback(table.id)}
            selected={selectedTableId == table.id}
          />
        ))}
      </div>
    </List>
  );
};

export default TablesListComponent;
