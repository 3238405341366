import { makeAutoObservable } from "mobx";
import {
  IFiltersParams,
  ICreateParams,
  createQueryString,
} from "../api/ledgers_client";
import Ledger from "./ledger";
import LedgersStore from "./ledgers_store";
import { IPages } from "./types";

export function createLedgersPageKey(
  page: number,
  per_page: number,
  filters: IFiltersParams
): string {
  return createQueryString(page, per_page, filters);
}

class LedgerListPageStore {
  ledgersStore!: LedgersStore;

  current_page = 1;

  per_page = 15;

  last_page = 1;

  filters: IFiltersParams = {};

  pages: IPages = {};

  is_loading = false;

  constructor() {
    makeAutoObservable(this);

    this.ledgersStore = new LedgersStore();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  *load() {
    const response = yield this.ledgersStore.load(
      this.current_page,
      this.per_page,
      this.filters
    );

    this.pages[
      createLedgersPageKey(this.current_page, this.per_page, this.filters)
    ] = response.ids;

    this.last_page = response.meta.last;
  }

  *create(params: ICreateParams) {
    try {
      const ledger = yield this.ledgersStore.create(params);
      this.load();
      return ledger;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  setCurrentPage(page: number): void {
    this.current_page = page;
    this.load();
  }

  setPerPage(per_page: number): void {
    this.per_page = per_page;
    this.load();
  }

  setFilters(filters: IFiltersParams): void {
    this.filters = filters;
    this.load();
  }

  get current_page_ledgers(): Ledger[] {
    const ids =
      this.pages[
        createLedgersPageKey(this.current_page, this.per_page, this.filters)
      ];

    if (ids === undefined) {
      return [];
    }

    return ids.reduce((array: Ledger[], id) => {
      const ledger = this.ledgersStore.ledgers.get(String(id));
      if (ledger !== undefined) {
        array.push(ledger);
      }
      return array;
    }, []);
  }
}

export default LedgerListPageStore;
