import React from "react";
import { useDropzone } from "react-dropzone";
import {
  Dropzone,
  Images,
  Image,
  ImageContainer,
  Videos,
  // Video,
  Subscribe,
  VideoContainer,
  RemoveButton,
  Subtitle,
  Description,
  Box,
  Gallery,
  Container,
  ProductName,
} from "./style";
import { IComponentProps } from "./types";

const MAX_IMAGE_SIZE = 5242880;

const ImageForm: React.FC<IComponentProps> = ({
  company,
  product_name,
  videos,
  images,
  onDrop,
  onImageRemove,
  onVideoRemove,
  t,
}: IComponentProps) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    accept: ["image/jpg", "image/jpeg", "image/png", "video/mp4"],
    onDropAccepted: onDrop,
    maxSize: MAX_IMAGE_SIZE,
    maxFiles: 1,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <Container
      initial={{ y: -3, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: "easeIn", duration: 0.3 }}
      data-testid="image-form"
    >
      <Dropzone {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} data-testid="dropzone-input" />
        {!isDragActive && t("dropzone content")}
        {isDragActive && !isDragReject && t("Drop it here!")}
        {isDragReject && t("File type not accepted, sorry!")}
      </Dropzone>

      <Gallery>
        <>{fileRejectionItems}</>

        <ProductName>{product_name}</ProductName>
        <Box>
          <Subtitle>{t("product-list.image-form.uploaded-videos")}</Subtitle>
          <Description>
            {t("product-list.image-form.maximum-videos")}
          </Description>
          <Videos>
            {videos.length === 0 &&
              t("product-list.image-form.no-videos-found")}
            {videos.map((video) => {
              return (
                <VideoContainer key={video.public_id}>
                  <a
                    href={`https://res.cloudinary.com/dnaexfddx/video/upload/w_150,h_150,dpr_2.0,c_fill/${video.public_id}.mp4`}
                  >
                    <Image
                      width={150}
                      height={150}
                      src={`https://res.cloudinary.com/dnaexfddx/video/upload/${video.public_id}.jpg`}
                      alt="no alt"
                    />
                  </a>

                  <RemoveButton
                    onClick={() => onVideoRemove(video.id)}
                    type="button"
                    data-testid="remove-video-button"
                  >
                    {t("product-list.image-form.remove")}
                  </RemoveButton>
                </VideoContainer>
              );
            })}
          </Videos>
        </Box>
        <hr />

        <Box>
          <Subtitle>{t("product-list.image-form.uploaded-images")}</Subtitle>
          <Description>
            {t("product-list.image-form.maximum-image")}
          </Description>
          <Images>
            {images.length === 0 &&
              t("product-list.image-form.no-images-found")}

            {images.map((image) => {
              return (
                <ImageContainer key={image.public_id}>
                  <Image
                    width={150}
                    height={150}
                    src={`https://res.cloudinary.com/dnaexfddx/image/upload/f_auto,q_auto,w_150,h_150,dpr_2.0,c_fill/v1616709574/${image.public_id}.jpg`}
                    alt="no alt"
                    data-testid="image"
                  />
                  <RemoveButton
                    onClick={() => onImageRemove(image.id)}
                    type="button"
                    data-testid="remove-image-button"
                  >
                    {t("product-list.image-form.remove")}
                  </RemoveButton>
                </ImageContainer>
              );
            })}
          </Images>
        </Box>
      </Gallery>
    </Container>
  );
};

export default ImageForm;
