import { makeAutoObservable } from "mobx";
import {
  IOrder,
  ICreateParams as ICreateOrderParams,
  IFiltersParams,
  IIndexResponse,
} from "../api/orders_client";
import Order from "./order";
import { ordersClient } from "../api";

class OrdersStore {
  orders: Map<string, Order> = new Map();

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  *load(
    page: number,
    per_page: number,
    filters: IFiltersParams
  ): Generator<Promise<IIndexResponse>, IIndexResponse, IIndexResponse> {
    try {
      this.isLoading = true;

      const response = yield ordersClient.index(page, per_page, filters);

      // eslint-disable-next-line
      const ids = response.ids;

      ids.forEach((id: string) => {
        this.refreshOrderFromServer(id, response.data[id]);
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  *loadAllAlive() {
    this.isLoading = true;
    let page = 1;
    let nextPage: null | number = null;

    do {
      try {
        const response = yield this.load(page, 15, {
          state_in: ["created", "checked"],
        });
        nextPage = response.meta.next;
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        page += 1;
      }
    } while (nextPage);

    this.isLoading = false;
  }

  refreshOrderFromServer(key: string, json: IOrder): void {
    const foundOrder = this.orders.get(key);

    if (!foundOrder) {
      const order = new Order(this, json);
      this.orders.set(key, order);
    } else {
      foundOrder.refresh(json);
    }
  }

  *create(
    params: ICreateOrderParams
  ): Generator<Promise<IOrder>, IOrder, IOrder> {
    try {
      const json = yield ordersClient.create(params);
      const order = new Order(this, json);
      this.orders.set(String(order.id), order);
      return order;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  // *refresh(id: number): Generator<Promise<IOrder>, void, IOrder> {
  //   try {
  //     this.isLoading = true;
  //     const response = yield this.ordersClient.show(id);
  //     this.refreshOrderFromServer(String(id), response);
  //   } catch (error) {
  //     console.error(error);
  //     throw error;
  //   } finally {
  //     this.isLoading = false;
  //   }
  // }
}

export default OrdersStore;
