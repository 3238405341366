import React from "react";
import { Card, Title, Number, Icon } from "./style";
import { IComponentProps } from "./types";
import { dashboardClient } from "../../../api";

const Cost: React.FC<IComponentProps> = ({ range, t }) => {
  const [data, setData] = React.useState(0);

  React.useEffect(() => {
    async function loadData() {
      const response = await dashboardClient.cost(
        range.from.toISOString(),
        range.to.toISOString()
      );
      setData(response.data);
    }

    loadData();
  }, [range]);

  return (
    <Card>
      <Title>
        <Icon size={25} /> {t("Cost")}
      </Title>
      <Number>$ {data}</Number>
    </Card>
  );
};

export default Cost;
