import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  Container,
  LeftContainer,
  Header,
  Description,
  RightContainer,
  Divider,
} from "./style";
import SignUpForm from "../../components/sign_up_form";
import { IComponentProps } from "./types";
import Logo from "../../controls/logo";
import GoogleSignInButton from "../../components/google_sign_in_button";

const SignUpPage: React.FC<IComponentProps> = ({ t }) => {
  return (
    <Container>
      <LeftContainer>
        <Logo />

        <Header>{t("sign-up-page.title")}</Header>
        <Description>{t("sign-up-page.description")}</Description>
        <Description>
          {t("sign-up-page.already-have-account")}{" "}
          <ReactRouterLink to="/sign_in">
            {t("sign-up-page.sign-in")}
          </ReactRouterLink>{" "}
        </Description>
        <Description>
          {t("forgot-password")}{" "}
          <ReactRouterLink to="/reset_password">
            {t("reset-password")}
          </ReactRouterLink>
        </Description>
      </LeftContainer>

      <RightContainer>
        <GoogleSignInButton />

        <Divider />
        <SignUpForm />
      </RightContainer>
    </Container>
  );
};

export default SignUpPage;
