import styled from "styled-components";
import { Link as LinkButton } from "react-router-dom";
import { motion } from "framer-motion";
import { down } from "styled-breakpoints";

export const Container = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  ${down("md")} {
    padding: 0 10px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 0;
  font-size: 60px;
  text-transform: capitalize;

  ${down("md")} {
    font-size: 30px;
  }
`;

export const Description = styled.p``;

export const Vertical = styled.div`
  max-width: 600px;
`;

export const Horizontal = styled.div``;

export const Background = styled(motion.div)`
  position: absolute;
  background-color: #bde0fe;
  border-radius: 8px;
  width: 600px;
  height: 600px;
  z-index: -1;

  ${down("md")} {
    display: none;
  }
`;

export const Link = styled(LinkButton)``;

export const Preview = styled.iframe`
  width: 360px;
  height: 640px;
  border: none;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  margin-top: 1em;

  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);

  ${down("md")} {
    display: none;
  }
`;

export const QRBox = styled.div`
  width: 120px;
  height: 120px;

  border: none;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  :hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
`;

export const Label = styled.label`
  font-weight: bold;
`;

export const Input = styled.div`
  background-color: ${(props) => props.theme.colors.gray};
  max-width: 400px;
  padding: 10px 20px;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const MenuLink = styled.a``;
