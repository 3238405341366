import React from "react";
import { withFormik } from "formik";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { ledgerSchema } from "./validation";
import { IComponentProps, FormValues } from "./type";
import Component from "./component";
import { useStores } from "../../contexts/store_context";

const withLedgersStore = (InnerComponent: any) => () => {
  const stores = useStores();

  const createLedger = React.useCallback(
    (params: any) => {
      stores?.ledgerListPageStore.create(params);
    },
    [stores?.ledgerListPageStore]
  );

  return <InnerComponent createLedger={createLedger} />;
};

const initialValues: FormValues = {
  description: "",
  category: "income",
  tag_list: [],
  billed_at: moment().format("YYYY-MM-DDTHH:MM"),
  total: 0,
};

const LedgerForm = withFormik<IComponentProps, FormValues>({
  mapPropsToValues: () => initialValues,
  validationSchema: ledgerSchema,
  handleSubmit: async (
    values,
    { props, setSubmitting, setStatus, resetForm }
  ) => {
    const { category, description, tag_list, total, billed_at } = values;
    const { t, createLedger } = props;

    try {
      await createLedger({
        category,
        description,
        total,
        tag_list: tag_list.join(", "),
        billed_at,
      });
      setStatus(t("ledger-form.created"));
      enqueueSnackbar(t("ledger-form.created"), {
        variant: "success",
      });
      resetForm();
    } catch (error) {
      setStatus(t("ledger-form.failed"));
      enqueueSnackbar(t("ledger-form.failed"), { variant: "error" });

      const message = error?.response.data.errors.join(", ");
      if (message.length > 0) {
        setStatus(message);
      }
    }

    setSubmitting(false);
  },
})(Component);

export default withLedgersStore(withTranslation()(LedgerForm));
