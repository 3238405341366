import React from "react";
import { Card, Title, Number, Icon } from "./style";
import { IComponentProps } from "./types";
import { dashboardClient } from "../../../api";

const Visitors: React.FC<IComponentProps> = ({ range, t }) => {
  const [data, setData] = React.useState(0);

  React.useEffect(() => {
    async function loadData() {
      const response = await dashboardClient.visitors(
        range.from.toISOString(),
        range.to.toISOString(),
        range.groupBy
      );
      setData(Object.values(response.data).reduce((a, b) => a + b));
    }

    loadData();
  }, [range]);

  return (
    <Card>
      <Title>
        <Icon size={25} /> {t("Visitors")}
      </Title>
      <Number>{data}</Number>
    </Card>
  );
};

export default Visitors;
