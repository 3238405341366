import styled from "styled-components";
import { WithTranslation, withTranslation } from "react-i18next";

const Container = styled.footer`
  display: flex;
  justify-content: space-around;
  padding-top: 3rem;
  padding-bottom: 0.4rem;
`;

const Text = styled.p`
  font-size: 0.8rem;
`;

const Footer = () => {
  return (
    <Container>
      <Text>Copyright © 2023 Motion Menu. All rights reserved.</Text>
    </Container>
  );
};

export default withTranslation()(Footer);
