import React from "react";
import { Card, Title, Number, Icon } from "./style";
import { IComponentProps } from "./types";
import { dashboardClient } from "../../../api";
import { LineChart, Line } from "recharts";

const chart = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const Total: React.FC<IComponentProps> = ({ range, t }) => {
  const [data, setData] = React.useState(0);

  React.useEffect(() => {
    async function loadData() {
      const response = await dashboardClient.total(
        range.from.toISOString(),
        range.to.toISOString()
      );
      setData(response.data);
    }

    loadData();
  }, [range]);

  return (
    <Card>
      <Title>
        <Icon size={25} /> {t("Total")}
      </Title>
      <Number>$ {data}</Number>

      <LineChart width={300} height={100} data={chart}>
        <Line type="monotone" dataKey="pv" stroke="#fff" strokeWidth={2} />
      </LineChart>
    </Card>
  );
};

export default Total;
