import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { useStores } from "../../../../contexts/store_context";
import LoadingSpinner from "../../../../controls/loading_spinner";
import Component from "./component";
import { IComponentProps } from "./types";

const withProfile =
  <P extends IComponentProps>(
    Component: React.ComponentType<P>
  ): React.FC<Omit<P, "company">> =>
  (props) => {
    const stores = useStores();

    if (!stores?.productListPageStore.company) {
      return <LoadingSpinner id="loading-spinner" />;
    }

    return (
      <Component
        {...(props as P)}
        company={stores.productListPageStore.company}
      />
    );
  };

export default withTranslation()(withProfile(observer(Component)));
