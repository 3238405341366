import React from "react";
import OrderRow from "./order_row";
import { Container, Header, Column, List, EmptyOrders } from "./style";
import { IComponentProps } from "./types";

const OrderList: React.FunctionComponent<IComponentProps> = ({
  orders,
  t,
}: IComponentProps) => {
  return (
    <Container>
      <Header>
        <Column>{t("order-list.index")}</Column>
        <Column>{t("order-list.name")}</Column>
        <Column>{t("order-list.items")}</Column>
        <Column>{t("order-list.dicsount")}</Column>
        <Column>{t("order-list.subtotal")}</Column>
        <Column>{t("order-list.total")}</Column>
        <Column>{t("order-list.status")}</Column>
        <Column>{t("order-list.created")}</Column>
      </Header>

      {orders.length < 1 && (
        <EmptyOrders>{t("order-list.no-result")}</EmptyOrders>
      )}

      <List>
        {orders.map((order, index) => (
          <OrderRow key={order.key} index={index + 1} order={order} />
        ))}
      </List>
    </Container>
  );
};

export default OrderList;
