import { AxiosInstance } from "axios";
import { ICompany } from "./profile_client";

export type IUpdateResponse = ICompany;

export interface IUpdateSubscriptionParams {
  subscription_plan_id: string;
  subscription_id: string;
  subscription_status: string;
}

class SubscriptionClient {
  restClient!: AxiosInstance;

  constructor(restClient: AxiosInstance) {
    this.restClient = restClient;
  }

  async update(params: IUpdateSubscriptionParams): Promise<IUpdateResponse> {
    const response = await this.restClient.put(
      "/api/v1/companies/subscription",
      {
        company: params,
      }
    );

    return response.data;
  }

  async suspend(): Promise<IUpdateResponse> {
    const response = await this.restClient.put(
      "/api/v1/companies/subscription/suspend"
    );

    return response.data;
  }

  async activate(): Promise<IUpdateResponse> {
    const response = await this.restClient.put(
      "/api/v1/companies/subscription/activate"
    );

    return response.data;
  }
}

export default SubscriptionClient;
