import moment from "moment";
import Order from "../stores/order";
import Handlebars from "handlebars/dist/cjs/handlebars";
import { print as printTemplate } from "../templates/print";
import { printCodes as printCodesTemplate } from "../templates/printCodes";
import Company from "../stores/company";

export const print = (company: Company | undefined, order: Order): void => {
  const win = window.open("", "", "width=500,height=500");
  if (win) {
    const template = Handlebars.compile(printTemplate);
    const context = {
      company: company,
      order: {
        ...order,
        created_at: moment(order.created_at).format("YYYY-MM-DD HH:mm"),
        subtotal: order.subtotal,
        discounted_total: order.discounted_total.toFixed(2),
        total: order.total,
      },
    };
    const html = template(context);

    win.document.write(html);
    win.print();
    win.close();
  }
};

export const printCodes = (order: Order): void => {
  const win = window.open("", "", "width=500,height=500");

  if (win) {
    const template = Handlebars.compile(printCodesTemplate);
    const context = {
      order: { ...order, created_at: moment(order.created_at).format("HH:mm") },
    };
    const html = template(context);

    win.document.write(html);
    win.print();
    win.close();
  }
};
