import styled from "styled-components";
import { PointOfSale } from "@styled-icons/material/PointOfSale";

export const Icon = styled(PointOfSale)`
  margin-right: 10px;
`;

export const Number = styled.h1``;

export const Title = styled.p`
  font-size: 20px;

  display: flex;
  align-items: center;
`;

export const Card = styled.div`
  min-width: 300px;
  background-color: ${(props) => props.theme.colors.red};
  color: #fff;
  border-radius: 8px;
  padding: 10px 30px;
`;
