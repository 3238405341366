import styled from "styled-components";
import { Link as LinkButton } from "react-router-dom";
import { motion } from "framer-motion";

export const Container = styled.div`
  position: absolute;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Card = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  border: none;
  border-radius: 8px;
  padding: 30px 43px;
  margin-bottom: 15px;
  max-width: 420px;
  background-color: #fff;

  box-shadow: 0 2.3px 1.9px rgba(0, 0, 0, 0.008),
    0 5.4px 4.3px rgba(0, 0, 0, 0.011), 0 9.7px 7.7px rgba(0, 0, 0, 0.014),
    0 16.1px 12.8px rgba(0, 0, 0, 0.016), 0 26.5px 21.2px rgba(0, 0, 0, 0.019),
    0 46.2px 37px rgba(0, 0, 0, 0.022), 0 100px 80px rgba(0, 0, 0, 0.03);
`;

export const Title = styled.h1`
  margin-bottom: 0;
  color: ${(props) => props.theme.colors.blueDarker};
`;

export const Price = styled.h2`
  font-weight: normal;

  span {
    font-size: 15px;
  }
`;

export const Button = styled(LinkButton)`
  margin-top: 30px;
  background-color: ${(props) => props.theme.colors.blue};
  outline: none;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  border: none;
  padding: 10px 16px;
  cursor: pointer;
  text-decoration: none;

  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.blueDarker};
  }
`;

export const Item = styled.li``;
