/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeAutoObservable } from "mobx";
import {
  IPaymentMethod,
  ICreatePaymentMethodParams,
  IUpdatePaymentMethodParams,
} from "../api/payment_methods_client";
import { paymentMethodsClient } from "../api";
import PaymentMethod from "./payment_method";

class PaymentMethodsStore {
  payment_methods: Map<string, PaymentMethod> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  *load() {
    const response = yield paymentMethodsClient.index();

    const ids = response.ids;

    ids.forEach((id: string) => {
      this.refreshFromServer(id, response.data[id]);
    });

    return response;
  }

  refreshFromServer(key: string, json: IPaymentMethod): void {
    const found = this.payment_methods.get(key);

    if (!found) {
      const new_payment_method = new PaymentMethod(this, json);
      this.payment_methods.set(key, new_payment_method);
    } else {
      // Delete and recreate object to rerender TanStack table
      this.payment_methods.delete(key);
      const new_payment_method = new PaymentMethod(this, json);
      this.payment_methods.set(key, new_payment_method);
    }
  }

  *create(params: ICreatePaymentMethodParams) {
    const json = yield paymentMethodsClient.create(params);
    const payment_method = new PaymentMethod(this, json);
    this.payment_methods.set(String(payment_method.id), payment_method);
  }

  *delete(id: number) {
    yield paymentMethodsClient.delete(id);
    this.payment_methods.delete(String(id));
  }
}

export default PaymentMethodsStore;
