import TablesPageStore from "./tables_page_store";
import ProductListPageStore from "./product_list_page_store";
import ReviewListPageStore from "./review_list_page_store";
import OrderListPageStore from "./order_list_page_store";
import LedgerListPageStore from "./ledger_list_page_store";
import ProfilePageStore from "./profile_page_store";
import BillingPageStore from "./billing_page_store";
import DigitalMenuPageStore from "./digital_menu_page_store";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function createStores() {
  // note the use of this which refers to observable instance of the store
  return {
    tablePageStore: new TablesPageStore(),
    productListPageStore: new ProductListPageStore(),
    reviewListPageStore: new ReviewListPageStore(),
    orderListPageStore: new OrderListPageStore(),
    ledgerListPageStore: new LedgerListPageStore(),
    profilePageStore: new ProfilePageStore(),
    billingPageStore: new BillingPageStore(),
    digitalMenuPageStore: new DigitalMenuPageStore(),
  };
}

export type TStore = ReturnType<typeof createStores>;

export default createStores;
