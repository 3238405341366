import styled, { css } from "styled-components";

export const Container = styled.div<{ warn: boolean }>`
  ${(props) =>
    props.warn &&
    css`
      background-color: ${props.theme.colors.red};
    `}
`;

export const Name = styled.label``;

export const Price = styled.span`
  font-size: 11px;
  margin-left: 3px;

  color: ${(props) => props.theme.colors.redDarker};
`;
