import axios from "axios";
import Cookies from "../lib/cookies";
import OrdersClient from "./orders_client";
import TablesClient from "./tables_client";
import FloorsClient from "./floors_client";
import ProductsClient from "./products_client";
import ReviewsClient from "./reviews_client";
import ProductOptionGroupsClient from "./product_option_groups_client";
import ProductOptionsClient from "./product_options_client";
import AuthClient from "./auth_client";
import ProfileClient from "./profile_client";
import SubscriptionClient from "./subscription_client";
import ItemsClient from "./items_client";
import DashboardClient from "./dashboard_client";
import LedgersClient from "./ledgers_client";
import AccessesClient from "./accesses_client";
import PaymentMethodsClient from "./payment_methods_client";
import DailyReportSettingsClient from "./daily_report_settings_client";
import PlanPurchasesClient from "./plan_purchases_client";
import OauthClient from "./oauth_client";

const BASE_URL =
  process.env.REACT_APP_MOTION_MENU_API_ENDPOINT || "http://localhost:3000";

export const restClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Response interceptor for API calls
restClient.interceptors.request.use(
  function interceptSuccessRequest(request) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    const accessToken = Cookies.get("access-token");
    const client = Cookies.get("client");
    const expiry = Cookies.get("expiry");
    const uid = Cookies.get("uid");

    // Set authorization header for each reseponses
    // eslint-disable-next-line @typescript-eslint/dot-notation
    request.headers.common["access-token"] = accessToken;
    request.headers.common["token-type"] = "Bearer";
    request.headers.common.client = client;
    request.headers.common.expiry = expiry;
    request.headers.common.uid = uid;

    return request;
  },
  function interceptFailureRequest(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    return Promise.reject(error);
  },
);

// Response interceptor for API calls
restClient.interceptors.response.use(
  function interceptSuccessResponse(response) {
    return response;
  },
  function interceptFailureResponse(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // throw error;
    return Promise.reject(error);
  },
);

export const accessesClient = new AccessesClient(restClient);

export const ledgersClient = new LedgersClient(restClient);

export const itemsClient = new ItemsClient(restClient);

export const ordersClient = new OrdersClient(restClient);

export const paymentMethodsClient = new PaymentMethodsClient(restClient);

export const floorsClient = new FloorsClient(restClient);

export const tablesClient = new TablesClient(restClient);

export const productsClient = new ProductsClient(restClient);

export const reviewsClient = new ReviewsClient(restClient);

export const productOptionGroupsClient = new ProductOptionGroupsClient(
  restClient,
);

export const productOptionsClient = new ProductOptionsClient(restClient);

export const dashboardClient = new DashboardClient(restClient);

export const profileClient = new ProfileClient(restClient);

export const dailyReportSettingsClient = new DailyReportSettingsClient(
  restClient,
);

export const subscriptionClient = new SubscriptionClient(restClient);

export const authClient = new AuthClient(restClient);

export const planPurchasesClient = new PlanPurchasesClient(restClient);

export const oauthClient = new OauthClient(restClient);
