import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { signInSchema } from "./validation";
import { IComponentProps, FormValues } from "./type";
import Component from "./component";
import { authClient } from "../../api";
import event from "../../event";

const initialValues: FormValues = {
  email: "",
  password: "",
  is_employee: "false",
};

const SignInForm = withFormik<IComponentProps, FormValues>({
  mapPropsToValues: () => initialValues,
  validationSchema: signInSchema,
  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    const { email, password, is_employee } = values;
    const { t, history } = props;

    event.track("sign-in-form.submit");

    try {
      await authClient.sign_in(email, password, is_employee == "true");

      setStatus(t("Signed in successfully"));
      enqueueSnackbar(t("Signed in successfully"), {
        variant: "success",
      });
      event.identify(email);
      event.track("sign-in-form.success");
      history.push("/products");
    } catch (error) {
      event.track("sign-in-form.failure");
      setStatus(t("Failed to sign in"));
      enqueueSnackbar(t("Failed to sign in"), { variant: "error" });

      const message = error?.response.data.errors.join(", ");
      if (message.length > 0) {
        setStatus(message);
      }
    }

    setSubmitting(false);
  },
})(Component);

export default withRouter(withTranslation()(SignInForm));
