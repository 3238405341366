import styled from "styled-components";

export const Container = styled.div``;

export const PrintButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const OrderContainer = styled.div`
  min-height: 100px;
  border-radius: 8px;
  margin-top: 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const Description = styled.p`
  color: #d9d9d9;
`;

export const Button = styled.button<{ hoverColor?: string }>`
  background-color: ${(props) => props.theme.colors.gray};
  outline: none;
  border-radius: 8px;
  color: black;
  text-align: center;
  height: 40px;
  border: none;
  padding: 10px 16px;
  margin-top: 15px;
  cursor: pointer;
  font-size: 12px;

  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) =>
      props.hoverColor ? props.hoverColor : props.theme.colors.grayDarker};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const PaymentMethod = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
`;

export const RadioItem = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 14px;

  input {
    margin: 0;
    cursor: pointer;

    &:disabled {
      cursor: auto;
    }
  }
`;

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Total = styled.h3`
  text-transform: uppercase;
  text-align: right;
`;

export const Subtotal = styled.h5`
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.grayDarker};
  margin: 3px;

  div,
  input {
    display: inline-block;
    max-width: 30px;
    text-align: center;
  }
`;
