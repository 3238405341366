import React from "react";
import { FormikProps, Field } from "formik";
import { FormValues, IComponentProps } from "./type";
import { Container, FormWrapper, Input, Label, Button, Error } from "./style";
import LoadingSpinner from "../../controls/loading_spinner";

const tag_options = [
  "material",
  "salary",
  "electricity",
  "milk",
  "bread",
  "soda",
  "coca",
  "suger",
  "ham",
];

const Form: React.FunctionComponent<
  FormikProps<FormValues> & IComponentProps
> = (props: FormikProps<FormValues> & IComponentProps) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    status,
    t,
  } = props;

  return (
    <Container>
      <FormWrapper>
        <p>{status}</p>
        <form onSubmit={handleSubmit} id="ledger-form">
          <Label>{t("category")}</Label>
          <div role="group" aria-labelledby="category-radio-group">
            <label>
              <Field type="radio" name="category" value="income" />
              {t("income")}
            </label>
            <label>
              <Field type="radio" name="category" value="cost" />
              {t("cost")}
            </label>
          </div>

          <Label>{t("tags")}</Label>
          <div role="group" aria-labelledby="tag-checkbox-group">
            {tag_options.map((option: string) => (
              <label key={`tags-option-${option}`}>
                <Field type="checkbox" name="tag_list" value={option} />
                {t(`ledger-form.${option}`)}
              </label>
            ))}
          </div>

          <Label>{t("description")}</Label>
          <Input
            type="description"
            name="description"
            className="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            placeholder={t("description")}
          />
          {errors.description && touched.description && (
            <Error>{t(errors.description)}</Error>
          )}
          <Label>{t("total")}</Label>
          <Input
            type="number"
            min={0}
            step="0.01"
            name="total"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.total}
            className="total"
          />
          {errors.total && touched.total && <Error>{t(errors.total)}</Error>}

          <Label>{t("ledger-form.billed-at")}</Label>
          <Input
            type="datetime-local"
            name="billed_at"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.billed_at}
          />
          {errors.billed_at && touched.billed_at && (
            <Error>{t(errors.billed_at)}</Error>
          )}

          <Button
            type="submit"
            id="create-ledger-button"
            disabled={
              isSubmitting ||
              !!(errors.description && touched.description) ||
              !!(errors.total && touched.total)
            }
          >
            {isSubmitting ? <LoadingSpinner /> : t("ledger-form.submit")}
          </Button>
        </form>
      </FormWrapper>
    </Container>
  );
};

export default Form;
