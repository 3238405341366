import React from "react";
import { Container, Button } from "./style";
import { IComponentProps } from "./types";

const BUTTON_LIMIT = 4;

const Pagination: React.FC<IComponentProps> = ({
  current_page,
  last_page,
  onPageClick,
  variant = "gray",
}: IComponentProps) => {
  const LeftPages = [];
  const RightPages = [];

  for (
    let index = current_page - BUTTON_LIMIT;
    index < current_page;
    index += 1
  ) {
    if (index >= 1) {
      LeftPages.push(
        <Button
          key={`pagination-left-${index}`}
          onClick={() => {
            onPageClick(index);
          }}
          variant={variant}
        >
          {index}
        </Button>
      );
    }
  }

  for (
    let index = current_page + 1;
    index < current_page + BUTTON_LIMIT + 1;
    index += 1
  ) {
    if (index <= last_page) {
      RightPages.push(
        <Button
          key={`pagination-right-${index}`}
          onClick={() => {
            onPageClick(index);
          }}
          variant={variant}
        >
          {index}
        </Button>
      );
    }
  }

  return (
    <Container>
      {LeftPages}
      <Button active disabled variant={variant}>
        {current_page}
      </Button>
      {RightPages}
    </Container>
  );
};

export default Pagination;
