import React from "react";
import Cookies from "../../lib/cookies";
import { authClient } from "../../api";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Component from "./component";
import event from "../../event";

export const withLogOut =
  (InnerComponent: React.ComponentType<any>): React.FC =>
  () => {
    const onSignOutClick = React.useCallback(async () => {
      const is_employee = Cookies.get("is_employee") == "true";

      await authClient.sign_out(is_employee);
      event.reset_identity();

      window.location.reload();
    }, []);

    return <InnerComponent onSignOutClick={onSignOutClick} />;
  };

export default withLogOut(withRouter(withTranslation()(Component)));
