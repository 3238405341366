import React from "react";
import { FormikProps } from "formik";
import { FormValues, IComponentProps } from "./type";
import { Container, FormWrapper, Input, Label, Button, Error } from "./style";
import LoadingSpinner from "../../controls/loading_spinner";

const Form: React.FunctionComponent<
  FormikProps<FormValues> & IComponentProps
> = (props: FormikProps<FormValues> & IComponentProps) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    status,
    t,
  } = props;

  return (
    <Container>
      <FormWrapper>
        <p>{status}</p>
        <form onSubmit={handleSubmit} id="sign-in-form">
          <Label>{t("password")}</Label>
          <Input
            type="password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            placeholder={t("password")}
          />
          {errors.password && touched.password && (
            <Error>{t(errors.password)}</Error>
          )}

          <Label>{t("password-confirmation")}</Label>
          <Input
            type="password"
            name="passwordConfirmation"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.passwordConfirmation}
            placeholder={t("password-confirmation")}
          />
          {errors.passwordConfirmation && touched.passwordConfirmation && (
            <Error>{t(errors.passwordConfirmation)}</Error>
          )}

          <Button
            type="submit"
            disabled={
              isSubmitting ||
              !!(errors.password && touched.password) ||
              !!(errors.passwordConfirmation && touched.passwordConfirmation)
            }
          >
            {isSubmitting ? <LoadingSpinner /> : t("Update Password")}
          </Button>
        </form>
      </FormWrapper>
    </Container>
  );
};

export default Form;
