import { up } from "styled-breakpoints";
import styled, { css } from "styled-components";

export const InputBase = css`
  font-family: inherit;
  background-color: ${(props) => props.theme.colors.gray};
  border: 1px solid transparent;

  width: 100%;

  color: ${(props) => props.theme.colors.black};
  border-radius: 8px;
  padding: 10px 16px;

  transition: all 200ms ease;

  &:focus {
    background-color: #fff;
    box-shadow: 0 0 0 4px rgba(0, 118, 237, 0.1);
  }

  ${up("md")} {
    max-width: 25em;
  }
`;

const Input = styled.input`
  ${InputBase}
`;

export default Input;
