import React from "react";
import { observer } from "mobx-react";
import moment from "moment";
import Moment from "react-moment";
import Select from "react-select";
import { map } from "lodash";
import { RIEInput } from "riek";
import Item from "./item";
import {
  Container,
  Description,
  OrderContainer,
  Button,
  ButtonContainer,
  Subtotal,
  Total,
  PrintButtons,
  RadioContainer,
  RadioItem,
  PaymentMethod,
  TotalContainer,
} from "./style";
import { IComponentProps } from "./types";
import Product from "../../stores/product";
import InputContainer from "../../controls/inline_input";
import { enqueueSnackbar } from "notistack";

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    borderColor: "#fff",
    borderRadius: "8px",
    backgroundColor: "#f5f3f4",
  }),
};

/**
 * Functional Component
 * renders a form to update order details.
 *
 * @param  {order object} order
 * @param  {array of product options used for select} productOptions
 * @param  {callback returns product given id} getProductById
 * @param  {method to show notification} enqueueSnackbar
 * @param  {translation function} t
 *
 * @return form with order items
 *
 * @test
 * it should add new item when select value is selected
 * it should send cancel or complete request to server when button are pressed
 * it should remove item when item count is 0
 */
const OrderForm: React.FunctionComponent<IComponentProps> = ({
  order,
  printCallback,
  printCodeCallback,
  productOptions,
  paymentMethods,
  getProductById,
  itemInputReferenceCallback,
  t,
}) => {
  const onSelectChange = React.useCallback(
    async (selected: { value: Product; label: string } | null) => {
      if (selected === null) return;

      try {
        await order.createItem(selected.value.id, 1);
        enqueueSnackbar(t("order-form.item.update.success"), {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar(t("order-form.item.update.failure"), {
          variant: "error",
        });
      }
    },
    [order, enqueueSnackbar, t]
  );

  // Display order items
  const items = map(order.items, (item) => {
    const product = getProductById(String(item.product_id));

    if (!product) {
      return null;
    }

    return <Item key={item.key} item={item} product={product} />;
  });

  const renderItems = order.items.length > 0 ? items : t("order-form.no-items");

  const handlePaymentMethodChange = React.useCallback(
    (id: number) => {
      order.update({ payment_method_id: id });
    },
    [order]
  );

  return (
    <Container data-order-id={order.id}>
      <PrintButtons>
        <Button
          type="button"
          className="print-button"
          onClick={() => {
            printCallback();
          }}
          hoverColor="#2ec4b6"
          disabled={order.items.length < 1}
        >
          {t("order-form.print")}
        </Button>
        <Button
          type="button"
          className="print-code-button"
          onClick={() => {
            printCodeCallback();
          }}
          hoverColor="#2ec4b6"
          disabled={order.items.length < 1}
        >
          {t("order-form.print-code")}
        </Button>
      </PrintButtons>
      <Description>
        <Moment fromNow>{moment(order.created_at)}</Moment>
      </Description>
      <Select
        ref={itemInputReferenceCallback}
        className="product-select"
        options={productOptions}
        onChange={onSelectChange}
        styles={customStyles}
      />
      <OrderContainer>{renderItems}</OrderContainer>
      <PaymentMethod>
        <RadioContainer>
          {paymentMethods?.map((payment_method) => (
            <RadioItem key={payment_method.id} data-testid="payment-method">
              <input
                data-testid="payment-method-input"
                type="radio"
                defaultChecked={order?.payment_method?.id === payment_method.id}
                name={`payment-method-${order.id}`}
                value={payment_method.id}
                onClick={() => {
                  handlePaymentMethodChange(payment_method.id);
                }}
              />
              <label>{payment_method.name}</label>
            </RadioItem>
          ))}
        </RadioContainer>
        <TotalContainer>
          <Subtotal>
            {t("order-form.discount")}
            <InputContainer className="discount-percentage-input">
              <RIEInput
                value={order.discount_percentage}
                change={({
                  discount_percentage,
                }: {
                  discount_percentage: number;
                }) => order.update({ discount_percentage })}
                propName="discount_percentage"
                classEditing="editing"
              />{" "}
            </InputContainer>
            %
          </Subtotal>

          <Subtotal>
            {t("order-form.subtotal")} $ {order.subtotal}
          </Subtotal>
          <Total className="total-price">
            {t("order-form.total")} $ {order.total}
          </Total>
        </TotalContainer>
      </PaymentMethod>
      <ButtonContainer>
        <Button
          className="cancel-order-button"
          type="button"
          onClick={() => {
            if (window.confirm(t("Are you sure?"))) {
              order.cancel();
            }
          }}
          hoverColor="#ffbf69"
        >
          {t("order-form.cancel")}
        </Button>
        <Button
          type="button"
          onClick={async () => {
            if (window.confirm(t("Are you sure?"))) {
              try {
                await order.complete();
                enqueueSnackbar(t("order-form.completed"), {
                  variant: "success",
                });
              } catch (e) {
                enqueueSnackbar(t("order-form.complete-failed"), {
                  variant: "error",
                });
              }
            }
          }}
          hoverColor="#2ec4b6"
          disabled={order.items.length < 1}
          className="complete-order-button"
        >
          {t("order-form.complete")}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default observer(OrderForm);
