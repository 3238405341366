import React from "react";
import {
  Container,
  CardContainer,
  Horizontal,
  Title,
  Description,
} from "./style";
import DateRanges from "../../components/date_ranges";
import { today } from "../../components/date_ranges/ranges";
import type { IDateTimeRange } from "../../components/date_ranges/types";
import Sales from "../../components/reports/sales";
import Visitors from "../../components/reports/visitors";
import Count from "../../components/reports/count";
import Total from "../../components/reports/total";
import Cost from "../../components/reports/cost";
import CostTag from "../../components/reports/cost_tag";
import Income from "../../components/reports/income";
import SubscriptionModal from "../../components/subscription_modal";
import { IComponentProps } from "./types";
import useSubscribed from "../../hooks/useSubscribed";

const DashboardPage: React.FC<IComponentProps> = ({ t }: IComponentProps) => {
  const [range, setRange] = React.useState<IDateTimeRange>(today());
  const [subscribed] = useSubscribed();

  return (
    <>
      {!subscribed && <SubscriptionModal />}

      <Container>
        <Title>{t("dashboard-page.title")}</Title>
        <Description>{t("dashboard-page.description")}</Description>
        <Horizontal>
          <CardContainer>
            <DateRanges
              onDateChange={(range: IDateTimeRange) => {
                setRange(range);
              }}
            />
            <Total range={range} />
            <Cost range={range} />
            <Income range={range} />
          </CardContainer>

          <Sales range={range} />
        </Horizontal>
        <Horizontal>
          <Count range={range} />
          <Visitors range={range} />
        </Horizontal>
        <CostTag range={range} />
      </Container>
    </>
  );
};

export default DashboardPage;
