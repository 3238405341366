import { useEffect, useState } from "react";
import Cookies from "../lib/cookies";
import { authClient } from "../api";

interface IUseValidateCredentials {
  isLoading: boolean;
  isValidated: boolean;
  isAuthenticated: boolean;
  is_employee: boolean;
}

function useValidateCredentials(): IUseValidateCredentials {
  const is_employee = Cookies.get("is_employee") == "true";
  const accessToken = Cookies.get("access-token");
  const client = Cookies.get("client");
  const uid = Cookies.get("uid");
  const isAuthenticated = !!uid && !!client && !!accessToken;

  const [isLoading, setLoading] = useState(true);
  const [isValidated, setValidated] = useState(false);

  useEffect(() => {
    async function validateCredentials() {
      try {
        if (!!uid && !!client && !!accessToken) {
          const response = await authClient.validate_token(
            uid,
            client,
            accessToken,
            is_employee,
          );
          setValidated(response.success);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setValidated(false);
        setLoading(false);
      }
    }

    validateCredentials();
  }, [accessToken, client, uid]);

  return {
    isLoading,
    isValidated,
    isAuthenticated,
    is_employee,
  };
}

export default useValidateCredentials;
