import React, { useCallback } from "react";
import {
  Container,
  Button,
  ButtonRight,
  QR,
  Menu,
  Dropdown,
  DropdownItem,
  ButtonDropdown,
  NavbarItems,
  Divider,
} from "./style";
import useDropdown from "../../hooks/useDropdown";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import { IComponentProps } from "./types";
import Logo from "../../controls/logo";
import event from "../../event";
import { useFlags } from "flagsmith/react";

const Navbar: React.FunctionComponent<IComponentProps> = ({
  onSignOutClick,
  match,
  t,
}) => {
  const flags = useFlags(["shop_review"]);
  const [openDropdown, setOpenDropDown, containerRef] = useDropdown();

  const isMobile = useBreakpoint(down("md"));

  const onLinkClick = useCallback(
    (button_name: string) => () => {
      event.track(`navbar.${button_name}.click`);
    },
    []
  );

  return (
    <Container>
      <Logo />
      {!isMobile && (
        <NavbarItems id="navbar-items">
          <Button
            active={match.path === "/products" ? 1 : 0}
            to="/products"
            onClick={onLinkClick("products-link")}
          >
            {t("navbar.products")}
          </Button>
          {flags.shop_review.enabled && (
            <Button
              active={match.path === "/reviews" ? 1 : 0}
              to="/reviews"
              onClick={onLinkClick("reviews-link")}
            >
              {t("reviews")}
            </Button>
          )}
          <Button
            active={match.path === "/tables" ? 1 : 0}
            to="/tables"
            onClick={onLinkClick("tables-link")}
          >
            {t("navbar.tables")}
          </Button>
          <Button
            active={match.path === "/orders" ? 1 : 0}
            to="/orders"
            onClick={onLinkClick("orders-link")}
          >
            {t("navbar.orders")}
          </Button>
          <Button
            active={match.path === "/ledgers" ? 1 : 0}
            to="/ledgers"
            onClick={onLinkClick("ledgers-link")}
          >
            {t("navbar.ledgers")}
          </Button>
          <Button
            active={match.path === "/dashboard" ? 1 : 0}
            to="/dashboard"
            onClick={onLinkClick("dashboard-link")}
          >
            {t("navbar.dashboard")}
          </Button>
          <Button
            active={match.path === "/digital-menu" ? 1 : 0}
            to="/digital-menu"
            onClick={onLinkClick("digital-menu-link")}
          >
            <QR size={20} /> {t("navbar.digital-menu")}
          </Button>
        </NavbarItems>
      )}
      <div
        style={{ display: "inline-block", position: "relative" }}
        ref={containerRef}
      >
        <ButtonRight id="menuButton" active={openDropdown}>
          <Menu
            onClick={() => {
              setOpenDropDown(!openDropdown);
            }}
          />
        </ButtonRight>
        <Dropdown id="dropdown" active={openDropdown}>
          {isMobile && (
            <>
              <DropdownItem>
                <ButtonDropdown
                  active={match.path === "/products" ? 1 : 0}
                  to="/products"
                  onClick={onLinkClick("products-link")}
                >
                  {t("navbar.products")}
                </ButtonDropdown>
              </DropdownItem>

              {flags.shop_review.enabled && (
                <DropdownItem>
                  <ButtonDropdown
                    active={match.path === "/reviews" ? 1 : 0}
                    to="/reviews"
                    onClick={onLinkClick("reviews-link")}
                  >
                    {t("reviews")}
                  </ButtonDropdown>
                </DropdownItem>
              )}

              <DropdownItem>
                <ButtonDropdown
                  active={match.path === "/tables" ? 1 : 0}
                  to="/tables"
                  onClick={onLinkClick("tables-link")}
                >
                  {t("navbar.tables")}
                </ButtonDropdown>
              </DropdownItem>

              <DropdownItem>
                <ButtonDropdown
                  active={match.path === "/orders" ? 1 : 0}
                  to="/orders"
                  onClick={onLinkClick("orders-link")}
                >
                  {t("navbar.orders")}
                </ButtonDropdown>
              </DropdownItem>

              <DropdownItem>
                <ButtonDropdown
                  active={match.path === "/ledgers" ? 1 : 0}
                  to="/ledgers"
                  onClick={onLinkClick("ledgers-link")}
                >
                  {t("navbar.ledgers")}
                </ButtonDropdown>
              </DropdownItem>

              <DropdownItem>
                <ButtonDropdown
                  active={match.path === "/dashboard" ? 1 : 0}
                  to="/dashboard"
                  onClick={onLinkClick("dashboard-link")}
                >
                  {t("navbar.dashboard")}
                </ButtonDropdown>
              </DropdownItem>

              <DropdownItem>
                <ButtonDropdown
                  active={match.path === "/digital-menu" ? 1 : 0}
                  to="/digital-menu"
                  onClick={onLinkClick("digital-menu-link")}
                >
                  {t("navbar.digital-menu")}
                </ButtonDropdown>
              </DropdownItem>
              <Divider />
            </>
          )}
          <DropdownItem>
            <ButtonDropdown
              active={match.path === "/billing" ? 1 : 0}
              to="/billing"
              onClick={onLinkClick("billing-link")}
            >
              {t("navbar.billing")}
            </ButtonDropdown>
          </DropdownItem>
          <DropdownItem>
            <ButtonDropdown
              active={match.path === "/profile" ? 1 : 0}
              to="/profile"
              onClick={onLinkClick("profile-link")}
            >
              {t("navbar.profile")}
            </ButtonDropdown>
          </DropdownItem>
          <Divider />
          <DropdownItem>
            <ButtonDropdown onClick={onSignOutClick} to="/sign-in">
              {t("navbar.sign-out")}
            </ButtonDropdown>
          </DropdownItem>
        </Dropdown>
      </div>
    </Container>
  );
};

export default Navbar;
