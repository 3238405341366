import { makeAutoObservable } from "mobx";
import FloorsStore from "./floors_store";
import TablesStore from "./tables_store";
import ProductsStore from "./products_store";
import OrdersStore from "./orders_store";
import PaymentMethodsStore from "./payment_methods_store";
import type Table from "./table";
import type Product from "./product";
import type Floor from "./floor";
import Company from "./company";
import { profileClient } from "../api";

class TablesPageStore {
  ordersStore!: OrdersStore;

  productsStore!: ProductsStore;

  floorsStore!: FloorsStore;

  tablesStore!: TablesStore;

  paymentMethodsStore!: PaymentMethodsStore;

  // Preoperty specific to the page
  selectedFloorId!: number;

  selectedTableId!: number;

  scrollToTable?: (table_id: number) => void;

  searchQuery = "";

  isEditingTables = false;

  itemInputs: React.MutableRefObject<{
    [key: number]: HTMLInputElement;
  }> = {
    current: {},
  };

  company!: Company;

  is_loading = false;

  constructor() {
    makeAutoObservable(this);

    this.productsStore = new ProductsStore();
    this.ordersStore = new OrdersStore();
    this.floorsStore = new FloorsStore();
    this.tablesStore = new TablesStore(this.ordersStore);
    this.paymentMethodsStore = new PaymentMethodsStore();
  }

  load(): void {
    profileClient.show().then((response) => {
      this.company = new Company(response);
    });
    this.tablesStore.load();
    this.productsStore.loadAll();
    this.ordersStore.loadAllAlive();
    this.floorsStore.load();
    this.paymentMethodsStore.load();
  }

  get floors(): Floor[] {
    return Array.from(this.floorsStore.floors.values());
  }

  get tables(): Table[] {
    return Array.from(this.tablesStore.tables.values());
  }

  get filteredTables(): Table[] {
    return this.tables.filter((table) => table.name.includes(this.searchQuery));
  }

  get getProductsOptions(): { value: Product; label: string }[] {
    const products: Product[] = Array.from(
      this.productsStore.products.values()
    );

    return products.map((product) => ({
      value: product,
      label: product.name + (product.code?.length ? ` (${product.code})` : ""),
    }));
  }

  get getPaymentMethods() {
    return Array.from(this.paymentMethodsStore.payment_methods.values());
  }

  selectFloor(floor_id: number): void {
    this.selectedFloorId = floor_id;
  }

  selectTable(table_id: number): void {
    this.selectedTableId = table_id;
  }

  getProductById(product_id: string): Product | undefined {
    return this.productsStore.products.get(product_id);
  }

  getFloorById(floor_id: number): Floor | undefined {
    return this.floorsStore.floors.get(floor_id);
  }

  getTableById(table_id: number): Table | undefined {
    return this.tablesStore.tables.get(table_id);
  }

  getSelectedFloor(): Floor | undefined {
    return this.getFloorById(this.selectedFloorId);
  }

  getSelectedTable(): Table | undefined {
    return this.getTableById(this.selectedFloorId);
  }

  setScrollToTable(callback: (table_id: number) => void): void {
    this.scrollToTable = callback;
  }

  focusItemInputByTableId(table_id: number) {
    this.itemInputs.current[table_id].focus();
  }

  setSearchQuery(value: string): void {
    this.searchQuery = value;
  }

  setIsEditingTables(value: boolean): void {
    this.isEditingTables = value;
  }
}

export default TablesPageStore;
