import styled from "styled-components";
import { down } from "styled-breakpoints";
import { CheckmarkCircle2 } from "@styled-icons/evaicons-solid/CheckmarkCircle2";
import { Circle as CircleIcon } from "@styled-icons/fa-solid/Circle";

export const Checked = styled(CheckmarkCircle2)`
  width: 25px;
  color: #06d6a0;
  margin-right: 10px;
`;

export const Circle = styled(CircleIcon)`
  width: 10px;
  margin-right: 8px;
`;

export const Item = styled.li``;

export const Container = styled.div`
  margin-top: 130px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  ${down("md")} {
    margin-top: 30px;
  }
`;

export const Title = styled.h1<{ highlight?: boolean }>`
  margin-bottom: 0;

  color: ${(props) => (props.highlight ? props.theme.colors.blue : "inherit")};
`;

export const Description = styled.p``;

export const Price = styled.h2`
  font-weight: normal;

  span {
    font-size: 15px;
  }
`;

export const SubscribeContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Vertical = styled.div`
  max-width: 400px;
`;

export const PlanContainer = styled.div<{ background?: string }>`
  border: none;
  border-radius: 8px;
  padding: 30px 43px;
  margin-bottom: 15px;
  max-width: 430px;
  height: 100%;
  background-color: ${(props) =>
    props.background ? props.background : "#fff"};

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  box-shadow: 0 0px 2.2px rgba(0, 0, 0, 0.02), 0 0px 5.3px rgba(0, 0, 0, 0.028),
    0 0px 10px rgba(0, 0, 0, 0.035), 0 0px 17.9px rgba(0, 0, 0, 0.042),
    0 0px 33.4px rgba(0, 0, 0, 0.05), 0 0px 80px rgba(0, 0, 0, 0.07);
`;

export const Button = styled.button<{ suspend?: boolean }>`
  display: block;
  background-color: ${(props) =>
    props.suspend ? props.theme.colors.red : props.theme.colors.blue};
  color: #fff;
  width: 100%;
  outline: none;
  border-radius: 8px;
  text-align: center;
  height: 40px;
  border: none;
  padding: 10px 16px;
  margin: auto;
  margin-top: 15px;
  cursor: pointer;

  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) =>
      props.suspend
        ? props.theme.colors.redDarker
        : props.theme.colors.blueDarker};
  }
`;
