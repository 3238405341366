import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  Container,
  LeftContainer,
  RightContainer,
  Header,
  Description,
  Link,
  SignOut,
  Divider,
} from "./style";
import SignInForm from "../../components/sign_in_form";
import { IComponentProps } from "./types";
import Logo from "../../controls/logo";
import LanguageSelect from "../../components/language_select";
import GoogleSignInButton from "../../components/google_sign_in_button";

const SignInPage: React.FunctionComponent<IComponentProps> = ({
  isValidated,
  onSignOutClick,
  t,
}) => {
  return (
    <>
      <Container>
        <LeftContainer>
          <Logo />
          <Header>{t("sign-in-page.title")}</Header>
          <Description>{t("sign-in-page.description")}</Description>
          <Description>
            {t("dont-have-account")}{" "}
            <ReactRouterLink to="/sign_up">{t("sign-up")}</ReactRouterLink>!
          </Description>
          {!isValidated && (
            <Description>
              {t("forgot-password")}{" "}
              <ReactRouterLink to="/reset_password">
                {t("reset-password")}
              </ReactRouterLink>
            </Description>
          )}
          <LanguageSelect />
        </LeftContainer>

        {isValidated ? (
          <RightContainer>
            <Link to="/products">{t("Go To Store")}</Link>
            <SignOut onClick={onSignOutClick}>{t("Sign out")}</SignOut>
          </RightContainer>
        ) : (
          <RightContainer>
            <GoogleSignInButton />
            <Divider />
            <SignInForm />
          </RightContainer>
        )}
      </Container>
    </>
  );
};

export default SignInPage;
