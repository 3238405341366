import React from "react";
import { Container, Name, Price } from "./style";
import { ICheckBoxIds, IComponentProps } from "./types";
import { compact, values } from "lodash";
import { ICheckBoxState } from "./types";

const CheckboxProductOptions: React.FC<IComponentProps> = ({
  product_options,
  selection_limit,
  onChangeCallback,
  initialValue,
}: IComponentProps) => {
  const [value, setValue] = React.useState<ICheckBoxState>(initialValue);

  return (
    <Container role="group">
      {product_options.map((product_option) => (
        <Name key={product_option.key}>
          <input
            type="checkbox"
            name={String(product_option.product_option_group_id)}
            value={product_option.id}
            onChange={(event) => {
              if (selection_limit)
                setValue((prev: ICheckBoxState) => {
                  if (
                    compact(values(prev.ids)).length >= selection_limit &&
                    !(prev.ids as ICheckBoxIds)[Number(event.target.value)]
                  ) {
                    return prev;
                  }

                  const new_value = {
                    ...prev,
                    ids: {
                      ...prev.ids,
                      [event.target.value]: !(prev.ids as ICheckBoxIds)[
                        Number(event.target.value)
                      ],
                    },
                    option_id_modified: product_option.id,
                  };

                  onChangeCallback(new_value);
                  return new_value;
                });
            }}
            checked={value.ids[product_option.id]}
          />
          {product_option.name}
          <Price>${product_option.price_f}</Price>
        </Name>
      ))}
    </Container>
  );
};

export default CheckboxProductOptions;
