import React from "react";
import {
  Subtitle,
  Description,
  Box,
  OptionGroups,
  OptionGroupBox,
  Group,
  Options,
  Container,
  Button,
  OptionCreateButton,
  GroupTitle,
  DeleteIcon,
  ProductName,
  Content,
  LimitWarn,
} from "./style";
import { IComponentProps } from "./types";
import { RIENumber, RIEToggle } from "riek";
import InputContainer from "../../../../controls/inline_input";
import ProductOptionForm from "./product_option_form";
import InputCell from "../../../editable-cells/input";

const ProductOptionGroupsForm: React.FC<IComponentProps> = ({
  t,
  product_name,
  productOptionGroups,
  onProductOptionGroupCreate,
}: IComponentProps) => {
  const OPTION_GROUPS_LIMIT = 10;
  const OPTIONS_PER_GROUP_LIMIT = 15;

  return (
    <Container
      initial={{ y: -3, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: "easeIn", duration: 0.3 }}
    >
      <ProductName>{product_name}</ProductName>
      <Content>
        <Box>
          <Subtitle>{t("product-option-groups-form.title")}</Subtitle>
          <Description>
            {t("product-option-groups-form.description")}
          </Description>

          {productOptionGroups.length >= OPTION_GROUPS_LIMIT ? (
            <LimitWarn>
              {t("product-option-groups-form.max-groups-limit", {
                limit: OPTION_GROUPS_LIMIT,
              })}
            </LimitWarn>
          ) : (
            <Button
              data-testid="create-product-option-group"
              onClick={() => {
                onProductOptionGroupCreate();
              }}
            >
              {t("product-option-groups-form.create-group")}
            </Button>
          )}
        </Box>

        <OptionGroups>
          {productOptionGroups.map((product_option_group) => (
            <OptionGroupBox key={product_option_group.key}>
              <Group>
                <GroupTitle>
                  <InputContainer style={{ maxWidth: 150 }}>
                    <InputCell
                      type="text"
                      value={product_option_group.name}
                      updateCallback={async (value) =>
                        product_option_group.update({ name: value })
                      }
                      test_id="product-option-group-name-input"
                    />
                  </InputContainer>

                  <DeleteIcon
                    className="delete-product-option-group"
                    size={20}
                    whileHover={{ scale: 1.2 }}
                    onClick={() => {
                      if (window.confirm(t("Are you sure?"))) {
                        product_option_group.delete();
                      }
                    }}
                    data-product-option-group-id={product_option_group.id}
                  />
                </GroupTitle>

                <Description>
                  {t("product-option-groups-form.is-mandatory")}{" "}
                  <RIEToggle
                    value={product_option_group.is_mandatory}
                    change={({ is_mandatory }: { is_mandatory: boolean }) =>
                      product_option_group.update({ is_mandatory })
                    }
                    propName="is_mandatory"
                    classEditing="editing"
                    textTrue="yes"
                    textFalse="no"
                    className="editable product-is-mandatory-toggle"
                  />
                </Description>

                <Description>
                  {t("product-option-groups-form.selection-limit")}{" "}
                  <RIENumber
                    value={product_option_group.selection_limit}
                    change={({
                      selection_limit,
                    }: {
                      selection_limit: number;
                    }) => product_option_group.update({ selection_limit })}
                    step="1"
                    min="0"
                    max="99"
                    propName="selection_limit"
                    classEditing="editing"
                    className="editable product-option-group-selection-limit-input"
                    disabled={product_option_group.isLoading}
                  />{" "}
                  {t("options")}
                </Description>

                {product_option_group.product_options.length >=
                OPTIONS_PER_GROUP_LIMIT ? (
                  <LimitWarn>
                    {t("product-option-groups-form.max-options-limit", {
                      limit: OPTIONS_PER_GROUP_LIMIT,
                    })}
                  </LimitWarn>
                ) : (
                  <OptionCreateButton
                    className="create-product-option"
                    onClick={() => {
                      product_option_group.createProductOption({
                        name: t("product-option-groups-form.edit-me"),
                        price: 0,
                      });
                    }}
                  >
                    {t("product-option-groups-form.new-option")}
                  </OptionCreateButton>
                )}
              </Group>
              {product_option_group.product_options.length > 0 && (
                <Options>
                  {product_option_group.product_options.map(
                    (product_option) => (
                      <ProductOptionForm
                        key={product_option.key}
                        productOption={product_option}
                      />
                    )
                  )}
                </Options>
              )}
            </OptionGroupBox>
          ))}
        </OptionGroups>
      </Content>
    </Container>
  );
};

export default ProductOptionGroupsForm;
