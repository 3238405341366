import styled from "styled-components";
import { Plus as PlusIcon } from "@styled-icons/heroicons-outline";
import { MenuAltLeft as MenuAlt3 } from "@styled-icons/boxicons-regular/MenuAltLeft";
import { DeleteForever } from "@styled-icons/material-outlined/DeleteForever";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-bottom: 10px;
  z-index: 1;
`;

export const Title = styled.h2`
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 15px;
  margin-right: 55px;
  width: 150px;
`;

export const Button = styled.button<{ active?: boolean }>`
  background-color: ${(props) =>
    props.active ? props.theme.colors.grayDarker : props.theme.colors.gray};
  outline: none;
  border-radius: 8px;
  color: black;
  text-align: center;
  height: 40px;
  border: none;
  padding: 10px 16px;
  margin-top: 15px;
  margin-right: 5px;
  cursor: pointer;

  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.grayDarker};
  }
`;

export const Plus = styled(PlusIcon)`
  width: 15px;
`;

export const Menu = styled(MenuAlt3)`
  width: 15px;
`;

export const Delete = styled(DeleteForever)`
  width: 15px;
  margin-right: 5px;
`;

export const Dropdown = styled.div<{ active?: boolean }>`
  background-color: #fff;
  display: ${(props) => (props.active ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 8px;
  margin-top: 15px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);

  position: absolute;
  min-width: 130px;
`;

export const DropdownItem = styled.a<{ hoverColor?: string }>`
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;

  &:hover {
    color: ${(props) => (props.hoverColor ? props.hoverColor : "black")};
  }
`;
