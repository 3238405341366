import styled from "styled-components";
import { up } from "styled-breakpoints";
import { motion } from "framer-motion";
import { DeleteForever } from "@styled-icons/material/DeleteForever";

export const Container = styled(motion.div)`
  width: 95vw;
  max-height: 80vh;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  overflow-y: auto;
  padding: 20px;

  ${up("md")} {
    width: auto;
    max-width: 95vw;
    min-width: 350px;
    position: relative;
  }
`;

export const ProductName = styled.h2`
  margin: 0;
  padding-bottom: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray};
  text-transform: capitalize;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${up("md")} {
    flex-direction: row;
  }
`;

export const Subtitle = styled.h2`
  margin-bottom: 0;
`;

export const Description = styled.p``;

export const LimitWarn = styled.span`
  color: ${(props) => props.theme.colors.grayDarker};
`;

export const GroupTitle = styled.h3`
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Box = styled.div`
  ${up("md")} {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    max-height: calc(80vh - 100px);
    align-items: start;
    justify-content: center;
  }
`;

export const OptionGroups = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${up("md")} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    padding: 20px;
  }
`;

export const OptionGroupBox = styled.div`
  padding: 15px;
  box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 8px;

  ${up("md")} {
    align-self: start;
  }
`;

export const Group = styled.div``;

export const Options = styled.div``;

export const Button = styled.button`
  color: black;
  outline: none;
  border-radius: 8px;
  text-align: center;
  height: 40px;
  border: none;
  padding: 10px 16px;
  // margin-top: 15px;
  cursor: pointer;

  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.grayDarker};
  }
`;

export const OptionCreateButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.blue};
  color: #fff;

  &:hover {
    background-color: ${(props) => props.theme.colors.blueDarker};
  }
`;

export const DeleteIcon = styled(motion(DeleteForever))`
  color: ${(props) => props.theme.colors.redDarker};
  cursor: pointer;
`;
