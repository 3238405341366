import React from "react";
import { RIEInput, RIETextArea, RIENumber, RIEToggle, RIETags } from "riek";
import Moment from "react-moment";
import Compressor from "compressorjs";
import InputContainer from "../../../controls/inline_input";
import { Container, Product, Button, Edit, Column } from "./style";
import ImageForm from "./image_form";
import ProductOptionGroupForm from "./product_option_groups_form";
import { IComponentProps } from "./types";
import useModal from "../../../hooks/useModal";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import { enqueueSnackbar } from "notistack";

const ProductRow: React.FC<IComponentProps> = ({
  product,
  t,
}: IComponentProps) => {
  const [dropzoneOpened, setDropzoneOpened] = React.useState(false);
  const [productOptionGroupsOpened, setProductOptionGroupsOpened] =
    React.useState(false);

  const [opened, setOpened, containerRef] = useModal();

  React.useEffect(() => {
    if (!opened) {
      setDropzoneOpened(false);
      setProductOptionGroupsOpened(false);
    }
  }, [opened]);

  const onDrop = (acceptedFiles: any[]) => {
    enqueueSnackbar(t("product-list.product.upload.loading"), {
      variant: "info",
    });

    acceptedFiles.forEach((file: any) => {
      const uploadContent = async () => {
        const formData = new FormData();

        if (file.type === "video/mp4") {
          try {
            formData.append("file", file);
            await product.addVideo(formData);
            enqueueSnackbar(t("product-list.product.upload.success"), {
              variant: "success",
            });
          } catch (error) {
            enqueueSnackbar(t("product-list.product.upload.failed"), {
              variant: "error",
            });
          }
        } else {
          new Compressor(file, {
            quality: 0.7,
            // mimeType: "jpg",
            maxWidth: 800,
            maxHeight: 800,
            // The compression process is asynchronous,
            // which means you have to access the `result` in the `success` hook function.
            success: async (result) => {
              try {
                formData.append("file", result);
                await product.addImage(formData);
                enqueueSnackbar(t("product-list.product.upload.success"), {
                  variant: "success",
                });
              } catch (error) {
                enqueueSnackbar(t("product-list.product.upload.failed"), {
                  variant: "error",
                });
              }
            },
            error(err) {
              console.error(err.message);
              enqueueSnackbar(t("product-list.product.upload.failed"), {
                variant: "error",
              });
            },
          });
        }
      };

      uploadContent();
    });
  };

  const onImageRemove = (image_id: number) => {
    product.removeImage(image_id);
  };

  const onVideoRemove = (video_id: number) => {
    product.removeVideo(video_id);
  };

  const onProductOptionGroupCreate = () => {
    product.createProductOptionGroup({
      name: "new group",
      is_mandatory: false,
      selection_limit: 1,
    });
  };

  const isMobile = useBreakpoint(down("md"));

  return (
    <>
      <Container
        opened={opened}
        ref={containerRef}
        onFocusCapture={(e) => {
          setOpened(true);
        }}
      >
        <Product
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, scaleX: [0.95, 1] }}
          transition={{ duration: 0.3, ease: "easeIn" }}
          className="product-list-item"
          opened={opened}
          data-product-id={product.id}
        >
          <InputContainer>
            {isMobile && opened && <Column>{t("Name")}</Column>}
            <RIEInput
              value={product.name}
              change={({ name }: { name: string }) => product.update({ name })}
              propName="name"
              classEditing="editing"
              className="editable product-name-input"
            />
          </InputContainer>
          {(!isMobile || opened) && (
            <InputContainer>
              {isMobile && <Column>{t("Description")}</Column>}
              <RIETextArea
                value={product.description ? product.description : t("content")}
                change={({ description }: { description: string }) =>
                  product.update({ description })
                }
                propName="description"
                classEditing="editing"
                className="editable product-description-input"
              />
            </InputContainer>
          )}
          <InputContainer>
            {isMobile && opened && <Column>{t("Price")}</Column>}
            <RIENumber
              value={`$ ${product.price}`}
              change={({ price }: { price: number }) =>
                product.update({ price })
              }
              step="0.01"
              min="0"
              max="99999"
              propName="price"
              classEditing="editing"
              className="editable product-price-input"
            />
          </InputContainer>
          {(!isMobile || opened) && (
            <>
              <InputContainer>
                {isMobile && <Column>{t("Published")}</Column>}
                <RIEToggle
                  value={product.published}
                  change={({ published }: { published: boolean }) =>
                    product.update({ published })
                  }
                  propName="published"
                  classEditing="editing"
                  textTrue={t("published")}
                  textFalse={t("hidden")}
                  className="editable product-published-button"
                />
              </InputContainer>
              <InputContainer>
                {isMobile && <Column>{t("Tag")}</Column>}
                <RIETags
                  value={product.tag_names}
                  change={({ tag_set }: { tag_set: Set<string> }) => {
                    product.update({
                      tag_list: Array.from(tag_set).join(", "),
                    });
                  }}
                  propName="tag_set"
                  classEditing="editing"
                  className="editable product-tags-input"
                />
              </InputContainer>
              <InputContainer>
                {isMobile && opened && <Column>{t("Code")}</Column>}
                <RIEInput
                  value={product.code}
                  change={({ code }: { code: string }) =>
                    product.update({ code })
                  }
                  propName="code"
                  classEditing="editing"
                  className="editable product-code-input"
                />
              </InputContainer>
              <InputContainer>
                {isMobile && (
                  <Column>{t("product-list.product.created")}</Column>
                )}
                <Moment fromNow>{product.created_at}</Moment>
              </InputContainer>

              <InputContainer>
                <Button
                  style={{ minWidth: "70px" }}
                  onClick={() => {
                    setProductOptionGroupsOpened((prev) => !prev);
                    if (dropzoneOpened) {
                      setDropzoneOpened(false);
                    }
                  }}
                  className="product-option-menu"
                >
                  {t("product-list.product.options")}
                </Button>
              </InputContainer>

              <InputContainer>
                <Button
                  style={{ minWidth: "70px" }}
                  onClick={() => {
                    setDropzoneOpened((prev) => !prev);
                    if (productOptionGroupsOpened) {
                      setProductOptionGroupsOpened(false);
                    }
                  }}
                  className="product-image-menu"
                >
                  {t("product-list.product.images")}
                </Button>
              </InputContainer>

              <InputContainer>
                <Button
                  onClick={() => {
                    if (window.confirm(t("Are you sure?"))) {
                      product.delete();
                    }
                  }}
                  hoverColor="#e63946"
                  className="product-delete-button"
                >
                  {t("product-list.product.delete")}
                </Button>
              </InputContainer>
            </>
          )}
          {isMobile && !opened && (
            <InputContainer>
              <Button hoverColor="#e63946" className="product-delete-button">
                <Edit size={20} />
              </Button>
            </InputContainer>
          )}
        </Product>

        {productOptionGroupsOpened && (
          <ProductOptionGroupForm
            product_name=""
            productOptionGroups={product.product_option_groups}
            onProductOptionGroupCreate={onProductOptionGroupCreate}
          />
        )}

        {dropzoneOpened && (
          <ImageForm
            product_name={product.name}
            images={product.images}
            videos={product.videos}
            onDrop={onDrop}
            onImageRemove={onImageRemove}
            onVideoRemove={onVideoRemove}
          />
        )}
      </Container>
    </>
  );
};

export default ProductRow;
