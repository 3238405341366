import styled from "styled-components";

export const Container = styled.div``;

export const Name = styled.label``;

export const Price = styled.span`
  font-size: 11px;
  margin-left: 3px;

  color: ${(props) => props.theme.colors.redDarker};
`;
