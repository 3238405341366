import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { updatePasswordSchema } from "./validation";
import { IComponentProps, FormValues } from "./type";
import Component from "./component";
import { authClient } from "../../api";

const initialValues: FormValues = {
  password: "",
  passwordConfirmation: "",
};

const UpdatePasswordForm = withFormik<IComponentProps, FormValues>({
  mapPropsToValues: () => initialValues,
  validationSchema: updatePasswordSchema,
  handleSubmit: async (
    values,
    { props, setSubmitting, setStatus, resetForm }
  ) => {
    const { password, passwordConfirmation } = values;

    const query = new URLSearchParams(props.location.search);

    const { t } = props;
    try {
      await authClient.update_password(
        password,
        passwordConfirmation,
        query.get("uid") || "",
        query.get("client") || "",
        query.get("access-token") || ""
      );
      resetForm();
      setStatus(t("You have updated password successfully!"));
      enqueueSnackbar(t("You have updated password successfully!"), {
        variant: "success",
      });
    } catch (error) {
      setStatus(t("Failed to update password"));
      enqueueSnackbar(t("Failed to update password"), {
        variant: "error",
      });

      const message = error?.response.data.errors.join(", ");
      if (message.length > 0) {
        setStatus(message);
      }
    }

    setSubmitting(false);
  },
})(Component);

export default withRouter(withTranslation()(UpdatePasswordForm));
