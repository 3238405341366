import styled from "styled-components";

export const Container = styled.div`
  margin-top: 15px;
`;

export const Name = styled.label`
  font-weight: bold;
`;

export const Info = styled.span`
  font-weight: normal;
  color: ${(props) => props.theme.colors.grayDarker};
`;
