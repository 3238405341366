import React from "react";

/**
 * Custom React hook to manage modals.
 * The modal can be closed by pressing the escape key or clicking outside the container.
 *
 * @returns {Array} [opened, setOpened, containerRef] - The opened state of the modal, the function to set the opened state and the ref of the container.
 * @example const [modal_opened, setModalOpened, containerRef] = useProductModal();
 *
 */

function useModal(): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  React.RefObject<HTMLDivElement>
] {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [opened, setOpened] = React.useState<boolean>(false);

  const handleClickOutside = (e: any) => {
    if (!containerRef.current) {
      // if the container ref is null, return
      return;
    }

    if (containerRef.current?.contains(e.target)) {
      // if the container ref contains the target,
      // it means that the click was inside the container, so return
      return;
    }
    setOpened(false);
  };

  const handleEscape = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setOpened(false);
    }
  };

  React.useEffect(() => {
    if (opened) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleEscape);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    };
  }, [opened]);

  return [opened, setOpened, containerRef];
}

export default useModal;
