import { AxiosInstance } from "axios";

interface ISales {
  created_at: string;
  total: number;
}
interface ISalesResponse {
  data: ISales[];
}

interface ITotalResponse {
  data: number;
}

interface ICountResponse {
  data: IProductCount[];
}

export interface IProductCount {
  name: string;
  count: number;
}

export interface ICostResponse {
  data: ITagCount[];
}

export interface ITagCount {
  name: string;
  total: number;
}

class DashboardClient {
  restClient!: AxiosInstance;

  constructor(restClient: AxiosInstance) {
    this.restClient = restClient;
  }

  async sales(
    from: string,
    to: string,
    groupBy: string
  ): Promise<ISalesResponse> {
    const response = await this.restClient.get(
      "/api/v1/companies/dashboard/sales",
      {
        params: {
          from,
          to,
          groupBy,
        },
      }
    );
    return response.data;
  }

  async total(from: string, to: string): Promise<ITotalResponse> {
    const response = await this.restClient.get(
      "/api/v1/companies/dashboard/total",
      {
        params: {
          from,
          to,
        },
      }
    );
    return response.data;
  }

  async cost(from: string, to: string): Promise<ITotalResponse> {
    const response = await this.restClient.get(
      "/api/v1/companies/dashboard/cost",
      {
        params: {
          from,
          to,
        },
      }
    );
    return response.data;
  }

  async income(from: string, to: string): Promise<ITotalResponse> {
    const response = await this.restClient.get(
      "/api/v1/companies/dashboard/income",
      {
        params: {
          from,
          to,
        },
      }
    );
    return response.data;
  }

  async count(from: string, to: string): Promise<ICountResponse> {
    const response = await this.restClient.get(
      "/api/v1/companies/dashboard/count",
      {
        params: {
          from,
          to,
        },
      }
    );
    return response.data;
  }

  async costByTag(from: string, to: string): Promise<ICostResponse> {
    const response = await this.restClient.get(
      "/api/v1/companies/dashboard/cost_by_tag",
      {
        params: {
          from,
          to,
        },
      }
    );
    return response.data;
  }

  async visitors(
    from: string,
    to: string,
    groupBy: string
  ): Promise<ITotalResponse> {
    const response = await this.restClient.get(
      "/api/v1/companies/dashboard/visits",
      {
        params: {
          from,
          to,
          groupBy,
        },
      }
    );
    return response.data;
  }
}

export default DashboardClient;
