import { makeAutoObservable } from "mobx";
import { remove } from "lodash";
import { productOptionGroupsClient, productOptionsClient } from "../api";
import type {
  IProductOptionGroup,
  IUpdateParams,
} from "../api/product_option_groups_client";
import ProductOption from "./product_option";
import type Product from "./product";
import {
  IProductOption,
  ICreateParams as ICreateProductOptionParams,
} from "../api/product_options_client";

class ProductOptionGroup {
  id!: number;
  product_id!: number;
  name!: string;
  selection_limit!: number;
  is_mandatory!: boolean;
  updated_at!: string;
  created_at!: string;
  product_options!: ProductOption[];
  product!: Product;

  isLoading = false;

  constructor(product: Product, params: IProductOptionGroup) {
    makeAutoObservable(this, {
      id: false,
    });

    this.product = product;
    this.refresh(params);
  }

  refresh(json: IProductOptionGroup): void {
    this.id = json.id;
    this.name = json.name;
    this.product_id = json.product_id;
    this.selection_limit = json.selection_limit;
    this.is_mandatory = json.is_mandatory;
    this.updated_at = json.updated_at;
    this.created_at = json.created_at;

    this.product_options = json.product_options.map(
      (product_option: IProductOption) =>
        new ProductOption(this, product_option)
    );
  }

  *update(
    params: IUpdateParams
  ): Generator<Promise<IProductOptionGroup>, void, IProductOptionGroup> {
    try {
      this.isLoading = true;
      const json = yield productOptionGroupsClient.update(this.id, params);
      this.refresh(json);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  delete(): void {
    this.product.deleteProductOptionGroup(this.id);
  }

  *createProductOption(
    params: ICreateProductOptionParams
  ): Generator<Promise<IProductOption>, void, IProductOption> {
    try {
      this.isLoading = true;
      const response = yield productOptionsClient.create(
        this.product_id,
        this.id,
        params
      );
      this.product_options.push(new ProductOption(this, response));
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  *deleteProductOption(
    product_option_id: number
  ): Generator<Promise<void>, void, void> {
    try {
      this.isLoading = true;

      yield productOptionsClient.delete(product_option_id);

      remove(
        this.product_options,
        (product_option) => product_option.id === product_option_id
      );
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  get key(): string {
    return `${this.id}-${this.product_id}-${this.name}-${this.created_at}`.toLowerCase();
  }

  get is_exclusive(): boolean {
    return this.selection_limit === 1 && this.is_mandatory;
  }
}

export default ProductOptionGroup;
