import * as Yup from "yup";

export const signUpSchema = Yup.object().shape({
  name: Yup.string()
    .required("validations.name.required")
    .min(2, "validations.name.short")
    .max(100, "validations.name.long"),
  email: Yup.string()
    .email("validations.email.valid")
    .required("validations.email.required")
    .min(5, "validations.email.short")
    .max(100, "validations.email.long"),
  password: Yup.string()
    .required("validations.password.required")
    .min(5, "validations.password.short")
    .max(100, "validations.password.long"),
  passwordConfirmation: Yup.string()
    .required("validations.password.required")
    .oneOf([Yup.ref("password"), null], "validations.password.match"),
});
