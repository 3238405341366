const Logo = (): JSX.Element => (
  <>
    <img
      srcSet="https://res.cloudinary.com/dnaexfddx/image/upload/f_auto,q_70,w_100/v1668909675/motion%20menu/motion_menu_logo_500_300.png 100w, https://res.cloudinary.com/dnaexfddx/image/upload/f_auto,q_70,w_300/v1668909675/motion%20menu/motion_menu_logo_500_300.png 300w"
      src="https://res.cloudinary.com/dnaexfddx/image/upload/v1668909675/motion%20menu/motion_menu_logo_500_300.png"
      sizes="(min-width: 738w) 20w, 50vw"
      alt="motion menu logo"
      style={{ maxWidth: 100 }}
    />
  </>
);

export default Logo;
