import { AxiosInstance, AxiosResponse } from "axios";
import type { ICompany } from "./profile_client";

export type ICreateResponse = ICompany;

class PlanPurchasesClient {
  restClient!: AxiosInstance;

  constructor(restClient: AxiosInstance) {
    this.restClient = restClient;
  }

  async create(order_id: string): Promise<AxiosResponse<ICreateResponse>> {
    const response = await this.restClient.post(
      "/api/v1/companies/plan_purchases",
      {
        plan_purchase: {
          order_id,
        },
      }
    );
    return response;
  }
}

export default PlanPurchasesClient;
