import { makeAutoObservable } from "mobx";
import { profileClient } from "../api";
import { ICompany } from "../api/profile_client";
import Company from "./company";

class BillingPage {
  company!: Company;

  is_loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  *load(): Generator<Promise<ICompany>, void, ICompany> {
    try {
      this.is_loading = true;
      const company = yield profileClient.show();
      this.company = new Company(company);
    } finally {
      this.is_loading = false;
    }
  }
}

export default BillingPage;
