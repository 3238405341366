import React from "react";
import { Container, Name, Price } from "./style";
import { IComponentProps } from "./types";

const RadioProductOptions: React.FC<IComponentProps> = ({
  product_options,
  onChangeCallback,
  initialValue,
}: IComponentProps) => {
  const [value, setValue] = React.useState<number>(initialValue || 0);

  return (
    <Container role="group" warn={value === 0}>
      {product_options.map((product_option) => (
        <Name key={product_option.key}>
          <input
            type={"radio"}
            name={String(product_option.product_option_group_id)}
            value={product_option.id}
            onChange={(event) => {
              onChangeCallback(Number(event.target.value));
              setValue(Number(event.target.value));
            }}
            checked={value === product_option.id}
          />
          {product_option.name}
          <Price>${product_option.price_f}</Price>
        </Name>
      ))}
    </Container>
  );
};

export default RadioProductOptions;
