import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { schema } from "./validation";
import { IComponentProps, FormValues } from "./type";
import Component from "./component";
import i18n from "../../i18n";

const ProfileFormIndex = withFormik<IComponentProps, FormValues>({
  mapPropsToValues: ({ company }: IComponentProps) => ({
    name: company.name,
    description: company.description,
    instagram: company.instagram,
    locale: company.locale,
  }),
  validationSchema: schema,
  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    const { name, description, instagram, locale } = values;
    const { t, company } = props;

    try {
      i18n.changeLanguage(locale);

      await company.update({ name, description, instagram, locale });
      setStatus(t("profile-form.success"));
      enqueueSnackbar(t("profile-form.success"), {
        variant: "success",
      });
    } catch (error) {
      setStatus(t("profile-form.failure"));
      enqueueSnackbar(t("profile-form.failure"), { variant: "error" });

      const message = error?.response.data.errors.join(", ");

      if (message.length > 0) {
        setStatus(message);
      }
    }

    setSubmitting(false);
  },
})(Component);

export default withRouter(withTranslation()(ProfileFormIndex));
