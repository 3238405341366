import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-width: 1280px;
  padding: 0 10px;
`;

export const Empty = styled.h3`
  text-align: center;
  margin-top: 80px;
`;

export const Header = styled.div`
  margin: 10px 0;
  border-radius: 8px;
  padding: 15px 25px;
  max-height: 60px;

  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.gray};

  span {
    width: 200px;
  }
`;

export const Column = styled.span`
  text-transform: capitalize;
`;

export const List = styled.div`
  height: 600px;
`;
