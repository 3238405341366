import { makeAutoObservable } from "mobx";
import { IFiltersParams } from "../api/orders_client";
import OrdersStore from "./orders_store";
import type Order from "./order";
import { IPages } from "./types";

export function createOrdersPageKey(
  page: number,
  per_page: number,
  filters: IFiltersParams
): string {
  let base = `${page}-${per_page}`;

  base += filters.state_in ? `-state_in: ${filters.state_in.join(",")}` : "";
  return base;
}

class OrderListPageStore {
  ordersStore!: OrdersStore;

  current_page = 1;

  per_page = 15;

  last_page = 1;

  filters: IFiltersParams = {};

  pages: IPages = {};

  is_loading = false;

  constructor() {
    makeAutoObservable(this);

    this.ordersStore = new OrdersStore();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  *load() {
    const { meta, ids } = yield this.ordersStore.load(
      this.current_page,
      this.per_page,
      this.filters
    );

    this.pages[
      createOrdersPageKey(this.current_page, this.per_page, this.filters)
    ] = ids;

    this.last_page = meta.last;
  }

  setCurrentPage(page: number): void {
    this.current_page = page;
    this.load();
  }

  setPerPage(per_page: number): void {
    this.per_page = per_page;
    this.load();
  }

  setFilters(filters: IFiltersParams): void {
    this.filters = filters;
    this.load();
  }

  get current_page_orders(): Order[] {
    const ids =
      this.pages[
        createOrdersPageKey(this.current_page, this.per_page, this.filters)
      ];

    if (ids === undefined) {
      return [];
    }

    return ids.reduce((array: Order[], id) => {
      const order = this.ordersStore.orders.get(String(id));
      if (order !== undefined) {
        array.push(order);
      }
      return array;
    }, []);
  }

  getOrderById(order_id: number): Order | undefined {
    return this.ordersStore.orders.get(String(order_id));
  }
}

export default OrderListPageStore;
