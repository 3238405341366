import { makeAutoObservable } from "mobx";
import { ledgersClient } from "../api";
import {
  ILedger,
  ICreateParams as ICreateLedgerParams,
  IFiltersParams,
  IIndexResponse,
} from "../api/ledgers_client";
import Ledger from "./ledger";

class LedgersStore {
  ledgers: Map<string, Ledger> = new Map();

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  *load(
    page: number,
    per_page: number,
    filters: IFiltersParams
  ): Generator<Promise<IIndexResponse>, IIndexResponse, IIndexResponse> {
    try {
      this.isLoading = true;

      const response = yield ledgersClient.index(page, per_page, filters);

      // eslint-disable-next-line
      const ids = response.ids;

      ids.forEach((id: string) => {
        this.refreshOrderFromServer(id, response.data[id]);
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  refreshOrderFromServer(key: string, json: ILedger): void {
    const foundLedger = this.ledgers.get(key);

    if (!foundLedger) {
      const ledger = new Ledger(this, json);
      this.ledgers.set(key, ledger);
    } else {
      foundLedger.refresh(json);
    }
  }

  *create(
    params: ICreateLedgerParams
  ): Generator<Promise<ILedger>, Ledger, ILedger> {
    try {
      this.isLoading = true;
      const json = yield ledgersClient.create(params);
      const ledger = new Ledger(this, json);
      this.ledgers.set(String(ledger.id), ledger);
      return ledger;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  *delete(ledger: Ledger): Generator<Promise<void>, void, void> {
    try {
      this.isLoading = true;
      yield ledgersClient.delete(ledger.id);
      this.ledgers.delete(String(ledger.id));
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default LedgersStore;
