import React from "react";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStores } from "../../contexts/store_context";
import Component from "./component";

/**
 * HOC Component
 * renders a tab components for floors. It injects callbacks to the component.
 *
 * @return floor tabs
 *
 * @test
 * it should pass floors, selectFloor, createFloor and deleteFloor
 */
const withFloors =
  (InnerComponent: any): React.FC =>
  () => {
    const stores = useStores();

    const floors = stores?.tablePageStore.floors || [];

    const selectedFloor = stores?.tablePageStore.getSelectedFloor();

    const selectFloor = React.useCallback(
      (floor_id: number) => {
        stores?.tablePageStore.selectFloor(floor_id);
      },
      [stores]
    );

    const createFloor = React.useCallback(() => {
      stores?.tablePageStore.floorsStore.create({
        name: "New Floor",
      });
    }, [stores]);

    const deleteFloor = React.useCallback(
      (floor_id: number) => {
        stores?.tablePageStore.floorsStore.remove(floor_id);
      },
      [stores]
    );

    return (
      <InnerComponent
        floors={floors}
        selectedFloor={selectedFloor}
        selectFloor={selectFloor}
        createFloor={createFloor}
        deleteFloor={deleteFloor}
      />
    );
  };

export default observer(withFloors(withTranslation()(Component)));
