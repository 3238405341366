import React from "react";
import { observer } from "mobx-react-lite";
import TableObject from "./table";
import { Container, Canvas } from "./style";
import { IComponentProps } from "./types";
import type Table from "../../stores/table";

const TablesCanvas: React.FunctionComponent<IComponentProps> = ({ tables }) => {
  return (
    <Container>
      <Canvas>
        {tables.map((table: Table) => (
          <TableObject key={table.key} table={table} />
        ))}
      </Canvas>
    </Container>
  );
};

export default observer(TablesCanvas);
