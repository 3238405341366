import React from "react";
import { profileClient } from "../api";
import Company from "../stores/company";

function useSubscribed(): [boolean] {
  const [subscribed, setSubscribed] = React.useState<boolean>(true);

  React.useLayoutEffect(() => {
    async function checkAccess() {
      const response = await profileClient.show();
      const company = new Company(response);

      setSubscribed(company.hasActivePro);
    }

    checkAccess();
  }, []);

  return [subscribed];
}

export default useSubscribed;
