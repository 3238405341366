import { makeAutoObservable } from "mobx";
import {
  IFiltersParams,
  createQueryString,
  ReviewStatus,
} from "../api/reviews_client";
import Review from "./review";
import ReviewsStore from "./reviews_store";
import { IPages } from "./types";

export function createReviewsPageKey(
  page: number,
  per_page: number,
  filters: IFiltersParams
): string {
  return createQueryString(page, per_page, filters);
}

const FEATURED_REVIEWS_PARAMS = {
  page: 1,
  per_page: 15,
  filters: {
    featured: true,
  },
};

class ReviewListPageStore {
  reviewsStore!: ReviewsStore;

  current_page = 1;

  per_page = 15;

  last_page = 1;

  filters: IFiltersParams = {
    // Default tab value is "New"
    status: ReviewStatus.not_moderated,
  };

  pages: IPages = {};

  is_loading = false;

  constructor() {
    makeAutoObservable(this);

    this.reviewsStore = new ReviewsStore();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  *load() {
    this.is_loading = true;

    const response = yield this.reviewsStore.load(
      this.current_page,
      this.per_page,
      this.filters
    );

    this.pages[
      createReviewsPageKey(this.current_page, this.per_page, this.filters)
    ] = response.ids;

    this.last_page = response.meta.last;

    this.is_loading = false;
  }

  *loadFeaturedReviews() {
    const response = yield this.reviewsStore.load(
      FEATURED_REVIEWS_PARAMS.page,
      FEATURED_REVIEWS_PARAMS.per_page,
      FEATURED_REVIEWS_PARAMS.filters
    );

    this.pages[
      createReviewsPageKey(
        FEATURED_REVIEWS_PARAMS.page,
        FEATURED_REVIEWS_PARAMS.per_page,
        FEATURED_REVIEWS_PARAMS.filters
      )
    ] = response.ids;
  }

  setCurrentPage(page: number): void {
    this.current_page = page;
    this.load();
  }

  setPerPage(per_page: number): void {
    this.per_page = per_page;
    this.load();
  }

  setFilters(filters: IFiltersParams): void {
    this.filters = filters;
    this.load();
  }

  get current_page_reviews(): Review[] {
    const ids =
      this.pages[
        createReviewsPageKey(this.current_page, this.per_page, this.filters)
      ];

    if (ids === undefined) {
      return [];
    }

    return ids.reduce((array: Review[], id) => {
      const review = this.reviewsStore.reviews.get(String(id));
      if (review !== undefined) {
        array.push(review);
      }
      return array;
    }, []);
  }

  get featured_reviews(): Review[] {
    const ids =
      this.pages[
        createReviewsPageKey(
          FEATURED_REVIEWS_PARAMS.page,
          FEATURED_REVIEWS_PARAMS.per_page,
          FEATURED_REVIEWS_PARAMS.filters
        )
      ];

    if (ids === undefined) {
      return [];
    }

    return ids.reduce((array: Review[], id) => {
      const review = this.reviewsStore.reviews.get(String(id));
      if (review !== undefined) {
        array.push(review);
      }
      return array;
    }, []);
  }
}

export default ReviewListPageStore;
