import { up } from "styled-breakpoints";
import styled from "styled-components";

export const Container = styled.div`
  max-width: 1024px;
  width: 90%;

  margin: 4em auto 0 auto;
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.colors.black};
`;

export const Description = styled.p``;

export const Divider = styled.hr`
  width: 90%;
  background: ${(props) => props.theme.colors.gray};
  height: 1px;
  border: 0;

  margin-bottom: 2em;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Column = styled.div``;

export const SettingLabel = styled.p`
  color: ${(props) => props.theme.colors.black};
  margin-top: 0;
  font-weight: bold;
  text-transform: capitalize;
`;

export const SettingDescription = styled.div`
  width: 100%;

  ${up("md")} {
    max-width: 20em;
    margin-right: 10em;
  }
`;

export const DailyReportSettingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  overflow-x: auto;
`;
