import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  borderRadius: "8px",
  colors: {
    gray: "#f5f3f4",
    grayDarker: "#a7a7a7",
    red: "#fd3a5c",
    redDarker: "#ca2e49",
    blue: "#0076ed",
    lightBlue: "#0099ff",
    blueDarker: "#0050a0",
    white: "#fff",
    black: "#111111",
  },
};

export { theme };
