import styled from "styled-components";

export const BLUE_COLOR = "#d3e5ef";
export const RED_COLOR = "#ffe2dd";

const Tag = styled.div<{ color: string; disable_hover?: boolean }>`
  width: fit-content;
  background-color: ${(props) => props.color};
  color: ${(props) => props.theme.colors.black};

  border-radius: 3px;
  padding-left: 6px;
  padding-right: 6px;
  height: 20px;

  font-size: 14px;

  :hover {
    scale: ${(props) => (props.disable_hover ? "1" : "1.1")};
    cursor: pointer;
  }
`;

export default Tag;
