import styled from "styled-components";
import { DollarCircle } from "@styled-icons/boxicons-solid";

export const Icon = styled(DollarCircle)`
  margin-right: 10px;
`;

export const Number = styled.h1``;

export const Title = styled.p`
  font-size: 20px;

  display: flex;
  align-items: center;
`;

export const Card = styled.div`
  min-width: 300px;
  margin-top: 8px;
  background-color: ${(props) => props.theme.colors.gray};
  border-radius: 8px;
  padding: 10px 30px;
`;
