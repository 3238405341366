import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Fastfood } from "@styled-icons/material-rounded/Fastfood";
import { Settings as SettingsIcon } from "@styled-icons/material-rounded/Settings";
import { BubbleChart } from "@styled-icons/material/BubbleChart";
import { FilePaper as ReceiptIcon } from "@styled-icons/remix-fill/FilePaper";
import { BarChartSquare } from "@styled-icons/boxicons-solid/BarChartSquare";
import { DollarCircle } from "@styled-icons/boxicons-solid/DollarCircle";
import { QrCode } from "@styled-icons/heroicons-solid/QrCode";
import { Wallet } from "@styled-icons/entypo/Wallet";
import { Menu as MenuIcon } from "@styled-icons/material-rounded/Menu";
import { ArrowLeftCircle } from "@styled-icons/heroicons-outline/ArrowLeftCircle";

export const Container = styled.div`
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavbarItems = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

interface ILink {
  hoverColor?: string;
  active?: number;
}

export const Divider = styled.hr`
  width: 90%;
  background: ${(props) => props.theme.colors.gray};
  height: 1px;
  border: 0;
`;

export const Button = styled(Link)<ILink>`
  border-radius: 8px;
  height: 30px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 200ms ease;
  white-space: nowrap;
  color: ${(props) =>
    props.active ? props.theme.colors.blueDarker : props.theme.colors.black};
  font-weight: 600;
  padding: 1em 1em;
  margin-right: 0.5em;

  display: flex;
  align-items: center;

  &:hover {
    background-color: ${(props) =>
      props.hoverColor ? props.hoverColor : props.theme.colors.gray};
  }

  background-color: ${(props) =>
    props.active ? props.theme.colors.gray : "transparent"};
`;

export const ButtonRight = styled.button<{ active?: boolean }>`
  border-radius: 8px;
  height: 30px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 200ms ease;
  white-space: nowrap;
  background: none;
  padding: 0;

  align-items: center;
  justify-self: center;

  color: ${(props) =>
    props.active ? props.theme.colors.grayDarker : props.theme.colors.black};
`;

export const ButtonDropdown = styled(Link)<ILink>`
  width: 100%;
  border-radius: 8px;
  height: 30px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 200ms ease;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.black};
  padding: 0.9em 0.6em;

  display: flex;
  align-items: center;

  &:hover {
    background-color: ${(props) =>
      props.hoverColor ? props.hoverColor : props.theme.colors.gray};
  }

  background-color: ${(props) =>
    props.active ? props.theme.colors.gray : "transparent"};
`;

const Icon = css`
  margin-right: 5px;
`;

export const QR = styled(QrCode)`
  ${Icon}
`;

export const MenuFood = styled(Fastfood)`
  ${Icon}
`;

export const Table = styled(BubbleChart)`
  ${Icon}
`;

export const Receipt = styled(ReceiptIcon)`
  ${Icon}
`;

export const Dashboard = styled(BarChartSquare)`
  ${Icon}
`;

export const Ledger = styled(DollarCircle)`
  ${Icon}
`;

export const Settings = styled(SettingsIcon)`
  ${Icon}
`;

export const Billing = styled(Wallet)`
  ${Icon}
`;

export const Menu = styled(MenuIcon)`
  ${Icon};
  width: 25px;
`;

export const ArrowCircleLeft = styled(ArrowLeftCircle)`
  ${Icon};
  width: 25px;
`;

export const Dropdown = styled.div<{ active?: boolean }>`
  max-width: 15em;
  min-width: 10em;
  height: auto;
  padding: 1em 0.4em;
  display: ${(props) => (props.active ? "flex" : "none")};
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  position: absolute;
  right: 0px;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  z-index: 3;
`;

export const DropdownItem = styled.div<{ hoverColor?: string }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 0.3em;

  &:hover {
    color: ${(props) => (props.hoverColor ? props.hoverColor : "black")};
  }
`;
