import styled from "styled-components";

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;

  :hover {
    cursor: text;
  }

  transition: all 200ms ease;

  input.editing,
  textarea.editing {
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray};
    font-size: inherit;
    font-weight: inherit;
    padding: 3px;
    border: none;
    border-radius: 8px;

    :focus {
      outline: none;
    }
  }

  textarea.editing {
    height: 100px;
  }

  .editable:empty:after {
    content: "empty value";
  }
`;

export default InputContainer;
