import React from "react";
import { withTranslation } from "react-i18next";
import Component from "./component";
import useValidateCredentials from "../../hooks/useValidateCredentials";
import { authClient } from "../../api";
import { IComponentProps } from "./types";
import event from "../../event";

const withValidated =
  (
    InnerComponent: React.ComponentType<
      Omit<IComponentProps, "t" | "i18n" | "tReady">
    >
  ): React.FC =>
  () => {
    const { isValidated, is_employee } = useValidateCredentials();

    const onSignOutClick = React.useCallback(async () => {
      await authClient.sign_out(is_employee);
      event.reset_identity();

      window.location.reload();
    }, []);

    return (
      <InnerComponent
        isValidated={isValidated}
        onSignOutClick={onSignOutClick}
      />
    );
  };

export default withValidated(withTranslation()(Component));
