import React from "react";
import InputCell from "../editable-cells/input";
import { Table, Header, Row, Styles, Cell } from "./style";
import { IComponentProps } from "./types";
import PaymentMethod from "../../stores/payment_method";
import BooleanCell from "../editable-cells/boolean";
import Button from "../../controls/button";
import { enqueueSnackbar } from "notistack";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  ColumnDef,
} from "@tanstack/react-table";

const PaymentMethodTable = ({
  payment_methods,
  deleteCallback,
  createCallback,
  t,
}: IComponentProps): JSX.Element => {
  const columns = React.useMemo<ColumnDef<PaymentMethod, any>[]>(
    () => [
      {
        header: t("payment-method-table.name"),
        accessorKey: "name",
        cell: ({ row, getValue }) => (
          <InputCell
            type="text"
            value={getValue()}
            updateCallback={async (value) => {
              try {
                await row.original.update({ name: value });
                enqueueSnackbar(t("payment-method-table.name-input.success"), {
                  variant: "success",
                });
              } catch (e) {
                enqueueSnackbar(t("payment-method-table.name-input.failed"), {
                  variant: "error",
                });
                throw e;
              }
            }}
          />
        ),
      },
      {
        header: t("payment-method-table.default"),
        accessorKey: "default",
        cell: ({ row, getValue }) => {
          return (
            <BooleanCell
              value={getValue()}
              updateCallback={async (value: boolean) => {
                try {
                  await row.original.update({ default: value });
                  enqueueSnackbar(
                    t("payment-method-table.default-input.success"),
                    {
                      variant: "success",
                    },
                  );
                } catch (e) {
                  enqueueSnackbar(
                    t("payment-method-table.default-input.failed"),
                    {
                      variant: "error",
                    },
                  );
                  throw e;
                }
              }}
            />
          );
        },
      },
      {
        header: t("payment-method-table.delete"),
        accessorKey: "id",
        cell: ({ getValue }) => {
          return (
            <Button
              test_id="payment-method-delete-button"
              variant="borderless"
              onClick={() => {
                if (window.confirm(t("payment-method-table.delete-confirm"))) {
                  deleteCallback(getValue());
                }
              }}
            >
              {t("payment-method-table.delete")}
            </Button>
          );
        },
      },
    ],
    [],
  );

  // // Use the state and functions returned from useTable to build your UI
  const { getCenterTotalSize, getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data: payment_methods,
    getCoreRowModel: getCoreRowModel(),
  });

  // // Render the UI for your table
  return (
    <Styles>
      <Table
        style={{
          width: getCenterTotalSize(),
        }}
      >
        <thead>
          {getHeaderGroups().map((headerGroup) => (
            <Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Header
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: header.getSize(),
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}

                  {/* Use column.getResizerProps to hook up the events correctly */}
                  {/* <Resizer
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    isResizing={header.column.getIsResizing()}
                  /> */}
                </Header>
              ))}
            </Row>
          ))}
        </thead>

        <tbody>
          {getRowModel().rows.map((row) => (
            <Row key={row.id} data-testid="payment-method-row">
              {row.getVisibleCells().map((cell) => (
                <Cell
                  key={cell.id}
                  style={{
                    width: cell.column.getSize(),
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}{" "}
                </Cell>
              ))}
            </Row>
          ))}
        </tbody>
      </Table>

      <br />
      <Button
        test_id="payment-method-create-button"
        variant="blue"
        onClick={createCallback}
      >
        {t("payment-method-table.create")}
      </Button>
    </Styles>
  );
};

export default PaymentMethodTable;
