import styled from "styled-components";

export const Container = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 550px; //to fit better the text
  height: fit-content;
  border-radius: 8px;
  padding: 2em 3em;

  background-color: ${(props) => props.theme.colors.white};
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;

  z-index: 102; //tour button has 101
`;
