import styled from "styled-components";
import { Close } from "@styled-icons/material-sharp/Close";
import { up } from "styled-breakpoints";

export const Table = styled.table`
  display: inline-block;
  border-spacing: 0;
  border: 0;
`;

export const Header = styled.th`
  position: relative;
  font-weight: normal;
  color: #37352f;
  text-transform: capitalize;
  text-align: left;

  margin: 0;
  padding: 0;
  border-bottom: 1.5px solid;
  border-color: ${(props) => props.theme.colors.gray};
`;

export const Row = styled.tr`
  :last-child {
    .td {
      border-bottom: 0;
    }
  }
`;

export const Cell = styled.td`
  margin: 0;
  padding: 0px;
  border-bottom: 1.5px solid;
  border-right: 1.5px solid;
  border-color: ${(props) => props.theme.colors.gray};

  ${
    "" /* In this example we use an absolutely position resizer,
       so this is required. */
  }
  position: relative;

  :last-child {
    border-right: 0;
  }
`;

export const Resizer = styled.div<{ isResizing: boolean }>`
  width: 0.15em;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  cursor: col-resize;
  z-index: 1;
  ${"" /* prevents from scrolling while dragging on touch devices */}
  user-select: none;

  :hover {
    background: ${(props) => props.theme.colors.blue};
  }

  background: ${(props) =>
    props.isResizing ? props.theme.colors.blue : "transparent"};
`;

export const Styles = styled.div`
  padding: 1rem;
`;

export const Modal = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  background-color: rgb(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;

  ${up("md")} {
    padding: 0 50px;
  }
`;

export const CloseIcon = styled(Close)`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.5);
`;
