import { AxiosInstance } from "axios";
import Cookies from "../lib/cookies";

class OauthClient {
  restClient!: AxiosInstance;

  constructor(restClient: AxiosInstance) {
    this.restClient = restClient;
  }

  async signInWithGoogle(id_token: string) {
    const response = await this.restClient.post(
      "/api/v1/companies/oauth/google",
      {
        id_token,
      },
    );

    Cookies.set("access-token", response.headers["access-token"], {
      expires: 3,
    });
    Cookies.set("token-type", response.headers["token-type"], {
      expires: 3,
    });
    Cookies.set("client", response.headers.client, {
      expires: 3,
    });
    Cookies.set("expiry", response.headers.expiry, {
      expires: 3,
    });
    Cookies.set("uid", response.headers.uid, {
      expires: 3,
    });

    return response.data;
  }
}

export default OauthClient;
