import React, { useEffect } from "react";
import { IComponentProps } from "./types";
import { Container } from "./style";
import useModal from "../../hooks/useModal";

const Modal: React.FC<IComponentProps> = ({
  children,
  closeModalFromChildren,
  clickOutsideDisabled,
}) => {
  const [openModal, setOpenModal, containerRef] = useModal();
  const ref: React.RefObject<HTMLDivElement> | null = clickOutsideDisabled
    ? null
    : containerRef;

  useEffect(() => {
    setOpenModal(true);
  }, []);

  useEffect(() => {
    if (closeModalFromChildren) {
      setOpenModal(false);
    }
  }, [closeModalFromChildren]);

  if (!openModal) {
    return null;
  }

  return (
    <Container ref={ref} data-testid="modal">
      {children}
    </Container>
  );
};

export default Modal;
