import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
`;

export const Button = styled.button<{ active?: boolean }>`
  background-color: ${(props) =>
    props.active ? props.theme.colors.grayDarker : props.theme.colors.gray};
  outline: none;
  border-radius: 8px;
  color: black;
  text-align: center;
  height: 40px;
  border: none;
  padding: 10px 16px;
  margin-right: 5px;
  cursor: pointer;

  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.grayDarker};
  }
`;
