import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import Component from "./component";
import withAuthorization, { ACCESS_TABLE } from "../../hocs/withAuthorization";

const REQUIRED_ACCESS = ACCESS_TABLE["order"]["view"];

export default withAuthorization(
  REQUIRED_ACCESS,
  withTranslation()(observer(Component))
);
