import React from "react";
import {
  Container,
  Title,
  Description,
  Divider,
  SettingLabel,
  SettingDescription,
  Row,
  Column,
  DailyReportSettingsContainer,
} from "./style";
import { IComponentProps } from "./types";
import ProfileFrom from "../../components/profile_form";
import DailyReportSettingItem from "../../components/daily_report_setting";
import { useStores } from "../../contexts/store_context";
import LoadingPage from "../loading_page";
import PaymentMethodTable from "../../components/payment_method_table";

const ProfilePage: React.FC<IComponentProps> = ({ t }: IComponentProps) => {
  const stores = useStores();

  React.useEffect(() => {
    // Load profile, daily report settings and payment methods
    stores?.profilePageStore.load();
  }, [stores?.profilePageStore]);

  const company = stores?.profilePageStore.company;
  const payment_methods = stores?.profilePageStore.payment_methods || [];

  const deletePaymentMethodCallback = React.useCallback(
    (id: number) => {
      stores?.profilePageStore.paymentMethodsStore.delete(id);
    },
    [stores?.profilePageStore.paymentMethodsStore]
  );

  const createPaymentMethodCallback = React.useCallback(() => {
    stores?.profilePageStore.paymentMethodsStore.create({
      name: t("profile-page.payment-methods.default-name"),
      default: false,
    });
  }, [stores?.profilePageStore.paymentMethodsStore]);

  const settings = stores?.profilePageStore.getDailyReportSettings;

  if (!company) {
    return <LoadingPage />;
  }

  return (
    <Container>
      <Title>{t("profile-page.title")}</Title>
      <Description>{t("profile-page.description")}</Description>

      <Divider />
      <ProfileFrom company={company} />

      <Divider />

      <Row>
        <Column>
          <SettingLabel>{t("profile-page.payment-methods.title")}</SettingLabel>
          <SettingDescription>
            {t("profile-page.payment-methods.description")}
          </SettingDescription>
        </Column>

        <PaymentMethodTable
          payment_methods={payment_methods}
          deleteCallback={deletePaymentMethodCallback}
          createCallback={createPaymentMethodCallback}
        />
      </Row>

      <Divider />

      <Row>
        <Column>
          <SettingLabel>{t("profile-page.daily-report.title")}</SettingLabel>
          <SettingDescription>
            {t("profile-page.payment-methods.description")}
          </SettingDescription>
          <br />
        </Column>
      </Row>

      <DailyReportSettingsContainer>
        {settings?.map((dailyReportSetting) => (
          <DailyReportSettingItem
            key={dailyReportSetting.id}
            dailyReportSetting={dailyReportSetting}
          />
        ))}
      </DailyReportSettingsContainer>
    </Container>
  );
};

export default ProfilePage;
