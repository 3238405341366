import { makeAutoObservable } from "mobx";
import { remove } from "lodash";
import type ProductsStore from "./products_store";
import type { IProduct, IUpdateParams } from "../api/products_client";
import type { IImage, IVideo } from "./types";
import ProductOptionGroup from "./product_option_group";
import { productsClient, productOptionGroupsClient } from "../api";
import { IProductOptionGroup } from "../api/product_option_groups_client";
import { ICreateParams as ICreateProductOptionGroupParams } from "../api/product_option_groups_client";

class Product {
  id!: number;

  name!: string;

  description!: string;

  stock!: number;

  published!: boolean;

  price!: number;

  code!: string;

  images!: IImage[];

  videos!: IVideo[];

  tag_list!: string[];

  priority!: number;

  created_at!: string;

  updated_at!: string;

  deleted_at?: string;

  product_option_groups!: ProductOptionGroup[];

  // Extra Properties
  store!: ProductsStore;

  isLoading = false;

  constructor(store: ProductsStore, params: IProduct) {
    makeAutoObservable(this, {
      id: false,
      store: false,
    });

    this.store = store;
    this.refresh(params);
  }

  *update(params: IUpdateParams): Generator<Promise<IProduct>, void, IProduct> {
    try {
      this.isLoading = true;
      const json = yield productsClient.update(this.id, params);
      this.store.refreshProductFromServer(String(json.id), json);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  delete(): void {
    this.store.remove(this);
  }

  refresh(json: IProduct): void {
    this.id = json.id;
    this.name = json.name;
    this.description = json.description;
    this.stock = json.stock;
    this.published = json.published;
    this.price = json.price;
    this.code = json.code;
    this.images = json.images;
    this.videos = json.videos;
    this.tag_list = json.tag_list;
    this.priority = json.priority;
    this.created_at = json.created_at;
    this.updated_at = json.updated_at;
    this.deleted_at = json.deleted_at;

    this.product_option_groups = json.product_option_groups.map(
      (product_option_group: IProductOptionGroup) =>
        new ProductOptionGroup(this, product_option_group),
    );
  }

  *createProductOptionGroup(
    params: ICreateProductOptionGroupParams,
  ): Generator<Promise<IProductOptionGroup>, void, IProductOptionGroup> {
    try {
      this.isLoading = true;
      const response = yield productOptionGroupsClient.create(this.id, params);
      this.product_option_groups.push(new ProductOptionGroup(this, response));
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  *deleteProductOptionGroup(
    product_option_group_id: number,
  ): Generator<Promise<void>, void, void> {
    try {
      this.isLoading = true;

      yield productOptionGroupsClient.delete(product_option_group_id);

      remove(
        this.product_option_groups,
        (product_option_group) =>
          product_option_group.id === product_option_group_id,
      );
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  *addImage(formData: FormData) {
    try {
      this.isLoading = true;
      const response = yield productsClient.add_image(this.id, formData);
      this.refresh(response);
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  *addVideo(formData: FormData) {
    try {
      this.isLoading = true;
      const response = yield productsClient.add_video(this.id, formData);
      this.refresh(response);
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  *removeImage(image_id: number) {
    try {
      this.isLoading = true;
      const response = yield productsClient.remove_image(this.id, image_id);
      this.refresh(response);
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  *removeVideo(video_id: number) {
    try {
      this.isLoading = true;
      const response = yield productsClient.remove_video(this.id, video_id);
      this.refresh(response);
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  get tag_names(): Set<string> {
    return new Set(this.tag_list);
  }

  get key(): string {
    return `product-${this.id}-${this.created_at}`.toLowerCase();
  }
}

export default Product;
