import React from "react";
import { reduce } from "lodash";
import { Container, Name, Info } from "./style";
import { IComponentProps } from "./types";
import RadioProductOptions from "./radio_product_options";
import CheckboxProductOptions from "./checkbox_product_options";
import { ICheckBoxState, ICheckBoxIds } from "./checkbox_product_options/types";
import { enqueueSnackbar } from "notistack";

const ProductOptionGroupForm: React.FC<IComponentProps> = ({
  item,
  product_option_group,
  t,
}: IComponentProps) => {
  const onRadioChangeCallback = async (product_option_id: number) => {
    try {
      await item.add_product_option(product_option_id);
      enqueueSnackbar(t("product-option-group-form.update-success"), {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(t("product-option-group-form.update-failure"), {
        variant: "error",
      });
    }
  };

  const onCheckboxChangeCallback = (state: ICheckBoxState) => {
    try {
      const option_id_modified = Number(state.option_id_modified);
      const checked = state.ids[Number(option_id_modified)];
      if (checked) {
        item.add_product_option(option_id_modified);
      } else {
        item.remove_product_option(option_id_modified);
      }

      enqueueSnackbar(t("product-option-group-form.update-success"), {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(t("product-option-group-form.update-failure"), {
        variant: "error",
      });
    }
  };

  const initialValuesIds: ICheckBoxIds = reduce(
    product_option_group.product_options,
    (result: ICheckBoxIds, value) => {
      result[value.id] =
        item.product_options.filter(
          (product_option) => product_option.id === value.id
        ).length > 0 || false;
      return result;
    },
    {}
  );
  return (
    <Container>
      <Name>
        {product_option_group.name}{" "}
        <Info>
          ({product_option_group.is_mandatory && "you must select "}
          {product_option_group.selection_limit} options)
        </Info>
      </Name>
      <div role="group">
        {product_option_group.is_exclusive ? (
          <RadioProductOptions
            onChangeCallback={onRadioChangeCallback}
            product_options={product_option_group.product_options}
            initialValue={
              item.productOptionsByGroupId(product_option_group.id)[0]?.id
            }
          />
        ) : (
          <CheckboxProductOptions
            onChangeCallback={onCheckboxChangeCallback}
            product_options={product_option_group.product_options}
            selection_limit={product_option_group.selection_limit}
            initialValue={{ ids: initialValuesIds }}
          />
        )}
      </div>
    </Container>
  );
};

export default ProductOptionGroupForm;
