import React from "react";
import i18n from "../../i18n";
import { Container, Button } from "./style";

const LanguageSelect: React.FC = () => {
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Container>
      <Button
        type="button"
        active={i18n.language === "en"}
        onClick={() => {
          changeLanguage("en");
        }}
      >
        EN
      </Button>
      <Button
        type="button"
        active={i18n.language === "es"}
        onClick={() => {
          changeLanguage("es");
        }}
      >
        ES
      </Button>
      <Button
        type="button"
        active={i18n.language === "ko"}
        onClick={() => {
          changeLanguage("ko");
        }}
      >
        KO
      </Button>
    </Container>
  );
};

export default LanguageSelect;
