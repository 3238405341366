import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { withTranslation } from "react-i18next";
import { useStores } from "../../../contexts/store_context";
import Component from "./component";
import { IComponentProps } from "./types";

function inject(InnerComponent: React.FC<IComponentProps>) {
  const OuterComponent = (props: Omit<IComponentProps, "onClick">) => {
    const { table } = props;
    const stores = useStores();

    const onClick = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        stores?.tablePageStore.selectTable(table.id);
      },
      [stores, table]
    );

    return <InnerComponent {...props} onClick={onClick} />;
  };

  return OuterComponent;
}

export default withTranslation()(inject(observer(Component)));
