import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || "", {
  debug: process.env.REACT_APP_MIXPANEL_DEBUG_MODE == "true",
});

export const ACCOUNT_TYPE = {
  admin: "admin",
  employee: "employee",
  user: "user",
};

export const EVENT_PREFIX = "admin";

export default {
  identify: (id: string) => {
    mixpanel.identify(id);
  },
  alias: (id: string) => {
    mixpanel.alias(id);
  },
  track: (name: string, options?: any) => {
    mixpanel.track(`${EVENT_PREFIX}.${name}`, options);
  },
  people: {
    set: (options: any) => {
      mixpanel.people.set(options);
    },
    increment: (event_name: string) => {
      mixpanel.people.increment(`${EVENT_PREFIX}.${event_name}`);
    },
  },
  reset_identity: () => {
    mixpanel.reset();
  },
};
