import React from "react";
import { RIEInput } from "riek";
import { Plus } from "@styled-icons/heroicons-outline/Plus";
import InputContainer from "../../../controls/inline_input";
import OrderForm from "../../order_form";
import LoadingSpinner from "../../../controls/loading_spinner";
import {
  Container,
  Title,
  OrderContainer,
  OrderButton,
  Dots,
  EmptyTitle,
  EmptyDescription,
} from "./style";
import Dropdown from "./dropdown";
import { IComponentProps } from "./types";

const TableObject = ({
  table,
  referenceCallback,
  selected,
  onClick,
  t,
}: IComponentProps): JSX.Element => {
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);

  const onClickNewOrder = React.useCallback(() => {
    table.createOrder();
  }, [table]);

  const renderOrder = table.order ? (
    <OrderForm order={table.order} />
  ) : (
    <OrderContainer>
      <EmptyTitle>{t("table-list.is-empty.title")}</EmptyTitle>
      <EmptyDescription>
        {t("table-list.is-empty.description")}
      </EmptyDescription>
      <OrderButton onClick={onClickNewOrder} className="new-order-button">
        <Plus size={15} /> {t("table-list.new-order-button")}
      </OrderButton>
    </OrderContainer>
  );

  const isLoading = table.isLoading || table.order?.isLoading;

  return (
    <Container
      ref={referenceCallback}
      isLoading={isLoading}
      className="table-list-item"
      selected={selected}
      onClick={onClick}
    >
      <Title>
        <InputContainer>
          <RIEInput
            value={table.name}
            change={({ name }: { name: string }) => table.update({ name })}
            propName="name"
            classEditing="editing"
            className="table-name-input"
            data-table-id={table.id}
          />
        </InputContainer>
        {isLoading && <LoadingSpinner />}

        <Dots
          className="table-menu"
          size={30}
          onClick={() => setIsDropdownVisible(!isDropdownVisible)}
          data-table-id={table.id}
        />
      </Title>
      {isDropdownVisible && <Dropdown table={table} />}
      {renderOrder}
    </Container>
  );
};

export default TableObject;
