import { makeAutoObservable } from "mobx";
import type FloorsStore from "./floors_store";
import { IFloor, IUpdateParams } from "../api/floors_client";
import { floorsClient } from "../api";

class Floor {
  id!: number;

  name!: string;

  created_at!: string;

  updated_at!: string;

  // Extra Properties
  store!: FloorsStore;

  isLoading = false;

  constructor(store: FloorsStore, params: IFloor) {
    makeAutoObservable(this, {
      id: false,
      store: false,
    });

    this.store = store;
    this.refresh(params);
  }

  *update(params: IUpdateParams): Generator<Promise<IFloor>, void, IFloor> {
    try {
      this.isLoading = true;
      const json = yield floorsClient.update(this.id, params);
      this.refresh(json);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  delete(): void {
    this.store.remove(this.id);
  }

  refresh(json: IFloor): void {
    this.id = json.id;
    this.name = json.name;
    this.created_at = json.created_at;
    this.updated_at = json.updated_at;
  }

  get key(): string {
    return `${this.name}-${this.id}-${this.created_at}`.toLowerCase();
  }
}

export default Floor;
