import React from "react";
import {
  Link as ReactRouterLink,
  useLocation,
  Redirect,
} from "react-router-dom";
import { Container, LeftContainer, Header, Description } from "./style";
import { IComponentProps } from "./types";
import Logo from "../../controls/logo";

const ResetPasswordConfirmationPage: React.FC<IComponentProps> = ({ t }) => {
  const query = useLocation().search;

  return (
    <Container>
      <LeftContainer>
        <Logo />

        <Header>{t("reset-password-confirmation-page.title")}</Header>
        <Description>
          {t("Go back to")}{" "}
          <ReactRouterLink to="/">{t("home")}</ReactRouterLink>
        </Description>

        <Redirect
          to={{
            pathname: "/reset_password/edit",
            search: query,
          }}
        />
      </LeftContainer>
    </Container>
  );
};

export default ResetPasswordConfirmationPage;
