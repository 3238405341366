import styled from "styled-components";
import { down } from "styled-breakpoints";
import { motion } from "framer-motion";

export const Container = styled.div`
  margin: auto;
  margin-top: 7em;
  max-width: 1440px;

  display: flex;
  justify-content: space-around;

  ${down("md")} {
    margin-top: 2em;
    flex-wrap: wrap;
  }
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.colors.black};
  font-size: 2.4em;
  font-weight: bold;
`;

export const Description = styled.p`
  max-width: 37em;
`;

export const FeaturedColumn = styled.div`
  max-width: 400px;
  padding: 0 1em;

  ${down("md")} {
    max-width: initial;
  }
`;

export const Vertical = styled.div`
  max-width: 768px;
  padding: 0 1em;
`;

export const FeaturedCount = styled.p`
  font-weight: bold;
  font-size: 1em;
`;

export const TabContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: fit-content;

  button {
    margin-right: 0.5em;
  }
`;

export const Tab = styled.button<{ active?: boolean }>`
  display: block;
  background-color: ${(props) =>
    props.active ? props.theme.colors.gray : "transparent"};
  color: ${(props) => props.theme.colors.blueDarker};
  border: none;
  outline: none;
  border-radius: 12px;
  padding: 10px 20px;
  cursor: pointer;

  font-family: inherit;
  font-weight: bold;
  text-transform: capitalize;

  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray};
  }
`;

export const ReviewList = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 1em;
  list-style-type: none;
  padding: 0;

  div {
    margin-bottom: 1em;
  }

  button {
    margin-bottom: 1em;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;

  width: fit-content;

  button {
    margin-right: 1em;
    text-transform: capitalize;
  }
`;

export const NoReviews = styled(motion.div)`
  text-align: center;
  max-width: 300px;
  margin: 1em auto;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1em;
`;

export const PageCount = styled.p`
  display: flex;
  justify-content: flex-end;
  font-family: "Nunito Sans";
  font-size: 0.6em;

  color: ${(props) => props.theme.colors.black};
`;
