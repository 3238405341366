import { makeAutoObservable } from "mobx";
import { tablesClient } from "../api";
import { ITable, ICreateParams } from "../api/tables_client";
import Table from "./table";
import type OrdersStore from "./orders_store";
import Order from "./order";
import type {
  IOrder,
  ICreateParams as ICreateOrderParams,
} from "../api/orders_client";

class TablesStore {
  tables: Map<number, Table> = new Map();

  isLoading = false;

  ordersStore!: OrdersStore;

  constructor(ordersStore: OrdersStore) {
    makeAutoObservable(this, {
      ordersStore: false,
    });

    this.ordersStore = ordersStore;
  }

  *load(): Generator<Promise<ITable[]>, void, ITable[]> {
    this.isLoading = true;
    try {
      const fetchedTables = yield tablesClient.all();
      fetchedTables.forEach((json: ITable) =>
        this.refreshTableFromServer(json)
      );
    } finally {
      this.isLoading = false;
    }
  }

  refreshTableFromServer(json: ITable): void {
    const foundTable = this.tables.get(json.id);

    if (!foundTable) {
      const table = new Table(this, json);
      this.tables.set(json.id, table);
    } else {
      foundTable.refreshFromJson(json);
    }
  }

  *create(params: ICreateParams): Generator<Promise<ITable>, void, ITable> {
    try {
      const json = yield tablesClient.create(params);
      const table = new Table(this, json);
      this.tables.set(table.id, table);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  *remove(table: Table): Generator<Promise<void>, void, void> {
    try {
      yield tablesClient.delete(table.id);
      this.tables.delete(table.id);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  *createOrder(params: ICreateOrderParams): Generator<unknown, IOrder, IOrder> {
    return yield this.ordersStore.create(params);
  }

  // Relations
  resolveOrder(order_id: number): Order | undefined {
    return this.ordersStore.orders.get(String(order_id));
  }
}

export default TablesStore;
