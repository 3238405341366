import { AxiosInstance } from "axios";

export interface IPaymentMethod {
  id: number;
  name: string;
  discarded_at: string | null;
  created_at: string;
  updated_at: string;
  default: boolean;
}

export interface ICreatePaymentMethodParams {
  name: string;
  default: boolean;
}

export type IUpdatePaymentMethodParams = Partial<
  Partial<ICreatePaymentMethodParams>
>;

export interface IPaymentMethodsIndexResponse {
  data: {
    [key: string]: IPaymentMethod;
  };
}

class PaymentMethodsClient {
  restClient!: AxiosInstance;

  constructor(restClient: AxiosInstance) {
    this.restClient = restClient;
  }

  async index(): Promise<IPaymentMethodsIndexResponse> {
    const response = await this.restClient.get(
      "/api/v1/companies/payment_methods"
    );
    return response.data;
  }

  async create(body: ICreatePaymentMethodParams): Promise<IPaymentMethod> {
    const response = await this.restClient.post(
      "/api/v1/companies/payment_methods",
      body
    );
    return response.data;
  }

  async update(
    id: IPaymentMethod["id"],
    body: IUpdatePaymentMethodParams
  ): Promise<IPaymentMethod> {
    const response = await this.restClient.put(
      `/api/v1/companies/payment_methods/${id}`,
      body
    );
    return response.data;
  }

  async delete(id: IPaymentMethod["id"]): Promise<void> {
    await this.restClient.delete(`/api/v1/companies/payment_methods/${id}`);
  }
}

export default PaymentMethodsClient;
