import React from "react";
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from "recharts";
import { Container, Title, Description, ChartContainer } from "./style";
import { IComponentProps } from "./types";
import { sample } from "lodash";
import { dashboardClient } from "../../../api";
import type { ITagCount } from "../../../api/dashboard_client";

const CostTag: React.FC<IComponentProps> = ({ range, t }) => {
  const [data, setData] = React.useState<ITagCount[]>([]);

  const colors = [
    "#7FB285",
    "#55D6BE",
    "#ACFCD9",
    "#7D5BA6",
    "#E49273",
    "#FC6471",
    "#fab3a9",
  ];

  React.useEffect(() => {
    async function loadData() {
      const response = await dashboardClient.costByTag(
        range.from.toISOString(),
        range.to.toISOString()
      );
      setData(response.data);
    }

    loadData();
  }, [range]);

  return (
    <Container>
      <Title>{t("costTag-title")}</Title>
      <Description>{t("costTag-description")}</Description>

      <ChartContainer>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={400}>
            <Pie
              dataKey="total"
              isAnimationActive={false}
              data={data}
              cx="50%"
              cy="50%"
              outerRadius={130}
              fill="#8884d8"
              label
            >
              {data.map(() => (
                <Cell fill={sample(colors)} />
              ))}
            </Pie>
            ;
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </ChartContainer>
    </Container>
  );
};

export default CostTag;
