import { GoogleLogin } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import event from "../../event";
import { oauthClient } from "../../api";
import { useHistory } from "react-router-dom";

const GoogleSignInButton = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <GoogleLogin
      size="large"
      useOneTap
      onSuccess={async (result) => {
        event.track("google-sign-in-button.submit");

        if (result.credential) {
          const response = await oauthClient.signInWithGoogle(
            result.credential
          );

          enqueueSnackbar(t("google-sign-in-button.success"), {
            variant: "success",
          });

          event.identify(response.email);
          event.track("google-sign-in-button.success");

          history.push("/products");
        }
      }}
      onError={() => {
        event.track("google-sign-in-button.failure");
        enqueueSnackbar(t("google-sign-in-button.failure"), {
          variant: "error",
        });
      }}
    />
  );
};

export default GoogleSignInButton;
