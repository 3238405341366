import styled from "styled-components";

export const Container = styled.div`
  padding: 10px;
`;

export const Title = styled.h1``;

export const Description = styled.p``;

export const ChartContainer = styled.div`
  margin-top: 20px;
  height: 350px;
`;
