import { useState, useEffect, useCallback } from "react";
import { Textarea } from "./style";
import { IComponentProps } from "./types";

const TextareaCell = ({
  value: initial_value,
  updateCallback,
  palceholder,
  max_length,
}: IComponentProps) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initial_value);

  const onChange = (e: { target: { value: string } }) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = useCallback(async () => {
    if (initial_value === value) {
      return; // Skip if value is unchanged
    }

    try {
      await updateCallback(value);
    } catch (e) {
      // When update fails, revert to the original values
      setValue(initial_value);
    }
  }, [value]);

  const onKeyDown = useCallback(
    (event: { key: string }) => {
      if (event.key === "Enter") {
        try {
          updateCallback(value);
        } catch (e) {
          // When update fails, revert to the original values
          setValue(initial_value);
        }
      }
    },
    [value]
  );

  // If the initial_value is changed external, sync it up with our state
  useEffect(() => {
    setValue(initial_value);
  }, [initial_value]);

  return (
    <Textarea
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      placeholder={palceholder}
      rows={1}
      maxLength={max_length}
    />
  );
};

export default TextareaCell;
