import React from "react";
import { DraggableContainer, DraggableItem, Index } from "./styles";
import { IComponentProps } from "./types";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";

const DraggableList: React.FC<IComponentProps> = ({
  items,
  onDragEnd,
  loading = false,
}: IComponentProps) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <DraggableContainer
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {items?.map(({ value, displayed_value }, index) => (
              <Draggable
                key={`${value}-${index}`}
                draggableId={`${value}-${index}`}
                index={index}
              >
                {(
                  draggableProvided: DraggableProvided,
                  draggableSnapshot: DraggableStateSnapshot
                ) => (
                  <DraggableItem
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    style={draggableProvided.draggableProps.style}
                    isDragging={draggableSnapshot.isDragging}
                  >
                    <Index>{index + 1}.</Index>
                    <span>{displayed_value}</span>
                  </DraggableItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </DraggableContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
