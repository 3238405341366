import React from "react";
import { observer } from "mobx-react";
import { RIEInput } from "riek";
import { IComponentProps } from "./types";
import {
  Container,
  Title,
  Button,
  Plus,
  Menu,
  Dropdown,
  DropdownItem,
} from "./style";
import InputContainer from "../../controls/inline_input";
import useDropdown from "../../hooks/useDropdown";

const FloorsTabs: React.FC<IComponentProps> = ({
  floors,
  selectedFloor,
  selectFloor,
  createFloor,
  deleteFloor,
  t,
}) => {
  const [openDropdown, setOpenDropDown, containerRef] = useDropdown();

  const Tabs = floors.map((floor) => (
    <Button
      className="floor-tab-button"
      key={floor.key}
      active={selectedFloor?.id === floor.id}
      disabled={selectedFloor?.id === floor.id}
      onClick={() => {
        selectFloor(floor.id);
      }}
      data-floor-id={floor.id}
    >
      {floor.name}
    </Button>
  ));

  const deleteButtons =
    floors.length > 1 ? (
      floors.map((floor) => (
        <DropdownItem
          key={floor.key}
          onClick={() => {
            if (window.confirm(`${t("floors-tabs.delete")} ${floor.name}?`)) {
              deleteFloor(floor.id);
              selectFloor(floors.filter((f) => f.id !== floor.id)[0].id);
            }
          }}
          hoverColor="#e63946"
          className="delete-floor-button"
          data-floor-id={floor.id}
        >
          {t("Delete")} {floor.name}
        </DropdownItem>
      ))
    ) : (
      <DropdownItem>{t("No actions")}</DropdownItem>
    );

  return (
    <Container>
      {selectedFloor && (
        <Title>
          <InputContainer id="floor-name-input">
            <RIEInput
              value={selectedFloor?.name}
              change={({ name }: { name: string }) =>
                selectedFloor?.update({ name })
              }
              propName="name"
              classEditing="editing"
              className="editable"
              data-floor-id={selectedFloor?.id}
            />
          </InputContainer>
        </Title>
      )}

      <div>
        {Tabs}

        <Button
          id="create-floor-button"
          onClick={() => {
            createFloor();
          }}
        >
          <Plus />
        </Button>

        <div style={{ display: "inline-block" }} ref={containerRef}>
          <Button
            id="delete-floor-menu"
            onClick={() => {
              setOpenDropDown((prev) => !prev);
            }}
          >
            <Menu />
          </Button>
          <Dropdown active={openDropdown}>{deleteButtons}</Dropdown>
        </div>
      </div>
    </Container>
  );
};

export default observer(FloorsTabs);
