import { IComponentProps } from "./types";
import { Modal, CloseIcon } from "./style";

const ProductModal = ({
  children,
  closeCallback,
}: IComponentProps): JSX.Element => {
  return (
    <Modal>
      <CloseIcon onClick={closeCallback} />
      {children}
    </Modal>
  );
};

export default ProductModal;
