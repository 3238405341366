import React from "react";
import Moment from "react-moment";
import { RIETextArea, RIENumber, RIEToggle, RIETags } from "riek";
import InputContainer from "../../../controls/inline_input";
import { Container, Column, Button } from "./style";
import { IComponentProps } from "./types";

const LedgerRow: React.FC<IComponentProps> = ({
  index,
  ledger,
  t,
}: IComponentProps) => {
  return (
    <Container className="ledger-list-item">
      <Column>{index}</Column>
      <Column>
        <RIEToggle
          value={ledger.category === "income" ? true : false}
          change={({ category }: { category: boolean }) =>
            ledger.update({ category: category ? "income" : "cost" })
          }
          propName="category"
          classEditing="editing"
          textTrue={t("income")}
          textFalse={t("cost")}
          className="editable ledger-category-input"
        />
      </Column>
      <Column>
        <InputContainer>
          <RIETags
            value={ledger.tag_list_set}
            change={({ tag_set }: { tag_set: Set<string> }) => {
              ledger.update({ tag_list: Array.from(tag_set).join(", ") });
            }}
            propName="tag_set"
            classEditing="editing"
            className="editable ledger-tags-input"
          />
        </InputContainer>
      </Column>
      <Column>
        <RIETextArea
          value={ledger.description}
          change={({ description }: { description: string }) =>
            ledger.update({ description })
          }
          propName="description"
          classEditing="editing"
          className="editable ledger-description-textarea"
        />
      </Column>
      <Column>
        $
        <RIENumber
          value={ledger.total_f}
          change={({ total }: { total: number }) => ledger.update({ total })}
          step="0.01"
          min="0"
          max="99999"
          propName="total"
          classEditing="editing"
          className="editable ledger-total-input"
        />
      </Column>
      <Column>
        <Moment>{ledger.created_at}</Moment>
      </Column>
      <Column>
        <Button
          onClick={() => {
            if (window.confirm(t("Are you sure?"))) {
              ledger.delete();
            }
          }}
          hoverColor="#e63946"
          className="ledger-delete-button"
        >
          {t("delete")}
        </Button>
      </Column>
    </Container>
  );
};

export default LedgerRow;
