import { makeAutoObservable } from "mobx";
import moment from "moment";
import ProductsStore from "./products_store";
import type Product from "./product";
import Company from "./company";
import { productsClient, profileClient } from "../api";
import { ICompany } from "../api/profile_client";

class ProductListPageStore {
  productsStore!: ProductsStore;

  search_query = "";

  company!: Company;

  product_tags = [];

  is_loading = false;

  constructor() {
    makeAutoObservable(this);

    this.productsStore = new ProductsStore();
  }

  load(): void {
    this.productsStore.loadAll();
    this.loadCompany();
    this.loadProductTags();
  }

  *loadCompany(): Generator<Promise<ICompany>, void, ICompany> {
    const company = yield profileClient.show();
    this.company = new Company(company);
  }

  *loadProductTags() {
    const product_tags = yield productsClient.all_tags();
    this.product_tags = product_tags.data;
  }

  setSearchQuery(search_query: string): void {
    this.search_query = search_query;
  }

  get products(): Product[] {
    const products = Array.from(this.productsStore.products.values());

    const filtered = products.filter((product) =>
      product.name.toLowerCase().includes(this.search_query.toLowerCase())
    );

    const sorted = filtered.sort((a, b) => {
      return -moment(a.created_at).diff(b.created_at);
    });

    return sorted;
  }

  getProductById(product_id: string): Product | undefined {
    return this.productsStore.products.get(product_id);
  }

  get getProductTags() {
    return this.product_tags;
  }
}

export default ProductListPageStore;
