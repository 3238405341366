// stores/daily_report_settings_store.ts

import { makeAutoObservable } from "mobx";
import {
  IDailyReportSetting,
  ICreateDailyReportSettingParams,
  IDailyReportSettingsIndexResponse,
} from "../api/daily_report_settings_client";
import { dailyReportSettingsClient } from "../api";
import DailyReportSetting from "./daily_report_setting";

class DailyReportSettingsStore {
  daily_report_settings: Map<string, DailyReportSetting> = new Map();
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  *load(): Generator<
    Promise<IDailyReportSettingsIndexResponse>,
    void,
    IDailyReportSettingsIndexResponse
  > {
    try {
      this.isLoading = true;
      const response = yield dailyReportSettingsClient.index();
      const daily_report_settings_array: IDailyReportSetting[] = Object.values(
        response.data
      );

      daily_report_settings_array.forEach((setting: IDailyReportSetting) => {
        this.refreshFromServer(setting.id, setting);
      });
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  refreshFromServer(id: number, json: IDailyReportSetting): void {
    const key = String(id);
    const found = this.daily_report_settings.get(key);

    if (!found) {
      const new_setting = new DailyReportSetting(this, json);
      this.daily_report_settings.set(key, new_setting);
    } else {
      found.refresh(json);
    }
  }

  *create(
    params: ICreateDailyReportSettingParams
  ): Generator<Promise<IDailyReportSetting>, void, IDailyReportSetting> {
    try {
      this.isLoading = true;
      const json = yield dailyReportSettingsClient.create(params);
      this.refreshFromServer(json.id, json);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default DailyReportSettingsStore;
