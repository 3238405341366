import { makeAutoObservable } from "mobx";
import type Order from "./order";
import {
  IItem,
  IDeleteResponse,
  IAddProductOptionResponse,
  IRemoveProductOptionResponse,
  IUpdateParams,
  IUpdateResponse,
} from "../api/items_client";
import { itemsClient } from "../api";
import { IProductOption } from "../api/product_options_client";

class Item {
  id!: number;

  product_id!: number;

  product_name!: string;

  product_code!: string;

  order_id!: number;

  name!: string;

  product_options!: IProductOption[];

  total_f!: number;

  quantity!: number;

  aasm_state!: string;

  created_at!: string;

  updated_at!: string;

  // Extras Properties
  order!: Order;

  isLoading = false;

  constructor(order: Order, params: IItem) {
    makeAutoObservable(this, {
      id: false,
    });

    this.order = order;
    this.refresh(params);
  }

  refresh(json: IItem): void {
    this.id = json.id;
    this.product_id = json.product_id;
    this.product_name = json.product_name;
    this.product_code = json.product_code;
    this.order_id = json.order_id;
    this.quantity = json.quantity;
    this.name = json.name;
    this.total_f = json.total_f;
    this.aasm_state = json.aasm_state;
    this.created_at = json.created_at;
    this.updated_at = json.updated_at;
    this.product_options = json.product_options;
  }

  *update(
    params: IUpdateParams
  ): Generator<Promise<IUpdateResponse>, IUpdateResponse, IUpdateResponse> {
    try {
      this.isLoading = true;

      const response = yield itemsClient.update(this.id, params);
      this.order.refresh(response.order);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  *delete(): Generator<Promise<IDeleteResponse>, void, IDeleteResponse> {
    try {
      this.isLoading = true;

      const response = yield itemsClient.delete(this.id);
      this.order.refresh(response.order);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  *setQuantity(quantity: number): any {
    try {
      this.isLoading = true;

      if (quantity < 1) {
        this.delete();
      } else {
        this.update({ quantity });
      }
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  *add_product_option(
    product_option_id: number
  ): Generator<
    Promise<IAddProductOptionResponse>,
    void,
    IAddProductOptionResponse
  > {
    try {
      this.isLoading = true;

      const response = yield itemsClient.add_product_option(
        this.id,
        product_option_id
      );
      this.order.refresh(response.order);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  *remove_product_option(
    product_option_id: number
  ): Generator<
    Promise<IRemoveProductOptionResponse>,
    void,
    IRemoveProductOptionResponse
  > {
    try {
      this.isLoading = true;

      const response = yield itemsClient.remove_product_option(
        this.id,
        product_option_id
      );
      this.order.refresh(response.order);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  get key(): string {
    return `${this.id}-${this.order_id}-${this.product_id}-${this.created_at}`;
  }

  productOptionsByGroupId(product_option_group_id: number): IProductOption[] {
    return this.product_options.filter(
      (product_option) =>
        product_option.product_option_group_id === product_option_group_id
    );
  }
}

export default Item;
