import * as Yup from "yup";

export const ledgerSchema = Yup.object().shape({
  billed_at: Yup.string().required("Please specify when it is billed"),
  category: Yup.string().required("category is required"),
  description: Yup.string()
    .required("Item description is required")
    .min(3, "Add description of the item"),
  tag_list: Yup.array(),
  total: Yup.number().required("total is required").moreThan(0),
});
