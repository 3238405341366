import styled from "styled-components";
import { up, down } from "styled-breakpoints";
import { Link as ReactRouterLink } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  ${up("md")} {
    align-items: center;
    height: 90%;
  }
`;

export const LeftContainer = styled.div`
  padding: 15px;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 8px;

  width: 100%;
  max-width: 25em;
`;

export const Divider = styled.hr`
  width: 100%;
  background: ${(props) => props.theme.colors.gray};
  height: 1px;
  border: 0;
`;

export const Header = styled.h1`
  font-size: 49px;
`;

export const Description = styled.p``;

export const Link = styled(ReactRouterLink)`
  width: fit-content;
  display: block;
  background-color: ${(props) => props.theme.colors.blue};
  color: ${(props) => props.theme.colors.white};
  outline: none;
  border-radius: 8px;
  padding: 15px 30px;
  text-decoration: none;
  cursor: pointer;

  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.blueDarker};
  }
`;

export const SignOut = styled.a`
  width: fit-content;
  display: block;
  background-color: ${(props) => props.theme.colors.red};
  color: #fff;
  outline: none;
  border-radius: 8px;
  padding: 15px 30px;
  text-decoration: none;
  cursor: pointer;

  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.red};
  }
`;

export const BoardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  ${down("lg")} {
    display: none !important;
  }
`;

export const Board = styled.iframe`
  border: none;
  border-radius: 8px;

  width: 1024px;
  height: 500px;
`;
