import { enqueueSnackbar } from "notistack";
import Compressor from "compressorjs";
import { IComponentProps } from "./types";
import ImageForm from "../../../product_list/product/image_form";

const ImageOptions = ({ product, t }: IComponentProps): JSX.Element => {
  const onDrop = (acceptedFiles: any) => {
    enqueueSnackbar(t("product-list.product.upload.loading"), {
      variant: "info",
    });

    acceptedFiles.forEach((file: any) => {
      const uploadContent = async () => {
        const formData = new FormData();

        if (file.type === "video/mp4") {
          try {
            formData.append("file", file);
            await product.addVideo(formData);
            enqueueSnackbar(t("product-list.product.upload.success"), {
              variant: "success",
            });
          } catch (error) {
            enqueueSnackbar(t("product-list.product.upload.failed"), {
              variant: "error",
            });
          }
        } else {
          new Compressor(file, {
            quality: 0.7,
            // mimeType: "jpg",
            maxWidth: 800,
            maxHeight: 800,
            // The compression process is asynchronous,
            // which means you have to access the `result` in the `success` hook function.
            success: async (result) => {
              try {
                formData.append("file", result);
                await product.addImage(formData);
                enqueueSnackbar(t("product-list.product.upload.success"), {
                  variant: "success",
                });
              } catch (error) {
                enqueueSnackbar(t("product-list.product.upload.failed"), {
                  variant: "error",
                });
              }
            },
            error(err) {
              console.error(err.message);
              enqueueSnackbar(t("product-list.product.upload.failed"), {
                variant: "error",
              });
            },
          });
        }
      };

      uploadContent();
    });
  };

  const onImageRemove = (image_id: number) => {
    product.removeImage(image_id);
  };

  const onVideoRemove = (video_id: number) => {
    product.removeVideo(video_id);
  };

  return (
    <ImageForm
      product_name={product.name}
      images={product.images}
      videos={product.videos}
      onDrop={onDrop}
      onImageRemove={onImageRemove}
      onVideoRemove={onVideoRemove}
    />
  );
};

export default ImageOptions;
