import React from "react";
import { FormikProps } from "formik";
import { FormValues, IComponentProps } from "./type";
import {
  FormWrapper,
  Input,
  Label,
  Button,
  Error,
  Row,
  InputDescription,
  Textarea,
} from "./style";
import LoadingSpinner from "../../controls/loading_spinner";

const Form: React.FunctionComponent<
  FormikProps<FormValues> & IComponentProps
> = (props: FormikProps<FormValues> & IComponentProps) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    status,
    t,
  } = props;

  return (
    <FormWrapper>
      <p>{t(status)}</p>
      <form onSubmit={handleSubmit} id="profile-form">
        <Row>
          <InputDescription>
            <Label>{t("profile-form.name.title")}</Label>
            <p>{t("profile-form.name.description")}</p>
            {errors.name && touched.name && <Error>{t(errors.name)}</Error>}
          </InputDescription>

          <Input
            type="text"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            placeholder={t("profile-form.name.title")}
          />
        </Row>

        <Row>
          <InputDescription>
            <Label>{t("profile-form.description.title")}</Label>
            <p>{t("profile-form.description.description")}</p>
            {errors.description && touched.description && (
              <Error>{t(errors.description)}</Error>
            )}
          </InputDescription>

          <Textarea
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            placeholder={t("description")}
          />
        </Row>

        <Row>
          <InputDescription>
            <Label>{t("profile-form.instagram.title")}</Label>
            {errors.instagram && touched.instagram && (
              <Error>{t(errors.instagram)}</Error>
            )}
          </InputDescription>

          <Input
            type="text"
            name="instagram"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.instagram}
            placeholder="Instagram"
          />
        </Row>

        <Row>
          <InputDescription>
            <Label>{t("profile-form.locale.title")}</Label>
            {errors.locale && touched.locale && (
              <Error>{t(errors.locale)}</Error>
            )}
          </InputDescription>

          <select
            name="locale"
            value={values.locale}
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ display: "block" }}
          >
            <option value="en" label="en">
              en
            </option>
            <option value="es" label="es">
              es
            </option>
            <option value="ko" label="ko">
              ko
            </option>
          </select>
        </Row>

        <Button
          type="submit"
          disabled={
            isSubmitting ||
            !!(errors.name && touched.name) ||
            !!(errors.description && touched.description) ||
            !!(errors.instagram && touched.instagram)
          }
        >
          {isSubmitting ? <LoadingSpinner /> : t("profile-form.submit")}
        </Button>
      </form>
    </FormWrapper>
  );
};

export default Form;
