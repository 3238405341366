import styled from "styled-components";
import { Close } from "@styled-icons/material-sharp/Close";
import { up } from "styled-breakpoints";

export const Modal = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  background-color: rgb(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;

  ${up("md")} {
    padding: 0 50px;
  }
`;

export const CloseIcon = styled(Close)`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.5);
`;
