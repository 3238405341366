import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { sample } from "lodash";
import { Container, Title, Description, ChartContainer } from "./style";
import { IComponentProps } from "./types";
import { dashboardClient } from "../../../api";
import type { IProductCount } from "../../../api/dashboard_client";

const colors = [
  "#fec5bb",
  "#fae1dd",
  "#d8e2dc",
  "#ffe5d9",
  "#fec89a",
  "#ffc6ff",
  "#e8e8e4",
];

const Count: React.FC<IComponentProps> = ({ range, t }) => {
  const [data, setData] = React.useState<IProductCount[]>([]);

  React.useEffect(() => {
    async function loadData() {
      const response = await dashboardClient.count(
        range.from.toISOString(),
        range.to.toISOString()
      );

      setData(response.data);
    }

    loadData();
  }, [range]);

  return (
    <Container>
      <Title>{t("count-title")}</Title>
      <Description>{t("count-description")}</Description>

      <ChartContainer>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#ffc6ff">
              {data.map((_entry: unknown, index: number) => (
                <Cell key={`cell-${index}`} fill={sample(colors)} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </ChartContainer>
    </Container>
  );
};

export default Count;
