import React from "react";
import Moment from "react-moment";
import { map } from "lodash";
import { Container, Column } from "./style";
import { IComponentProps } from "./types";

const OrderRow: React.FC<IComponentProps> = ({
  index,
  order,
}: IComponentProps) => {
  const items = map(
    order.items,
    (item) => `${item.product_name} ${item.quantity}`
  ).join(", ");

  return (
    <Container>
      <Column>{index}</Column>
      <Column>{order.name}</Column>
      <Column>{items}</Column>
      <Column>{order.discount_percentage}</Column>
      <Column>{order.subtotal}</Column>
      <Column>{order.total}</Column>
      <Column>{order.aasm_state}</Column>
      <Column>
        <Moment>{order.created_at}</Moment>
      </Column>
    </Container>
  );
};

export default OrderRow;
