import styled from "styled-components";

export const Table = styled.table`
  display: inline-block;
  border-spacing: 0;
  border: 0;
`;

export const Header = styled.th`
  font-weight: normal;
  color: #37352f;
  text-transform: capitalize;
  text-align: left;

  margin: 0;
  padding: 0;
  border-bottom: 1.5px solid;
  border-color: ${(props) => props.theme.colors.gray};
`;

export const Row = styled.tr`
  :last-child {
    .td {
      border-bottom: 0;
    }
  }
`;

export const Cell = styled.td`
  margin: 0;
  padding: 0;
  border-bottom: 1.5px solid;
  border-right: 1.5px solid;
  border-color: ${(props) => props.theme.colors.gray};

  ${
    "" /* In this example we use an absolutely position resizer,
       so this is required. */
  }
  position: relative;

  :last-child {
    border-right: 0;
  }
`;

export const Resizer = styled.div<{ isResizing: boolean }>`
  display: inline-block;
  width: 0.15em;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  ${"" /* prevents from scrolling while dragging on touch devices */}
  touch-action:none;

  :hover {
    background: ${(props) => props.theme.colors.blue};
  }

  background: ${(props) =>
    props.isResizing ? props.theme.colors.blue : "transparent"};
`;

export const Styles = styled.div`
  padding: 1rem;
`;
