import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../contexts/store_context";
import Component from "./component";

const TablesCanvasIndex = () => {
  const stores = useStores();

  const tables = stores?.tablePageStore.tables || [];
  const selectedFloorId = stores?.tablePageStore.selectedFloorId;

  const filtered = tables.filter((table) => table.floor_id === selectedFloorId);

  return selectedFloorId ? <Component tables={filtered} /> : null;
};

export default observer(TablesCanvasIndex);
