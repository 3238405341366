import React from "react";
import LedgerRow from "./ledger_row";
import { Container, Header, Column, List, Empty } from "./style";
import { IComponentProps } from "./types";

const LedgerList: React.FC<IComponentProps> = ({
  ledgers,
  t,
}: IComponentProps) => {
  return (
    <Container>
      <Header>
        <Column>{t("index")}</Column>
        <Column>{t("category")}</Column>
        <Column>{t("tags")}</Column>
        <Column>{t("description")}</Column>
        <Column>{t("total")}</Column>
        <Column>{t("created")}</Column>
        <Column>{t("delete")}</Column>
      </Header>

      {ledgers.length < 1 && <Empty>{t("no-result")}</Empty>}

      <List>
        {ledgers.map((ledger, index) => (
          <LedgerRow key={ledger.key} index={index + 1} ledger={ledger} />
        ))}
      </List>
    </Container>
  );
};

export default LedgerList;
