import styled from "styled-components";
import { down } from "styled-breakpoints";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;

  ${down("md")} {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

export const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${down("md")} {
    margin-left: 10px;
    flex-direction: column;
  }
`;

export const PaginationContainer = styled.div`
  min-width: 300px;
`;

export const Header = styled.div`
  max-width: 350px;
  margin: 0 35px;

  ${down("md")} {
    margin: 0px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 0;
`;

export const Description = styled.p``;

export const ListContainer = styled.div`
  width: 100%;
  max-width: 1280px;

  ${down("md")} {
    padding: 0px;
  }
`;
