import React, { useCallback } from "react";
import {
  Container,
  FeaturedColumn,
  Vertical,
  Title,
  Description,
  FeaturedCount,
  TabContainer,
  Tab,
  ReviewList,
  NoReviews,
  ButtonContainer,
  PaginationContainer,
  PageCount,
} from "./style";
import { useStores } from "../../contexts/store_context";
import { IComponentProps } from "./types";
import ReviewCard from "../../controls/review_card";
import { ReviewStatus } from "../../api/reviews_client";
import Button from "../../controls/button";
import { motion, AnimatePresence } from "framer-motion";
import Pagination from "../../components/pagination";
import Review from "../../stores/review";
import { enqueueSnackbar } from "notistack";
import event from "../../event";

// Review card list animations
const list_variants = {};

const item_variants = {
  hidden: { x: -4, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
};

const ReviewListPage: React.FC<IComponentProps> = ({ t }: IComponentProps) => {
  const stores = useStores();

  React.useEffect(() => {
    stores?.reviewListPageStore.load();
    stores?.reviewListPageStore.loadFeaturedReviews();
    event.people.increment("review_list_page.view_count");
    event.track("review_list_page.view");
  }, [stores?.reviewListPageStore]);

  const current_page_reviews =
    stores?.reviewListPageStore.current_page_reviews || [];
  const featured_reviews = stores?.reviewListPageStore.featured_reviews || [];
  const is_loading = stores?.reviewListPageStore.is_loading || false;

  // Review status tabs
  const onTabClick = React.useCallback(
    async (status: ReviewStatus) => {
      event.track("review_list_page.tab.click", { status });
      stores?.reviewListPageStore.setFilters({ status });
    },
    [stores?.reviewListPageStore]
  );

  // Pagination
  const onPageClick = React.useCallback(
    async (page: number) => {
      stores?.reviewListPageStore.setCurrentPage(page);
    },
    [stores?.reviewListPageStore]
  );

  const onPublishButtonClick = useCallback(
    (review: Review) => async () => {
      const previous_status = review.status;
      review.update({
        status: ReviewStatus.published,
      });
      event.track("review_list_page.review-card.publish");

      stores?.reviewListPageStore.load();

      enqueueSnackbar(t("review-list-page.notification.published"), {
        action: undoSnackbarAction(async () => {
          review.update({
            status: previous_status,
          });

          stores?.reviewListPageStore.load();
        }, t("undo")),
        variant: "success",
      });
    },
    [stores?.reviewListPageStore]
  );

  const onHiddenButtonClick = useCallback(
    (review: Review) => async () => {
      const previous_status = review.status;
      const previous_featured = review.featured;

      await review.update({ status: ReviewStatus.hidden, featured: false });
      event.track("review_list_page.review-card.hide");

      stores?.reviewListPageStore.load();
      stores?.reviewListPageStore.loadFeaturedReviews();

      enqueueSnackbar(t("review-list-page.notification.hidden"), {
        action: undoSnackbarAction(async () => {
          await review.update({
            status: previous_status,
            featured: previous_featured,
          });

          stores?.reviewListPageStore.load();
          stores?.reviewListPageStore.loadFeaturedReviews();
        }, t("undo")),
        variant: "success",
      });
    },
    [stores?.reviewListPageStore]
  );

  const onFeaturedButtonClick = useCallback(
    (review: Review) => async () => {
      try {
        const previous_status = review.status;

        await review.update({
          status: ReviewStatus.published,
          featured: true,
        });
        event.track("review_list_page.review-card.feature");

        stores?.reviewListPageStore.load();
        stores?.reviewListPageStore.loadFeaturedReviews();

        enqueueSnackbar(t("review-list-page.notification.featured"), {
          action: undoSnackbarAction(async () => {
            await review.update({
              featured: false,
              status: previous_status,
            });
            stores?.reviewListPageStore.load();
            stores?.reviewListPageStore.loadFeaturedReviews();
          }, t("undo")),
          variant: "success",
        });
      } catch (e) {
        console.error(e);
        enqueueSnackbar(t("review-list-page.notification.error"), {
          variant: "error",
        });
      }
    },
    [stores?.reviewListPageStore]
  );

  let no_reviews_translation_key = "no-new-reviews";
  switch (stores?.reviewListPageStore.filters.status) {
    case ReviewStatus.not_moderated:
      no_reviews_translation_key = "no-new-reviews";
      break;
    case ReviewStatus.published:
      no_reviews_translation_key = "no-published-reviews";
      break;
    case ReviewStatus.hidden:
      no_reviews_translation_key = "no-hidden-reviews";
      break;
  }

  // Add undo action to review status update buttons
  const undoSnackbarAction =
    (actionCallback: () => void, children: string) => () =>
      (
        <a
          style={{
            textTransform: "capitalize",
            cursor: "pointer",
            marginRight: "0.5em",
          }}
          onClick={actionCallback}
        >
          {children}
        </a>
      );

  return (
    <Container>
      <FeaturedColumn>
        <Title>{t("review-list-page.featured.title")}</Title>
        <Description>{t("review-list-page.featured.description")}</Description>

        <FeaturedCount data-testid="featured-reviews-count">
          {t("review-list-page.featured.count", {
            count: featured_reviews.length,
          })}
        </FeaturedCount>
        <AnimatePresence>
          {featured_reviews.map((featured_review) => (
            <motion.div
              key={featured_review.key}
              initial="hidden"
              animate="visible"
              variants={item_variants}
              exit={{ opacity: 0.1, x: 10 }}
              transition={{ duration: 0.2 }}
            >
              <ReviewCard
                title={featured_review.user.name}
                body={featured_review.body}
                datetime={featured_review.created_at}
                test_id="featured-review-card"
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="borderless"
                  onClick={async () => {
                    featured_review.update({
                      status: ReviewStatus.published,
                      featured: false,
                    });
                    stores?.reviewListPageStore.load();
                    stores?.reviewListPageStore.loadFeaturedReviews();
                  }}
                  test_id="featured-review-cancel-button"
                >
                  {t("review-list-page.featured.cancel-button")}
                </Button>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </FeaturedColumn>
      <Vertical>
        <Title>{t("review-list-page.reviews.title")}</Title>
        <Description>{t("review-list-page.reviews.description")}</Description>

        <TabContainer>
          <Tab
            onClick={() => {
              onTabClick(ReviewStatus.not_moderated);
            }}
            active={
              stores?.reviewListPageStore.filters.status ==
              ReviewStatus.not_moderated
            }
            data-testid="review-new-tab"
          >
            {t("review-list-page.tab.new")}
          </Tab>
          <Tab
            onClick={() => {
              onTabClick(ReviewStatus.published);
            }}
            active={
              stores?.reviewListPageStore.filters.status ==
              ReviewStatus.published
            }
            data-testid="review-published-tab"
          >
            {t("review-list-page.tab.published")}
          </Tab>
          <Tab
            onClick={() => {
              onTabClick(ReviewStatus.hidden);
            }}
            active={
              stores?.reviewListPageStore.filters.status == ReviewStatus.hidden
            }
            data-testid="review-hidden-tab"
          >
            {t("review-list-page.tab.hidden")}
          </Tab>
        </TabContainer>

        <PaginationContainer>
          <Pagination
            current_page={stores?.reviewListPageStore.current_page || 1}
            last_page={stores?.reviewListPageStore.last_page || 1}
            onPageClick={onPageClick}
            variant="borderless"
            data-testid="review-pagination"
          />
        </PaginationContainer>

        <PageCount>{current_page_reviews.length} reviews</PageCount>

        {current_page_reviews.length > 0 && (
          <ReviewList
            initial="hidden"
            animate="visible"
            variants={list_variants}
            transition={{ staggerChildren: 0.2 }}
          >
            {current_page_reviews.map((review) => (
              <AnimatePresence key={review.key}>
                <motion.li
                  variants={item_variants}
                  exit={{ opacity: 0.1, x: 10 }}
                >
                  <ReviewCard
                    title={review.user.name}
                    body={review.body}
                    datetime={review.created_at}
                    test_id="review-card"
                  />

                  <ButtonContainer>
                    {review.status != ReviewStatus.published && (
                      <Button
                        variant="blue"
                        onClick={onPublishButtonClick(review)}
                        test_id="review-publish-button"
                      >
                        {t("review-list-page.action.publish")}
                      </Button>
                    )}

                    {review.status != ReviewStatus.hidden && (
                      <Button
                        variant="gray"
                        onClick={onHiddenButtonClick(review)}
                        test_id="review-hide-button"
                      >
                        {t("review-list-page.action.hide")}
                      </Button>
                    )}

                    {!review.featured && (
                      <Button
                        variant="gray"
                        onClick={onFeaturedButtonClick(review)}
                        test_id="review-featured-button"
                      >
                        {t("review-list-page.action.featured")}
                      </Button>
                    )}
                  </ButtonContainer>
                </motion.li>
              </AnimatePresence>
            ))}
          </ReviewList>
        )}

        {is_loading == false && current_page_reviews.length == 0 && (
          <NoReviews
            initial={{ y: 10, opacity: 0.3, scale: 0.9 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
          >
            {t(`review-list-page.reviews.${no_reviews_translation_key}`)}
          </NoReviews>
        )}
      </Vertical>
    </Container>
  );
};

export default ReviewListPage;
