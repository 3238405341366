import { AxiosInstance } from "axios";

export interface IDailyReportSetting {
  id: number;
  day: number;
  duration: string;
  closes_at: string;
  active: boolean;
  created_at: string;
  updated_at: string;
}

type Day =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export interface ICreateDailyReportSettingParams {
  day: Day;
  active: IDailyReportSetting["active"];
  closes_at: IDailyReportSetting["closes_at"];
  duration: IDailyReportSetting["duration"];
}

export type IUpdateDailyReportSettingParams = Partial<
  Partial<ICreateDailyReportSettingParams>
>;

export interface IDailyReportSettingsIndexResponse {
  data: {
    [key: string]: IDailyReportSetting;
  };
  ids: string[];
}

class DailyReportSettingsClient {
  restClient!: AxiosInstance;

  constructor(restClient: AxiosInstance) {
    this.restClient = restClient;
  }

  async index(): Promise<IDailyReportSettingsIndexResponse> {
    const response = await this.restClient.get(
      "/api/v1/companies/daily_report_settings"
    );

    return response.data;
  }

  async create(
    body: ICreateDailyReportSettingParams
  ): Promise<IDailyReportSetting> {
    const response = await this.restClient.post(
      "/api/v1/companies/daily_report_settings",
      {
        daily_report_setting: body,
      }
    );
    return response.data;
  }

  async update(
    id: IDailyReportSetting["id"],
    body: IUpdateDailyReportSettingParams
  ): Promise<IDailyReportSetting> {
    const response = await this.restClient.put(
      `/api/v1/companies/daily_report_settings/${id}`,
      {
        daily_report_setting: body,
      }
    );
    return response.data;
  }

  async show(id: IDailyReportSetting["id"]): Promise<IDailyReportSetting> {
    const response = await this.restClient.get(
      `/api/v1/companies/daily_report_settings/${id}`
    );
    return response.data;
  }

  async trigger(id: IDailyReportSetting["id"]) {
    const response = await this.restClient.post(
      `/api/v1/companies/daily_report_settings/${id}/trigger`
    );
  }
}

export default DailyReportSettingsClient;
