import { AxiosInstance } from "axios";
import { IOrder } from "./orders_client";
import { IProductOption } from "./product_options_client";

export interface IItem {
  id: number;
  product_id: number;
  product_name: string;
  product_code: string;
  quantity: number;
  order_id: number;
  name: string;
  aasm_state: string;
  total_f: number;
  product_options: IProductOption[];
  created_at: string;
  updated_at: string;
}

export interface ICreateParams {
  product_id: number;
  quantity: number;
}

export interface IUpdateParams {
  quantity: number;
}

export interface ICreateResponse {
  data: IItem;
  order: IOrder;
}

export type IUpdateResponse = ICreateResponse;
export type IAddProductOptionResponse = ICreateResponse;
export type IRemoveProductOptionResponse = ICreateResponse;

export type IDeleteResponse = { order: IOrder };

class ItemsClient {
  restClient!: AxiosInstance;

  constructor(restClient: AxiosInstance) {
    this.restClient = restClient;
  }

  async create(
    order_id: number,
    body: ICreateParams
  ): Promise<ICreateResponse> {
    const response = await this.restClient.post(
      `/api/v1/companies/orders/${order_id}/items`,
      {
        item: body,
      }
    );

    return response.data;
  }

  async update(id: number, params: IUpdateParams): Promise<IUpdateResponse> {
    const response = await this.restClient.put(
      `/api/v1/companies/items/${id}`,
      {
        item: params,
      }
    );

    return response.data;
  }

  async delete(id: number): Promise<IDeleteResponse> {
    const response = await this.restClient.delete(
      `/api/v1/companies/items/${id}`
    );

    return response.data;
  }

  async add_product_option(
    id: number,
    product_option_id: number
  ): Promise<IAddProductOptionResponse> {
    const response = await this.restClient.put(
      `/api/v1/companies/items/${id}/add_product_option`,
      { product_option_id }
    );

    return response.data;
  }

  async remove_product_option(
    id: number,
    product_option_id: number
  ): Promise<IRemoveProductOptionResponse> {
    const response = await this.restClient.put(
      `/api/v1/companies/items/${id}/remove_product_option`,
      { product_option_id }
    );

    return response.data;
  }
}

export default ItemsClient;
