import React from "react";
import { WithTranslation } from "react-i18next";
import { Container, Button, Item, Title, Price, Card } from "./style";
import { PRICE } from "../../pages/billing_page/component";

const SubscriptionModal: React.FC<WithTranslation> = ({ t }) => {
  return (
    <Container>
      <Card
        initial={{ scale: 0.9, opacity: 0.7 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeIn" }}
      >
        <Title>{t("profile-page.pro.title")}</Title>

        <Price>$ {PRICE}</Price>

        <ul>
          <Item>{t("plan.video")}</Item>
          <Item>{t("plan.dashboard")}</Item>
          <Item>{t("plan.orders")}</Item>
          <Item>{t("plan.tables")}</Item>
          <Item>{t("plan.ledgers")}</Item>
        </ul>

        <Button to="/billing">{t("plan.upgrade")}</Button>
      </Card>
    </Container>
  );
};

export default SubscriptionModal;
