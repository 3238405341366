import { AxiosInstance } from "axios";

export interface IAccess {
  id: number;
  category: string;
  description: string;
  value: number;
}

interface IAccessesResponse {
  accesses: IAccess[];
}

class AccessesClient {
  restClient!: AxiosInstance;

  constructor(restClient: AxiosInstance) {
    this.restClient = restClient;
  }

  async index(): Promise<IAccessesResponse> {
    const response = await this.restClient.get("/api/v1/companies/accesses");
    return response.data;
  }
}

export default AccessesClient;
