export default {
  translation: {
    "a day ago": "Hace un día",
    active: "Activado",
    "already-subscribed": "Ya estás subscripto",
    analytics: "Análisis",
    "Are you sure?": "¿Estás seguro?",
    Billing: "Planes",
    "billing-page": {
      description:
        "La visiblidad de los productos y la marca se incrementa con un buen contenido. Puedes cancelar la subscripción en cualquier momento.",
      title: "Crea un espacio para conectarse con sus clientes",
    },
    Cancel: "Cancelar",
    category: "Categoría",
    category: "Categoría",
    "Check your email account, we have sent you a confirmation email. You should first activate your account before start using Motion Menu.":
      "Mira la cuenta de correo electrónico, hemos enviado un email de confirmación. Necesitas confirmar tu cuenta para comenzar a usar Motion Menu.",
    "check-email-page": {
      description:
        "Hemos enviado un email para la confirmación. Para empezar, tiene que activar la cuenta a través del email.",
      "sign-in": "¿Has activado la cuenta?",
      title: "¡Gracias! Ya te sumaste a Motion Menu",
    },
    "click here": "Haz click aquí",
    code: "codigo",
    Complete: "Completar",
    content: "Contenido",
    cost: "costos",
    Cost: "Costos",
    "costTag-description":
      "Analice qué categoría representa más gastos para su negocio",
    "costTag-title": "Costos por etiquetas",
    "count-description": "Los productos más vendidos en las fechas elegidas.",
    "count-title": "Productos más populares",
    Created: "Creado",
    created: "creado",
    Created: "Creado",
    created: "Creado",
    Dashboard: "Dashboard",
    "dashboard-page": {
      description:
        "Vires In Numeris. Estamos mejorando esta página constantemente. Hay que medir y comparar los números para mejorar la calidad del servicio y de los productos.",
      title: "¡Analiza tu negocio!",
    },
    day: {
      0: "Lunes",
      1: "Martes",
      2: "Miércoles",
      3: "Jueves",
      4: "Viernes",
      5: "Sábado",
      6: "Domingo",
    },
    Delete: "Remover",
    Description: "Descripción",
    description: "Descripción",
    Dicsount: "Descuento",
    "digital-menu": "Carta digital",
    "digital-menu-page": {
      description:
        "No es suficiente tener la carta en papel. Necesitas la carta digital para atraer al cliente y vender mejor tus productos. Agrégalos a la carta y prueba Motion Menu.",
      "menu-page-link": "¡Visite el sitio con su menú",
      "product-priority-description":
        "Puedes mover productos con contenido hacia arriba para resaltar.",
      "qr-link": "Haz click en el código QR y descargue e imprima la imagen.",
      "sort-by-products": "Ordernar por productos",
      "sort-by-tags": "Ordenar por etiquetas",
      "tag-priority-description":
        "Puedes cambiar el orden de los productos moviendo las etiquetas. Tiene que asignar las etiquetas primero. Los productos sin etiquetas se muestran al final de la carta.",
      "tag-priority-title": "¡Puedes cambiar el orden de los productos!",
      title: "Atraiga la atención con su carta digital",
    },
    discount: "descuento",
    "discount total": "total del descuento",
    "Do you have an account already?": "¿Ya tienes una cuenta?",
    "dont-have-account": "¿No tienes una cuenta?",
    "Drop it here!": "¡Déjalo aquí!",
    "dropzone content": "Haz click para seleccionar archivos",
    "editable-cells": {
      boolean: {
        false: "falso",
        true: "verdadero",
      },
      tag: {
        available: "Disponible",
        empty: "Asignar etiqueta",
        input: "Escribe una etiqueta",
        new: "Crear nueva",
        selected: "Seleccionado",
      },
    },
    email: "Email",
    "empty value": "Vacío",
    "empty-tables": "¡No hay mesas!",
    "Example Table": "Mesa Nº",
    "Failed to reset password":
      "Hubo un error al mandar el mail para resetear la contraseña",
    "Failed to sign in": "Hubo un problema al ingresar",
    "Failed to sign up":
      "Hubo un problema. Por favor, mande un mail a qmfkdldks@gmail.com",
    "Failed to update password": "Hubo un error al actualizar la contraseña",
    "File type not accepted, sorry!": "¡No aceptamos ese tipo de archivo!",
    "First click new order to fill with items":
      "Click en el botón de abajo para crear nuevo pedido",
    "floors-tabs": {
      delete: "¿Quieres remover el piso",
    },
    "Forgot your password?": "¿Olvidaste la contraseña?",
    "forgot-password": "¿No recuerdas tu contraseña?",
    "forgot-password": "¿Olvidaste la contraseña?",
    friday: "Viernes",
    "Go back to": "Volver a",
    "Go To Store": "Ingresar",
    "google-sign-in-button": {
      failure: "Hubo un error al ingresar",
      success: "Ingresaste con éxito",
    },
    "got-suspended": "La subscripción está pausada",
    "Have you activated your account?":
      "¿Activaste tu cuenta? Haz click por favor",
    here: "Aquí",
    hidden: "Oculto",
    home: "La página principal",
    Images: "Imágenes",
    inactive: "Desactivado",
    income: "Ingresos",
    Income: "Ingresos",
    index: "Indice",
    "item-quantity-modal": {
      description: "Cuantos quieres agregar?",
      submit: "Confirmar",
    },
    "ledger-form": {
      "billed-at": "Facturado en",
      bread: "pan",
      coca: "coca",
      created: "Has creado un registro",
      electricity: "electricidad",
      failed: "No se pudo crear el registro",
      ham: "jamon",
      material: "material",
      milk: "leche",
      salary: "sueldo",
      soda: "soda",
      submit: "Crear",
      suger: "azúcar",
    },
    "ledger-list-page": {
      description: "ledger description",
      title: "¡Bienvenido!",
    },
    "ledger-list-page": {
      description:
        "Saber los costos y los ingresos es muy importante al realizar el análisis de su negocio. Siempre trata de crear registros. Primero elija la categoría ingreso o costo y marca todos las etiquetas para poder revisar los registros en un futuro.",
      title: "¡Los ingresos y costos más recientes!",
    },
    Ledgers: "Registros",
    Menu: "Carta",
    monday: "Lunes",
    month: "Mes",
    name: "Nombre",
    Name: "Nombre",
    navbar: {
      billing: "Facturación",
      dashboard: "Dashboard",
      "digital-menu": "Menú digital",
      ledgers: "Cuentas",
      orders: "Pedidos",
      products: "Productos",
      profile: "Perfil",
      "sign-out": "Cerrar sesión",
      tables: "Mesas",
    },
    new: "Nuevo",
    "New Order": "Nueva orden",
    "New Product": "Nuevo producto",
    "No actions": "Sin acciones",
    "No products found": "No hay productos. Por favor crea un nuevo producto.",
    "no-confirmation-email": "¿No recibiste el email de confirmación?",
    "no-result": "No hay ningún resultado",
    options: "Opciones",
    "order-form": {
      cancel: "Cancelar",
      complete: "Completar",
      "complete-failed": "No pudo completarse",
      completed: "El pedido está completo",
      discount: "Descuento",
      item: {
        update: {
          failure: "no pudo actualizar el ítem",
          success: "el ítem fue actualizado",
        },
      },
      "no-items": "No hay ítems",
      print: "Imprimir",
      "print-code": "Imprimir código",
      subtotal: "Subtotal",
      total: "Total",
    },
    "order-list": {
      created: "Creado",
      dicsount: "Descuento",
      index: "Indice",
      items: "Ítems",
      name: "Nombre",
      "no-result": "No hay ningún resultado",
      status: "Estado",
      subtotal: "Subtotal",
      total: "Total",
    },
    "order-list-page": {
      description:
        "Cambia el rango de los días para encontrar más pedidos. Cada botón trae las ordenes en las fechas seleccionadas.",
      title: "Puede encontrar pedidos completos y cancelados",
    },
    Orders: "Pedidos",
    password: "Contraseña",
    "password-confirmation": "Confirmación de la contraseña",
    "payment-method-table": {
      create: "Crear",
      default: "Por defecto?",
      "default-input": {
        failed: "No se pudo actualizar",
        success: "El método de pago por defecto fue actualizado",
      },
      delete: "Remover",
      "delete-confirm": "Estás seguro?",
      name: "Nombre",
      "name-input": {
        failed: "El nombre fue invalido.",
        success: "El nombre fue actualizado",
      },
    },
    plan: {
      dashboard: "Analizar el negocio y las ventas",
      "digital-menu": "Generar código QR para la carta digital",
      ledgers: "Registrar costos e ingresos",
      orders: "Crear pedidos e imprimir ticket",
      products: "Crear productos y subir imágenes",
      tables: "Adminitrar =mesas",
      upgrade: "Actualizar",
      video: "Subir videos del producto",
    },
    "Please wait for redirection": "Espera la redirección",
    Price: "Precio",
    Print: "Imprimir",
    "product-list": {
      column: {
        code: "Código",
        created: "Creado",
        delete: "Remover",
        description: "Descripción",
        edit: "Editar",
        images: "Imagenes",
        name: "Nombre",
        options: "Opciones",
        price: "Precio",
        published: "Publicado",
        tag: "Tag",
      },
      "image-form": {
        "maximum-image":
          "El máximo tamaño del archivo es 5 MB. Las imágenes van a estar en la carta digital. Si subiste varias imágenes, los usuarios pueden hacer click para ver las otras imágenes.",
        "maximum-videos":
          "El máximo tamaño es 2 MB. El video va a ser publicado en la carta digital. El formato recomendado es MP4.",
        "no-images-found": "No hay imagenes",
        "no-videos-found": "No hay videos",
        remove: "Remove",
        upgrade: {
          description: "Puedes ganar todos los accesso con la subscripción",
          title: "Subscribir",
        },
        "uploaded-images": "Imagenes subidos",
        "uploaded-videos": "Videos subidos",
      },
      "no-products-found": "No hay productos",
      product: {
        created: "Creado",
        delete: "Remover",
        images: "Imagenes",
        options: "Opciones",
        upload: {
          failed: "Hubo un error",
          loading: "Subiendo...",
          success: "Ha subida con éxito",
        },
      },
    },
    "product-option-group-form": {
      "empty-options": "Está vacío. Agrega opciones",
      "update-failure": "Hubo un error",
      "update-success": "Opción actualizada",
    },
    "product-option-groups-form": {
      "create-group": "Crear grupo",
      description:
        "Puede crear variantes y opciones del producto. Por ejemplo, hamburguesa peude tener papa fritas y coca. Un grupo tiene varias opciones inclusivas o exclusivas según el límite. Las opciones pueden ser obligatorias y además pueden tener precios adicionales.",
      "edit-me": "Edítame",
      "is-mandatory": "Es obligatorio?",
      "max-groups-limit": "Puedes agregar máximo {{limit}} grupos",
      "max-options-limit": "Puedes agregar máximo {{limit}} opciones",
      "new-option": "Nueva opción",
      "selection-limit": "El usuario puede elegir",
      title: "Opciones del producto",
    },
    "product-options": {
      "new-group": "Nuevo grupo",
    },
    "product-table": {
      code: "Codigo",
      created: "Creado",
      delete: {
        button: "Eliminar",
        confirm: "Quieres eliminar el producto?",
        failure: "El producto no pudo ser eliminado",
        success: "El producto fue eliminado",
        title: "Eliminar {{count}}",
      },
      description: {
        placeholder: "Agrega una descripción",
        title: "Descripción",
      },
      edit: "Editar",
      images: "Imagenes",
      name: "Nombre",
      options: "Opciones",
      price: "Precio",
      published: "Publicado",
      tags: "Tags",
      update: {
        failure: "No se pudo actualizar el producto",
        success: "El producto fue actualizado",
      },
    },
    products: "Productos",
    "products-page": {
      "5-new-product-button": "+5 Nuevos productos",
      "5-new-product-button": "+5 Nuevos productos",
      description:
        "Crea y modifica los productos, puedes ocultarlos también. Los productos creados aquí aparecerán instantaneamente en la carta digital.",
      "new-product-button": "Nuevo producto",
      steps: {
        1: "¡Presiona el botón para crear un nuevo producto! No es necesario poner el nombre o el precio.",
        2: "Edita el nombre directamente. Podrás editarlo al hacer click sobre el texto. Aparecerá un editor",
        3: "(Opcional) el código es el indentificador interno del producto. No es visible a los consumidores. (ejemplo) PD-12",
        4: "La descripción será visible para los consumidores a través de la carta digital. Describe el producto y agrega más información.",
        5: "El precio deberá tener como máximo dos decimales ($0,00)",
        6: "Haciendo click aquí puedes publicar o esconder el producto en la carta digital. Podrás agregar el producto al pedido aunque esté oculto.",
        7: "Agrega etiquetas para categorizar el producto (ej.: almuerzo, bebida o café). Los productos en la carta digital se organizan por etiquetas. La etiqueta puede ser también utilizada para la análitica.",
        8: "Crea las opciones del producto (Ej.: hamburguesas y sus variantes). Puedes manejar las variantes y las opciones aquí.",
        9: "Agrega imágenes y videos. Las imágenes son muy importantes en la carta digital. El máximo tamaño del archivo es de 5 MB. Mientras más ligeros sean los archivos más rápido se mostrarán en la carta.",
        10: "Puedes eliminar el producto haciendo click aquí.",
      },
      title: "Registra sus productos, imagenes y videos",
    },
    Profile: "Perfil",
    "profile-form": {
      description: {
        description:
          "La descripción corta del negocio. Aparecerá en la carta digital.",
        "description-long": "La descripción es larga",
        title: "Descripción del negocio",
      },
      failure: "No se pudo actualizar la información",
      instagram: "Instagram",
      "instagram-long": "El url es largo",
      locale: {
        title: "Idioma",
      },
      name: {
        description:
          "Ingrese el nombre del negocio. Nos construimos URLs con este nombre.",
        "name-short": "El nombre es muy corto",
        title: "Nombre del negocio",
      },
      Profile: "Perfíl",
      submit: "Actualizar",
      success: "El perfíl fue actualizado",
      tag_priority: "La prioridad en la carta digital",
    },
    "profile-page": {
      "daily-report": {
        "active-error": "No se pudo actualizar el reporte diario",
        "active-updated":
          "El reporte diario fue actualizado satisfactoriamente",
        "close-time-error": "No se pudo actualizar la hora de cierre",
        "close-time-label": "El negocio cierra a las",
        "close-time-updated": "La hora de cierre fue actualizada",
        "duration-error": "No se pudo actualizar la duración",
        "duration-label-1": "Recoge las últimas",
        "duration-label-2": "horas de datos",
        "duration-updated": "La duración fue actualizada",
        info: "El reporte diario será enviado {{day}} {{sent_time}} y contendrá datos desde {{start_time}} hasta {{end_time}}",
        title: "Configuración del reporte diario",
        trigger: {
          failure: "No se pudo enviar el reporte diario",
          success: "El reporte diario fue enviado",
          title: "Testear",
        },
      },
      description: "Actualiza el perfil y configuración",
      free: {
        description: "Uso limitado.",
        title: "14 días de prueba",
      },
      "payment-methods": {
        create: "Crear",
        "default-name": "Nuevo",
        description:
          "Puedes agregar o remover los métodos del pago. El método por defecto va a ser asignado a los nuevos pedidos.",
        title: "Métodos de pago",
      },
      pro: {
        description:
          "Subscríbete al mejor plan para tu negocio. Armamos la carta digital que transforma la experiencia del cliente.",
        title: "Sin límites",
      },
      title: "Configuración",
    },
    Published: "Publicado",
    published: "Publicado",
    "re-send-confirmation-email": "Reenviar",
    reactivate: "Reactivar",
    "resend-confirmation-email-form": {
      failure:
        "Hubo un error, por favor contacta al equipo del soporte por chat o email.",
      success: "¡Hemos mandado el email para la confirmación!",
    },
    "resend-confirmation-email-page": {
      title: "Enviar email de confirmación",
    },
    "reset-password": "Resetear la contraseña",
    "reset-password-confirmation-page": {
      title: "Por favor espera un momento",
    },
    "reset-password-form": {
      submit: "Resetear la contraseña",
    },
    "reset-password-page": {
      title: "¿Olvidaste la contraseña?",
    },
    "review-list-page": {
      action: {
        featured: "Destacar",
        hide: "Esconder",
        publish: "Publicar",
      },
      featured: {
        "cancel-button": "Cancelar destacado",
        count: "Hay {{count}} comentarios destacados",
        description:
          "Los comentarios destacadas se muestran primero. Haga comentarios destacados para que los usuarios conozcan su negocio. (máximo 3 destacados)",
        title: "Comentarios destacados",
      },
      notification: {
        error: "Hubo un error",
        featured: "El comentario quda destacado",
        hidden: "El comentario fue escondido",
        published: "El comentario fue publicado",
      },
      reviews: {
        description:
          "Publica u oculta los comentarios. Los usuarios no verán comentarios ocultos. Todas los comentarios no moderados se muestran en la pestaña nuevo.",
        "no-hidden-reviews": "No hay comentarios escondidos. 😮",
        "no-new-reviews": "No hay comentarios todavía",
        "no-published-reviews": "No hay comentarios publicados. 😮",
        title: "Comentarios no moderados",
      },
      tab: {
        hidden: "Escondido",
        new: "Nuevo",
        published: "Publicado",
      },
    },
    reviews: "comentarios",
    "sales-description": `El diagrama que se muestra los montos de la venta para la fecha elegida. Hay que mantener o aumentar el monto cada vez más.`,
    "sales-title": "Cambio en la venta",
    saturday: "Sábado",
    "Search by name": "Busca por nombre....",
    "select-dates": "Elegir fechas",
    "Set new password": "Actualiza tu contraseña",
    "Sign In": "Ingresar",
    "Sign out": "Cerrar sesión",
    "Sign Up in Motion Menu": "Registra en Motion Menu",
    "sign-in-form": {
      "sign-in-as-company": "soy propietario",
      "sign-in-as-employee": "soy empleado",
      submit: "Ingresar",
    },
    "sign-in-page": {
      description:
        "¡Motion Menu es un espacio para conectarse con los clientes!",
      releases: "¡Mira el progreso de las nuevas funcionalidades!",
      title: "Más contenido, más valor",
    },
    "sign-up": "Registrarse",
    "sign-up-form": {
      "password-confirmation": "Confirmación de la contraseña",
      "shop-name": "Nombre del negocio",
      submit: "Registrar",
      "your-shop-name": "Nombre del negocio",
    },
    "sign-up-page": {
      "already-have-account": "¿Ya tienes una cuenta?",
      description:
        "¡Motion Menu es un espacio para conectarse con los clientes!",
      "sign-in": "Ingresar",
      title: "Regístrate en Motion Menu",
    },
    "Signed in successfully": "Pudiste ingresar",
    "Signed up successfully": "El registro fue exitoso",
    "start-tour": " Guía de uso",
    Status: "Estado",
    subscribe: "Subscribir",
    Subtotal: "Subtotal",
    subtotoal: "subtotal",
    sunday: "Domingo",
    suspend: "Pausar el plan",
    "Table is empty": "La mesa está vacía",
    "table-list": {
      "is-empty": {
        description: "Tiene que crear nuevo pedido",
        title: "No hay pedidos",
      },
      "new-order-button": "Nuevo pedido",
    },
    Tables: "Mesas",
    "tables-page": {
      "add-table": "Crear nueva mesa",
      description:
        "Puedes crear un nuevo piso apretando el botón +. Para empezar hay que crear una nueva mesa. Los pedidos se administran en la parte derecha.",
      "start-moving-tables": "Mover las mesas",
      steps: {
        1: "Presiona el botón para crear una nueva mesa",
        2: "Edita el nombre directamente. Podrás editarlo al hacer click sobre el texto. Aparecerá un editor",
        3: "Desplegando el menu de la mesa podrás eliminarla",
        4: "Crea una nueva orden donde podrás agregar los productos solicitados por los clientes",
        5: "Haz click aquí para poder ubicar las mesas como sea necesario, vuelve a hacer click para dejar de moverlas",
        6: "Puedes agregar nuevos pisos o sectores en caso de que sea necesario",
        7: "Edita el nombre del piso o sector. Podrás editarlo al hacer click sobre el texto",
        8: "Despliega el menu de pisos para poder eliminarlos de ser necesario",
      },
      "stop-moving-tables": "Dejar de mover",
      title: "Administra las mesas y los pedidos",
    },
    Tag: "Etiqueta",
    tags: "Etiquetas",
    "Thank you! You are part of the community":
      "Gracias! Sos miembro de la comunidad",
    "This Month": "Este Mes",
    "This Week": "Esta Semana",
    thursday: "Jueves",
    "to sign in": "para ingresar",
    Today: "Hoy",
    Total: "Total",
    total: "Total",
    "trial-modal": {
      "attract-more-link": "Contactar",
      "attract-more-title":
        "¿Quieres atraer más clientes con imagenes y videos de alta calidad?",
      description:
        "Puedes utilizar todas las funcionalidades por 14 días. No es necesario ingresar la tarjeta de crédito.",
      dismiss: "Cerrar la ventana",
      "get-access-link": "${{ price }}",
      "get-access-title": "Consigue el acceso completo ",
      title: "Estás en período de prueba por 14 días",
    },
    tuesday: "Martes",
    "unauthorized-page": {
      description: "Pedí el accesso para navegar la página.",
      title: "Oops, no está autorizado",
    },
    undo: "deshacer",
    "update-password-form": {
      submit: "Actualizar",
    },
    "update-password-page": {
      title: "Ingresar nueva contraseña",
    },
    "Uploaded images": "Imágenes Subidos",
    validations: {
      email: {
        long: "Email es demasiado largo",
        required: "Email es requerido",
        short: "Email es demasiado corto",
        valid: "Email no es válido",
      },
      name: {
        long: "Nombre es demasiado largo",
        required: "Nombre es requerido",
        short: "Nombre es demasiado corto",
      },
      password: {
        long: "Contraseña es demasiado larga",
        match: "Contraseña no coincide",
        required: "Contraseña es requerida",
        short: "Contraseña es demasiado corta",
      },
    },
    Visitors: "Visitantes",
    "We have sent you a reset password email!":
      "¡Hemos enviado un mail para resetear la contraseña!",
    wednesday: "Miércoles",
    "Welcome to motion menu. Ultimate tool get more clients connected!":
      "¡Bienvenido a motion menu. La herramienta para conectarse con los clientes!",
    "Welcome to React": "¡Bienvenidos!",
    Yesterday: "Ayer",
    "You don't have any uploaded images": "No tiene imágenes",
    "You don't have any videos": "No tiene videos",
    "You have updated password successfully!":
      "¡La actualización de la contraseña fue exitosa!",
  },
};
