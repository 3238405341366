import React from "react";
import { Container, Name, Price, DeleteIcon } from "./style";
import { IComponentProps } from "./types";
import { RIEInput, RIENumber } from "riek";
import InputContainer from "../../../../../controls/inline_input";

const ProductOptionForm: React.FC<IComponentProps> = ({
  productOption,
  t,
}: IComponentProps) => {
  return (
    <Container>
      <Name>
        <InputContainer>
          <RIEInput
            value={productOption.name}
            change={({ name }: { name: string }) =>
              productOption.update({ name })
            }
            propName="name"
            classEditing="editing"
            className="editable product-option-name-input"
            disabled={productOption.isLoading}
          />
        </InputContainer>
      </Name>

      <Price>
        <InputContainer>
          $
          <RIENumber
            value={productOption.price_f}
            change={({ price }: { price: number }) =>
              productOption.update({ price })
            }
            step="0.01"
            min="0"
            max="99999"
            propName="price"
            classEditing="editing"
            className="editable product-option-price-input"
          />
        </InputContainer>
      </Price>

      <DeleteIcon
        data-testid="delete-product-option"
        whileHover={{ scale: 1.2 }}
        size={20}
        onClick={() => {
          if (window.confirm(t("Are you sure?"))) {
            productOption.delete();
          }
        }}
      />
    </Container>
  );
};

export default ProductOptionForm;
