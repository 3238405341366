import { makeAutoObservable } from "mobx";
import Order from "./order";
import { tablesClient } from "../api";
import type TablesStore from "./tables_store";
import { ITable, IUpdateParams } from "../api/tables_client";

class Table {
  id!: number;

  name!: string;

  left!: number;

  top!: number;

  width!: number;

  height!: number;

  floor_id!: number;

  order_id?: number;

  created_at!: string;

  updated_at!: string;

  // Extra Properties
  store!: TablesStore;

  isLoading = false;

  constructor(store: TablesStore, params: ITable) {
    makeAutoObservable(this, {
      id: false,
      store: false,
    });

    this.store = store;
    this.refreshFromJson(params);
  }

  *update(params: IUpdateParams): Generator<Promise<ITable>, void, ITable> {
    try {
      this.isLoading = true;
      const json = yield tablesClient.update(this.id, params);
      this.refreshFromJson(json);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  *createOrder() {
    const json = yield this.store.createOrder({
      orderable_id: this.id,
      orderable_type: "Table",
    });

    this.order_id = json.id;
  }

  delete(): void {
    this.store.remove(this);
  }

  refreshFromJson(json: ITable): void {
    this.id = json.id;
    this.name = json.name;
    this.created_at = json.created_at;
    this.updated_at = json.updated_at;
    this.left = json.left;
    this.top = json.top;
    this.width = json.width;
    this.height = json.height;
    this.floor_id = json.floor_id;
    this.order_id = json.order;
  }

  get order(): Order | undefined {
    if (!this.order_id) {
      return undefined;
    }

    return this.store.resolveOrder(this.order_id);
  }

  get key(): string {
    return `${this.name}-${this.id}-${this.created_at}`.toLowerCase();
  }

  get hasOrder(): boolean {
    return !!this.order;
  }
}

export default Table;
