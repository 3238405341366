import { AxiosInstance } from "axios";
import qs from "qs";
import { IPageMeta } from "./types";

export enum ReviewStatus {
  not_moderated = "not_moderated",
  published = "published",
  hidden = "hidden",
}

export interface IUser {
  name: string;
  email: string;
}

export interface IReview {
  id: number;
  company_id: number;
  user: IUser;
  body: string;
  featured: boolean;
  status: ReviewStatus;
  created_at: string;
  updated_at: string;
}

export interface IFiltersParams {
  featured?: boolean;
  status?: ReviewStatus;
}

export type IUpdateParams = {
  featured?: boolean;
  status?: ReviewStatus;
};

export interface IIndexResponse {
  data: Record<string, IReview>;
  ids: string[];
  meta: IPageMeta;
}

export function createQueryString(
  page: number,
  per_page: number,
  filters: IFiltersParams
): string {
  return qs.stringify(
    {
      page,
      per_page,
      ...filters,
    },
    {
      arrayFormat: "brackets",
      skipNulls: true,
    }
  );
}

class ReviewsClient {
  restClient!: AxiosInstance;

  constructor(restClient: AxiosInstance) {
    this.restClient = restClient;
  }

  async index(
    page: number,
    per_page: number,
    filters: IFiltersParams
  ): Promise<IIndexResponse> {
    const queryString = createQueryString(page, per_page, filters);

    const response = await this.restClient.get(
      `/api/v1/companies/reviews?${queryString}`
    );

    return response.data;
  }

  async show(id: number): Promise<IReview> {
    const response = await this.restClient.get(
      `/api/v1/companies/reviews/${id}`
    );
    return response.data;
  }

  async update(id: number, body: IUpdateParams): Promise<IReview> {
    const response = await this.restClient.put(
      `/api/v1/companies/reviews/${id}`,
      { review: body }
    );
    return response.data;
  }
}

export default ReviewsClient;
