import styled from "styled-components";
import { motion } from "framer-motion";
import { DeleteForever } from "@styled-icons/material/DeleteForever";

export const Container = styled(motion.div)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${(props) => props.theme.colors.gray};
  border-radius: 8px;
  margin-bottom: 4px;
`;

export const Name = styled.div`
  padding: 10px 16px;
  cursor: pointer;

  input {
    max-width: 90px;
  }
`;

export const Price = styled.div`
  padding: 10px 16px;
  cursor: pointer;

  input {
    max-width: 50px;
  }
`;

export const DeleteIcon = styled(motion(DeleteForever))`
  color: ${(props) => props.theme.colors.redDarker};
  cursor: pointer;
`;
