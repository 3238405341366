import styled, { css } from "styled-components";

export const DraggableItem = styled.div<{ isDragging: boolean }>`
  background-color: ${(props) =>
    props.isDragging ? props.theme.colors.white : props.theme.colors.gray};
  padding: 10px 15px;
  max-width: 300px;
  width: fit-content;
  font-family: inherit;
  font-weight: bold;
  border-radius: 8px;

  display: flex;
  justify-content: space-around;
  gap: 1em;

  ${(props) =>
    props.isDragging &&
    css`
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    `}
`;

export const DraggableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0px;
`;

export const Index = styled.span``;
