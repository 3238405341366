import styled from "styled-components";

export const Container = styled.div`
  padding: 10px;
  max-width: 800px;
`;

export const Title = styled.h1``;

export const Description = styled.p``;

export const ChartContainer = styled.div`
  margin-top: 50px;
  height: 300px;
`;
