import { useCallback } from "react";
import Tag, { BLUE_COLOR, RED_COLOR } from "../../../controls/tag";
import { useTranslation } from "react-i18next";
import { IComponentProps } from "./types";
import styled from "styled-components";

const Container = styled.div`
  padding: 0.3em;
`;

const BooleanCell = ({ value, updateCallback, test_id }: IComponentProps) => {
  const { t } = useTranslation();

  const onClick = useCallback(() => {
    // update callback with opposite value
    updateCallback(!value);
  }, [value]);

  const onKeyDown = useCallback(
    (event: { key: string }) => {
      if (event.key === "Enter") {
        updateCallback(!value);
      }
    },
    [value]
  );

  return (
    <Container>
      <Tag
        color={value ? BLUE_COLOR : RED_COLOR}
        onClick={onClick}
        onKeyDown={onKeyDown}
        tabIndex={0}
        data-testid={test_id}
      >
        {value ? t("true") : t("false")}
      </Tag>
    </Container>
  );
};

export default BooleanCell;
