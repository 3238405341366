export const printCodes = `
<html>
    <div style='padding: 0 20px;'>
        <h1>Order: {{order.name}}</h1>
        <h2>{{order.created_at}}</h2>

        <div style='display: flex; flex-direction: column; justify-content: start; font-size: 40px;'>
            {{#each order.items}}
                {{#if product_code}}
                    <div style='display: flex; padding: 0; justify-content: space-between;'>
                        <span>{{product_code}}</span>
                        <span>{{quantity}}</span>
                    </div>
                {{/if}}
            {{/each}}
        </div>
    </div>
</html>
`;
