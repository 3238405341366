import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 10px;
`;

export const TabContainer = styled.div`
  display: flex;
  gap: 10px;
`;
