import styled from "styled-components";

export const DaySettings = styled.div`
  min-width: 20em;
`;

export const Day = styled.h5`
  margin-bottom: 0;
`;

export const CloseTime = styled.div`
  display: flex;
  align-items: center;
`;

export const DurationTime = styled.div``;

export const Select = styled.select`
  padding: 5px 7px;
  background-color: rgb(235, 235, 235);
  border: none;
  border-radius: 7px;
  margin: 0 5px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  /* position: absolute;
  visibility: hidden; */
`;

export const CustomCheckbox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1em;
  width: 1em;
  border: 1px solid ${(props) => props.theme.colors.lightBlue};

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.blue};
  }

  ${HiddenCheckbox}:checked ~ & {
    background-color: ${(props) => props.theme.colors.lightBlue};
    transition: all 150ms;
  }

  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform-origin: 80% 40%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  ${HiddenCheckbox}:checked ~ &:after {
    display: block;
  }
`;

export const ActiveCheckboxContainer = styled.label`
  /* display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  margin-right: 10px; */
`;

export const ActiveLabel = styled.label`
  display: flex;
  align-items: center;
`;

export const Info = styled.p``;
