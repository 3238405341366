import * as Yup from "yup";

export const signInSchema = Yup.object().shape({
  email: Yup.string()
    .email("validations.email.valid")
    .required("validations.email.required")
    .min(5, "validations.email.short")
    .max(100, "validations.email.long"),
  password: Yup.string()
    .required("validations.password.required")
    .min(5, "validations.password.short")
    .max(100, "validations.password.long"),
});
