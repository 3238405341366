import { makeAutoObservable } from "mobx";
import { profileClient } from "../api";
import { IDailyReportSetting } from "../api/daily_report_settings_client";
import { ICompany } from "../api/profile_client";
import Company from "./company";
import PaymentMethodsStore from "./payment_methods_store";
import PaymentMethod from "./payment_method";
import DailyReportSetting from "./daily_report_setting";
import DailyReportSettingsStore from "./daily_report_settings_store";

class ProfilePageStore {
  company!: Company;

  dailyReportSettingsStore!: DailyReportSettingsStore;

  is_loading = false;

  paymentMethodsStore!: PaymentMethodsStore;

  constructor() {
    makeAutoObservable(this);

    this.paymentMethodsStore = new PaymentMethodsStore();
    this.dailyReportSettingsStore = new DailyReportSettingsStore();
  }

  *load(): Generator<Promise<ICompany>, void, ICompany> {
    try {
      this.is_loading = true;
      const company = yield profileClient.show();
      this.company = new Company(company);
      this.paymentMethodsStore.load();
      this.dailyReportSettingsStore.load();
    } finally {
      this.is_loading = false;
    }
  }

  get payment_methods(): PaymentMethod[] {
    const payment_methods = this.paymentMethodsStore.payment_methods.values();
    return Array.from(payment_methods).sort(
      (a, b) =>
        new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf(),
    );
  }

  get getDailyReportSettings(): DailyReportSetting[] {
    return Array.from(
      this.dailyReportSettingsStore.daily_report_settings.values(),
    );
  }
}

export default ProfilePageStore;
