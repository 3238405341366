import styled from "styled-components";
import { motion } from "framer-motion";
import { down, up } from "styled-breakpoints";
import { EditOutline } from "@styled-icons/evaicons-outline/EditOutline";

export const Container = styled(motion.div)<{ opened: boolean | null }>`
  position: relative;
  transition: all 0.5s ease;

  ${(props) =>
    props.opened &&
    `    transform: translateY(-5px);
    width: 105%;
    align-self: center;
    border-radius: 5px;
    box-shadow: 1px 8px 30px 1px rgba(0, 0, 0, 0.51);
    -webkit-box-shado: 1px 8px 30px 1px rgba(0, 0, 0, 0.51);
    -moz-box-shadow: 1px 8px 30px 1px rgba(0, 0, 0, 0.51);`}

  ${down("md")} {
    width: 100%;
    flex-direction: column;
  }
`;

export const Product = styled(motion.div)<{ opened: boolean | null }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 5px;
  border-radius: 8px;
  padding: 15px 25px;
  transition: all 1s ease;

  ${down("md")} {
    ${(props) =>
      props.opened &&
      `
      flex-direction: column;
      margin: 0;
      padding: 15px 35px 15px 25px;
      `}
  }
`;

export const Button = styled.button<{ hoverColor?: string }>`
  background-color: ${(props) => props.theme.colors.gray};
  outline: none;
  border-radius: 8px;
  color: black;
  text-align: center;
  min-height: 40px;
  border: none;
  padding: 10px 16px;
  cursor: pointer;

  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) =>
      props.hoverColor ? props.hoverColor : props.theme.colors.grayDarker};
  }
`;

export const Edit = styled(EditOutline)``;

export const Column = styled.span`
  margin: 10px;
`;

export const MobileColumn = styled(motion.div)`
  width: 50%;
  display: flex;
  flex-direction: column;
`;
