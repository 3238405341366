export const print = `
<html>
     <h1 style='margin-top: 10px;'>{{company.name}}</h1>
     <h3'>{{order.name}}</h3>
     <span>{{order.created_at}}</span>
     
     {{#each order.items}}
          <div style='display: flex; justify-content: space-between; font-size: 13px;'>
               <p>{{quantity}}</p>
               <p>{{product_name}}</p>
               <p>$ {{total_f}}</p>
          </div>
     {{/each}}

     <h4 style="text-align: right; color: gray;">Subtotal $ {{order.subtotal}}</h4>
     <h4 style="text-align: right; color: gray;">
               Descuento $ {{order.discounted_total}}
     </h4>
     <h2 style="text-align: right;">Total $ {{order.total}}</h2>
     <h5 style="color: white; text-align: center;">Thank you for visiting!</h5>
</html>
`;
