import styled from "styled-components";
import { up } from "styled-breakpoints";
import { InputBase } from "../../controls/input";

export const FormWrapper = styled.div`
  padding: 0px 15px;
  width: 100%;
`;

export const Label = styled.p`
  color: ${(props) => props.theme.colors.black};
  margin-top: 0;
  font-weight: bold;
  text-transform: capitalize;
`;

export const Input = styled.input`
  ${InputBase}

  height: fit-content;
`;

export const Textarea = styled.textarea`
  ${InputBase}

  min-height: 10em;
`;

export const Button = styled.button`
  display: block;
  background-color: ${(props) => props.theme.colors.blue};
  color: #fff;
  width: 8em;
  outline: none;
  border-radius: 8px;
  text-align: center;
  height: 40px;
  border: none;
  padding: 10px 16px;
  margin-top: 15px;
  cursor: pointer;

  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.blueDarker};
  }
`;

export const Error = styled.p`
  color: red;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1em;
  flex-wrap: wrap;

  ${up("md")} {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
`;

export const InputDescription = styled.div`
  width: 100%;

  ${up("md")} {
    max-width: 20em;
    margin-right: 10em;
  }
`;
