import React from "react";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { useStores } from "../../contexts/store_context";
import Component from "./component";

const Injected = withTranslation()(Component);

const TablesListIndex: React.FunctionComponent = () => {
  const stores = useStores();

  const scrollAreaRef = React.useRef<any>();
  const tableRefs = React.useRef<{ [key: number]: any }>({});

  const createTableRefCallback = React.useCallback(
    (table_id: number) => (reference: any) => {
      tableRefs.current[table_id] = reference;
    },
    []
  );

  React.useEffect(() => {
    stores?.tablePageStore.setScrollToTable((table_id) => {
      tableRefs.current[table_id]?.scrollIntoView();
    });
  }, [stores]);

  const tables = stores?.tablePageStore.filteredTables || [];

  const onSarchInputChange = React.useCallback(
    (value: string) => {
      stores?.tablePageStore.setSearchQuery(value);
    },
    [stores?.productListPageStore]
  );

  const selectedTableId = stores?.tablePageStore.selectedTableId || null;

  return (
    <Injected
      tables={tables}
      scrollAreaRef={scrollAreaRef}
      createTableRefCallback={createTableRefCallback}
      onSarchInputChangeCallback={onSarchInputChange}
      selectedTableId={selectedTableId}
    />
  );
};

export default observer(TablesListIndex);
