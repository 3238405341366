import styled from "styled-components";
import { up } from "styled-breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 382px;
  width: 100%;
`;

export const FormWrapper = styled.div`
  padding: 0px 15px;
  width: 100%;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    ${up("md")} {
      /* padding: 11px 3px; */
    }
  }
`;

export const Label = styled.p`
  margin-top: 15px;
`;

export const Button = styled.button`
  display: block;
  background-color: ${(props) => props.theme.colors.blue};
  color: #fff;
  width: 100%;
  outline: none;
  border-radius: 8px;
  text-align: center;
  height: 40px;
  border: none;
  padding: 10px 16px;
  margin: auto;
  margin-top: 15px;
  cursor: pointer;

  transition: all 200ms ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.blueDarker};
  }
`;

export const Error = styled.p`
  color: red;
`;
